<template>
  <ion-label position="floating">
    {{ label }}
  </ion-label>
  <ion-input type="text" 
    v-model="textSelected"
    clear-input
    :delay="delay" 
    :placeholder="placeholder" 
    :disabled="computedDisabled"
    @input="getItems($event)" 
    @click="$event.target.select()"/>
  <ion-card class="suggestions-card" v-if="isItemAvailable">

  <!-- <ion-list  > -->
    <ion-item v-for="(item, index) in items" 
      :key="index" 
      @click="selected(item)">
        <template v-if="attributesToHighlight && attributesToHighlight.length > 0">
          <span v-html="getHighlightResult(item)"></span>
        </template>
        <template v-else>
          <!-- {{ keySearcher(item) }} -->
          <div>{{ keySearcher(item) }}</div>
        </template>
    </ion-item>
  <!-- </ion-list> -->
  </ion-card>
</template>

<script>
import {IonInput, IonLabel, IonCard,  IonItem} from "@ionic/vue";

export default {
  name: "SimpleAutoComplete",
  emits:[
    'change'
  ],
  components: {
    IonInput,
    IonLabel,
    IonCard,
    // IonList,
    IonItem,
  },
  props: {
    attributesToHighlight: Array,
    modelValue: null,
    options: Array,
    label: String,
    placeholder: String,
    itemText: null,
    value: String,
    delay: String,
    disabled: Boolean,
    disabledObject: {
      type: Object
    }
  },
  computed: {
    computedDisabled() {
      return this.disabledInput
    }
  },
  watch: {
    modelValue() {
      this.itemSelected = this.modelValue
    },
    itemSelected() {
      this.textSelected = this.itemSelected ? this.keySearcher(this.itemSelected) : ''
      this.$emit('change', this.itemSelected)
    },
    textSelected(newValue) {
      if (!newValue || newValue.trim() === '') {
        this.isItemAvailable = false;
        this.$emit('change', null)
      }
    }
  },
  methods: {
    initializeItems() {
      this.items = [];
    },
    getHighlightResult(item){
      if (!this.textSelected) return ''
      return this.attributesToHighlight.map(field => 
        item[field] ? 
          item[field].replace(this.textSelected.toUpperCase(), `<em>${this.textSelected.toUpperCase()}</em>`) : 
          item[field]
      ).join(' - ')
    },
    getItems(ev) {
      // Reset items back to all of the items
      this.initializeItems();

      // set val to the value of the searchbar
      const val = ev.target.value;

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== "") {
        this.isItemAvailable = true;
        this.items = this.options.filter((item) => {
          const keySearch = this.keySearcher(item)
          return keySearch.toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
      } else {
        this.isItemAvailable = false;
      }
    },
    keySearcher(element) {
      if(Object.keys(element).length === 0) return ''

      else if (typeof this.itemText === "string") 
        return element[this.itemText];
      else if (element)
        return this.itemText({element})
      else
        return ''
    },
    selected(item) {
      this.itemSelected = item
      this.initializeItems()
    }
  },
  data() {
    return {
      items: [],
      isItemAvailable: false,
      itemSelected: null,
      textSelected: null,
      disabledInput: false
    };
  },
  created() {
    if (this.disabledObject) {
      const { params = null, execDisable = null } = this.disabledObject
      this.disabledInput = execDisable ? execDisable(params) || false : this.disabled
    } else {
      this.disabledInput = this.disabled
    }
    if (this.modelValue) this.itemSelected = this.modelValue
  },
};
</script>
<style>
  body {
    font-family: sans-serif;
  }

  .suggestions-card {
    opacity: 0.5;
    margin: 0 0.2em 1em 0em;
    box-shadow: 2px 2px #929292 !important;
  }
</style>
