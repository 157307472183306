<template>
  <ion-page>
    <Header />
    <ion-content>
      <ion-tabs v-if="!$route.meta.public && showing">
        <!-- <ion-router-outlet></ion-router-outlet> -->
        <ion-tab-bar>
          <ion-tab-button tab="SC5" href="/tabs/SC5">
            <ion-icon :icon="document" />
            <ion-label>Pedidos</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="SA1" href="/tabs/SA1">
            <ion-icon :icon="person" />
            <ion-label>Clientes</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="courses" href="/tabs/courses" :disabled="offline">
            <ion-icon :icon="book" />
            <ion-label>Cursos</ion-label>
            <ion-badge v-if="offline" color="danger">
              <ion-icon  :icon="cloudOffline"></ion-icon>
            </ion-badge>
          </ion-tab-button>

          <ion-tab-button tab="reports" href="/tabs/reports" :disabled="offline">
            <ion-icon :icon="barChart" />
            <ion-label>Relatórios</ion-label>
            <ion-badge v-if="offline" color="danger">
              <ion-icon  :icon="cloudOffline"></ion-icon>
            </ion-badge>
          </ion-tab-button>
          
          <ion-tab-button tab="tickets" href="/tabs/tickets" :disabled="offline">
            <ion-icon :icon="helpBuoyOutline" />
            <ion-label>Chamados</ion-label>
            <ion-badge v-if="offline" color="danger">
              <ion-icon  :icon="cloudOffline"></ion-icon>
            </ion-badge>
          </ion-tab-button>
          
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const { mapActions: registersActions } = createNamespacedHelpers("registers")
import {
  IonTabBar,
  IonLabel,
  IonContent,
  IonPage,
  IonIcon,
  IonTabButton,
  IonTabs,
  IonBadge
  // IonRouterOutlet,
} from "@ionic/vue";
import {
  helpBuoyOutline,
  personCircle,
  ticket,
  book,
  document,
  person,
  homeOutline,
  barChart,
  cloudOffline
} from "ionicons/icons";
import Header from "@/components/Auth/Header.vue";
import { mapState } from "vuex"

export default {
  name: "TabNavigation",
  components: {
    IonTabs,
    // IonRouterOutlet,
    IonContent,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
    IonBadge,
    Header,
  },
  computed: {
    ...mapState(["offline"]),
    showing() {
      return (
        !this.$route.path.includes("cadastrar") &&
        !this.$route.path.includes("detalhes")
      );
    },
  },
  methods:{
    ...registersActions(["showToast"]),
  },
  setup() {
    return {
      helpBuoyOutline,
      personCircle,
      document,
      person,
      homeOutline,
      barChart,
      ticket,
      book,
      cloudOffline
    };
  },
};
</script>
