<template>
  <ion-header v-show="showing">
    <ion-toolbar>
      <ion-buttons slot="start">
        <img :src="logoAsset" :style="{height: '60px'}" alt="{{title}}" class="contain" />
      </ion-buttons>
      <ion-title size="small">
        <SelectEmpFil/>
      </ion-title>
      <ion-buttons slot="end">
        <ion-fab-button 
          @click="syncOffline"
          :disabled="offline || synchronizing" 
          class="fab-sync" 
          size="small" 
          fill="celar"
          :color="colorSync">
          <ion-icon :icon="sync"></ion-icon>
          <ion-badge :class="'badge-sync'" :color="colorSync">
            {{ computedLastSync }}
          </ion-badge>
        </ion-fab-button>
        <ion-avatar @click="setOpen(true)">
          <ion-img v-if="user && user.avatar" :src="user.avatar.imageUrl"></ion-img>
          <ion-img v-else-if="user && user.email" :src="gravatarUser"></ion-img>
          <Notification :justBadge="true"/>
        </ion-avatar>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar v-if="searching.stats">
      <ion-buttons slot="start">
        <ion-button @click="toggleSearching({stats: false})">
          <ion-icon :icon="chevronUp"/>
        </ion-button>
      </ion-buttons>
      <ion-list>
        <ion-item class="ion-no-padding">
          <!-- <ion-input placeholder="Buscar" clear-input/> -->
          <v-text-field-algolia 
            :index="searching.index" 
            :delay="750"
            :placeholder="'Buscar'"
            :handler="searching.handler"
            :filters="searching.filters"
            :attributesToHighlight="searching.attributesToHighlight"
            >
          </v-text-field-algolia>
          <ion-icon :icon="search"/>
        </ion-item>
      </ion-list>
      <!-- <ion-searchbar placeholder="Buscar"></ion-searchbar> -->
    </ion-toolbar>
    <ion-row v-if="offline" class="toolbar-offline">
      <ion-col size="12">
        <ion-button color="danger" size="small" expand="full">
          <ion-icon :icon="cloudOffline"></ion-icon>&nbsp; Trabalhando off-line! 
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row v-if="synchronizing" class="toolbar-offline sync">
      <ion-col size="12">
        <ion-button color="warning" size="small" expand="full">
          <ion-icon :icon="syncCircleOutline"></ion-icon>&nbsp; Sincronizando dados off-line. Favor aguardar
        </ion-button>
        <ion-progress-bar color="warning" type="indeterminate"></ion-progress-bar>
      </ion-col>
    </ion-row>
    <ion-modal :is-open="isOpenRef" @didDismiss="setOpen(false)">
      <ModalUser />
    </ion-modal>
  </ion-header>
</template>

<script>
import crypto from 'crypto'
import {ellipsisVertical} from "ionicons/icons";
import ModalUser from "./ModalUser.vue";
import SelectEmpFil from "../Templates/SelectEmpFil.vue";
import Notification from "../Templates/Notification.vue"
// import FilterButton from "../FilterButton.vue"
import {
  IonHeader,
  IonList,
  IonItem,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonFabButton,
  IonButton,
  IonModal,
  IonProgressBar,
  IonAvatar,
  IonBadge,
  IonRow,
  IonCol,
  IonImg,
  IonIcon
} from "@ionic/vue";
import { search, filter, chevronUp, cloudOffline, sync, syncCircleOutline } from 'ionicons/icons'

import {createNamespacedHelpers, mapState, mapActions, mapMutations} from "vuex";
import {defineComponent, ref} from "vue";
import VTextFieldAlgolia from '../VTextFieldAlgolia.vue';
const { mapState:authState } = createNamespacedHelpers('auth')
const { mapState:registersState, mapActions:registersActions } = createNamespacedHelpers("registers")
export default defineComponent({
  name: "Loading",
  components: {
    IonHeader,
    IonBadge,
    IonList,
    IonItem,
    IonToolbar,
    IonTitle,
    IonProgressBar,
    IonButtons,
    IonButton,
    IonFabButton,
    // IonInput,
    IonRow,
    IonCol,
    IonAvatar,
    IonModal,
    IonImg, 
    ModalUser,
    SelectEmpFil,
    Notification,
    IonIcon,
    VTextFieldAlgolia
  },
  props: ["title", "have_menu"],
  data() {
    return {
      today: new Date(),
      logoAsset: '',
    }
  },
  computed: {
    ...authState(['user']),
    ...mapState(["loading", "searching", "offline", "synchronizing", 'syncable', "lastSync"]),
    ...registersState(['registerActive']),
    showing() {
      return !this.$route.meta.public && !this.$route.path.includes('cadastrar') && !this.$route.path.includes('detalhes') && !this.$route.path.includes('dashboard/');
    },
    menu() {
      return [];
    },
    gravatarUser() {
      return this.getGravatar()
    },
    colorSync() {
      let colorSync = 'warning'
      if (typeof this.computedMinutes !== 'number' || this.computedMinutes > (60*24) || this.computedMinutes < 0) { 
        colorSync = 'danger'
      } else if (this.computedMinutes < 60) {
        colorSync = 'success'
      } else if (this.computedMinutes < (60*24)) {
        colorSync = 'primary'
      }
      return colorSync
    },
    computedLastSyncData() {
      return this.lastSync
    },
    lastSyncRegister() {
      if (!this.computedLastSyncData) return
      const lastSyncRegister = this.computedLastSyncData
      if (!lastSyncRegister) return null
      return lastSyncRegister
    },
    computedMinutes() {
      if (typeof this.lastSyncRegister === 'null' || this.lastSyncRegister === NaN) return -1
      const diffMs = (this.today.getTime() - (new Date(this.lastSyncRegister)).getTime())
      const minutes = Math.round(((diffMs / 1000) / 60))
      return minutes
    },
    computedLastSync() {
      if (isNaN(this.computedMinutes) || this.computedMinutes == 0) {
        return ''
      } else if (this.computedMinutes < 60) {
        return `${this.computedMinutes}m`
      } else if (this.computedMinutes < (60*24)) {
        return `${Math.round(this.computedMinutes/60)}h`
      } else {
        return `${Math.round(this.computedMinutes/(60*24))}d`
      }
    }
  },
  watch: {
    lastSync(newValue) {
    }
  },
  methods: {
    ...mapActions(['toggleSearching']),
    ...mapMutations(['setLoading']),
    ...registersActions(['syncOffline']),
    getGravatar() {
      let hash = crypto
        .createHash("md5")
        .update(this.user.email || "")
        .digest("hex");
      return `https://www.gravatar.com/avatar/${hash}`;
    },
    allowed(meta) {
      let allowed = true;
      if (meta.requireClaim && (!this.$appConfig.claims || !this.$appConfig.claims[meta.requireClaim])) {
        allowed = false;
      }
      return allowed;
    },
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state) => {
      isOpenRef.value = state;
    };
    const data = {content: "New Content"};
    return {ellipsisVertical, syncCircleOutline, isOpenRef, setOpen, data, search, filter, chevronUp, cloudOffline, sync};
  },
  created() {
    this.today = new Date()
    setInterval(() => 
       this.today = new Date()
    , 6000)

    let theme = window.matchMedia('(prefers-color-scheme: dark)')

    this.logoAsset = theme.matches ? require("@/assets/logo_mini.png") : require("@/assets/logo_mini_dark.png")

    theme.addListener((mediaQuery) => {
      this.logoAsset = mediaQuery.matches ? require("@/assets/logo_mini.png") : require("@/assets/logo_mini_dark.png")
    })
  }
});
</script>

<style>
  ion-avatar {
    padding: .6em;
  }
  ion-title {
    padding: 0;
  }
  .toolbar-offline * {
    padding: 0;
    margin: 0;
  }
  .toolbar-offline.sync ion-button{
    font-size: .6em;
  }
  /* .toolbar-offline ion-button {
    padding: 0;
    margin: 0;
  } */

  .fab-sync {
    --box-shadow: unset;
    position: relative;
  }

  .badge-sync{
    z-index: 100;
    font-size: .65em;
    position: absolute;
    bottom: .01em;
    right: .15em;
  }
</style>
