<!--template -->
  <!-- <AutoComplete :placeholder="placeholder" @input="onInput" >

  </AutoComplete> -->
<!--/ template -->

<script>
  import AutoComplete from './AutoComplete.vue'
  import { createNamespacedHelpers, mapActions } from 'vuex'
  const { mapActions:registersActions } = createNamespacedHelpers('registers')
  export default {
    name: 'AutoCompleteAlgolia',
    extends: AutoComplete,
    components: {
      AutoComplete
    },
    props: {
      /**
       * Collection do algolia
       */
      index: {
        type: String,
        required: true
      },

      /**
       * Função de tratamento da requisição da busca.
       * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
       */
      handler: {
        type: Function,
        required: false
      },

      /**
       * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
       */
      delay: {
        type: Number,
        required: false,
        default: () => 0
      },

      /**
       * Texto que aparece quando a query de busca está vazia e a prop `noEmpty` tá marcada como `true`
       */
      emptyText: {
        type: String,
        default: 'Digite algo para pesquisar',
      },

      /**
       * Em caso positivo, limpa o array de itens buscados após escolher um item
       */
      clean: {
        type: Boolean,
        deafult: false,
      },

      filters: Array,
      restrictSearchableAttributes: Array,
      attributesToHighlight: Array
    },
    data() {
      return {
        timer: null,
        hasInput: false
      }
    },
    async created() {
    },
    methods: {
      ...registersActions(['searchAlgolian']),
      async onInput(event) {
        const query = event.target.value

        if (this.delay) {
          await this.timeoutSearch(query, this.filters)
        }else { 
          await this.search(query, this.filters)
        }
        
        this.onChange(event)
      },
      async timeoutSearch(query, filters) {
        if (query || filters) {
          if (this.timer) {
            clearTimeout(this.timer)
          }

          await this.timeOut(this.delay)
          await this.handler(this.algoliaSearch(query, filters))
        }
      },
      async search(query, filters) {
        if (query || filters.length > 0)
          await this.handler(this.algoliaSearch(query, filters))
      },
      algoliaSearch(query, filters) {
        // if (query || filters.length > 0)
          return this.searchAlgolian({index: this.index, query, facetFilters: filters, restrictSearchableAttributes: this.restrictSearchableAttributes, attributesToHighlight: this.attributesToHighlight})
        // else return { hits: [] }
      },
      timeOut(delay) {
        return new Promise(resolve => this.timer = setTimeout(() => {this.timer = null; resolve()}, delay))
      }
    },
    setup(props) {
      return { ...AutoComplete.setup(props) }
    }
  }
</script>