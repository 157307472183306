<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="hasDeferredPrompt" slot="end">
          <ion-button @click="install" left>
            <ion-icon :icon="downloadOutline"></ion-icon>
            <ion-label> Install </ion-label>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="signOut" left>
            <ion-icon :icon="exit"></ion-icon>
            <ion-label> Sair </ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <Notification :justBadge="false" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonLabel,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonToolbar,
  IonPage,
  // IonCard,
  // IonCardHeader,
  // IonCardTitle,
  // IonRow,
} from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";
import { modalController } from "@ionic/vue";
import Notification from "../Templates/Notification.vue";
import {
  notifications,
  exit,
  arrowBack,
  helpBuoyOutline,
  downloadOutline
} from "ionicons/icons";

import store from "../../store";
import { createNamespacedHelpers } from "vuex";
const { mapActions: authActions } = createNamespacedHelpers("auth");

export default defineComponent({
  name: "Modal",
  components: {
    IonLabel,
    IonHeader,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonToolbar,
    IonPage,
    // IonCard,
    // IonCardHeader,
    // IonCardTitle,
    // IonRow,
    Notification,
  },
  setup() {
    return {
      notifications,
      exit,
      arrowBack,
      helpBuoyOutline,
      downloadOutline
    };
  },
  computed: {
    hasDeferredPrompt() {return !!store && !!store.$deferredPrompt}
  },
  methods: {
    ...authActions(["signOut"]),
    async closeModal() {
      await modalController.dismiss();
    },
    showTickets() {
      this.closeModal();
      this.$router.push("/tabs/tickets/listar");
    },
    install() {
      if (store.$deferredPrompt) {
        store.$deferredPrompt.prompt()
        // store.$deferredPrompt.userChoice.then(choiseResult => {
        //   console.info(choiseResult.outcome)
        // })
        store.$deferredPrompt = null
      }
    }
  }
});
</script>

<style></style>
