<template>
  <ion-page>
    <ion-header >
      <ion-toolbar>
        <ion-title>{{name}}</ion-title>
        <ion-buttons slot="start">
          <ion-back-button/>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="showPopOver(true, $event)">
            <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
          </ion-button>
          <ion-popover
            :is-open="isPopOverRef"
            :event="event"
            @didDismiss="showPopOver(false)">
              <ion-list class="ion-no-padding">
                <ion-item button @click="()=>{exportXlsx({dTable: computedDataTables[0]}); showPopOver(false)}">
                  <ion-label>Exportar Excel</ion-label>
                  <ion-icon :icon="downloadOutline"></ion-icon>
                </ion-item>
                <ion-item button @click="() => {isOpenRef = true; showPopOver(false)}">
                  <ion-label>Filtros</ion-label>
                  <ion-icon :icon="funnelOutline"></ion-icon>
                </ion-item>
              </ion-list>
          </ion-popover>
        </ion-buttons>
      </ion-toolbar>
      <template v-for="(dTable, index) of computedDataTables" :key="index">
        <ion-toolbar>
          <ion-buttons slot="end">
            
          </ion-buttons>
          <v-text-field-external
            v-model="searchInputs[dTable.register]"
            :externalRequest="dTable.externalRequest"
            :handler="search"
            :delay="750"
            :filtersIn="computedFiltersProcessed"
            :fieldsToSearch="dTable.headers.filter((x) => x.searchable).map((x) => x.searchableContent || x.value)"
            :fieldsToMark="dTable.headers.filter((x) => x.searchable && x.searchableContent).map(({searchableContent, value}) => ({ searchableContent, value }))"
            :register="`report${dTable.register}`"
            :ref="`externalSearchInput${dTable.register}`"/>
        </ion-toolbar>
      </template>
    </ion-header>
    <ion-content>
      <ion-list :style="{paddingStart: '8px', paddingEnd: '8px'}">
        <template v-for="(dTable, index) of computedDataTables" :key="index">
          <template v-for="(item, index) of (!loading[`report${dTable.register}`] ? (searchInputs[dTable.register] && searchInputs[dTable.register].length > 0) || (computedFiltersProcessed.find(cp => cp.selecteds.length > 0)) ? 
                registers[`report${dTable.register}Filtered`] : 
                registers[`report${dTable.register}`] || [] : [])" :key="index">
            <ion-card style="margin-bottom: 8px;">
              <ion-card-content>
                <ion-grid class="ion-no-padding">
                  <template v-for="(header, index) of dTable.headers" :key="index">
                    <ion-row class="ion-align-items-center" :style="{borderBottom: '1px solid grey'}">
                      <ion-col size="3">
                        <small>{{header.text}}</small>
                      </ion-col>
                      <ion-col size="9">
                        <small v-html="item[header.value]"></small>
                      </ion-col>
                    </ion-row>
                  </template>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </template>
        </template>
      </ion-list>
      <ion-modal :is-open="isOpenRef" @didDismiss="setOpen(false)">
        <ion-page>
          <ion-header>
            <ion-toolbar>
              <ion-title :style="{marginStart: '16px'}">Filtros</ion-title>
              <ion-buttons slot="end">
                <ion-button @click="setOpen(false)">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content>
            <ion-list>
              <template v-for="(dTable, index) of computedDataTables" :key="index">
                <template v-for="(key, index) of filtersProcessed" :key="index">
                  <ion-item>
                    <ion-label>{{key.text}}</ion-label>
                    <ion-select v-model="key.selecteds" multiple="true"  @ionChange="applyFilter()">
                      <template v-for="(item, index) of searchInFilters[key.field] && searchInFilters[key.field].length > 0 ?
                        registers[`reportFilters${key.field}Filtered`] :
                        registers[`reportFilters${key.field}`]" :key="index">
                        <ion-select-option 
                          :value="item[key.field]"
                          :ref="`reportFiltersSelect${key.field}`">
                          {{item[key.itemText]}}
                        </ion-select-option>
                      </template>
                    </ion-select>
                  </ion-item>
                </template>
              </template>
            </ion-list>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonContent,
  IonList,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonPopover,
} from '@ionic/vue'
import store from '../../store'
import { ref } from 'vue'
import { funnelOutline, close, settingsOutline, ellipsisVertical, downloadOutline } from 'ionicons/icons'
import {createNamespacedHelpers, mapMutations} from "vuex"
import VTextFieldExternal from '../../components/VTextFieldExternal.vue'
import XLSX from "xlsx"

const {mapState: registersState, mapActions: registersActions} = createNamespacedHelpers("registers")

export default {
  name: "GeneratedReportsRegister",
  components:{
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonBackButton,
    IonContent,
    IonList,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
    IonModal,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonPopover,
    VTextFieldExternal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    dataTables: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: false
    }
  },
  watch: {
    dataCounter(){
      if((this.dataTables.length + this.filters.length) === this.dataCounter) this.setLoading({stats: false})
    }
  },
  data() {
    return {
      dialog: false,
      dataTablesProcessed: [],
      filtersProcessed: [],
      searchInFilters: {},
      searchInputs: {},
      searchEnableds: {},
      configsShowed: {},
      headersShowed: {},
      dataCounter: 0,
    };
  },
  methods: {
    ...registersActions(["getExternal", "showToast"]),
    ...mapMutations(['setLoading']),    
    initRegisters() {
      this.dataTablesProcessed = this.dataTables.map((dTable) => {
        const {query, register, headers, filters = [], order = {by: 'ID', desc: false}, itemsPerPage: perPage = 10} = dTable;
        const ordem = `${order.by} ${order.desc ? 'DESC' : false}`
        const externalRequest = {query, idField: "ID", register: `report${register}`, ordem, perPage: 101, async: true}
        this.getExternal(externalRequest).then(()=>{
          this.dataCounter++
        })
        return {...dTable, filters, serverItemsLength: true, externalRequest};
      });

      this.filtersProcessed = this.filters.map((f, ix) => {
        const { query, itemValue: idField, itemText: ordem } = f
        const externalRequest = {query, idField, register: `reportFilters${f.field}`, ordem, perPage: 999999999, async: true }
        this.getExternal(externalRequest).then(()=>{
          this.dataCounter++
        })
        return {...f, data: [], selecteds: [], externalRequest}
      })
    },
    async search(req) {
      try {
        await req;
      } catch (err) {
        console.error(err);
      }
    },
    proccessItem({item, head}) {
      let ret = store.$_.cloneDeep(item);
      if (!ret) return;
      if (
        ret._highlightResult && 
        ret._highlightResult[head.value] && 
        ret._highlightResult[head.value].matchedWords && 
        ret._highlightResult[head.value].matchedWords.length > 0
      ) {
        ret = ret._highlightResult;
      }
      /* NESTED */
      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      if (lenItem == 1) {
        ret = ret[pathItem];
      } else {
        for (let ix = 0; ix < pathItem.length; ix++) {
          const el = pathItem[ix];
          if (ret && typeof ret[el] != "undefined") {
            ret = ret[el];
          } else {
            ret = head.checkbox ? false : "";
            break;
          }
        }
      }

      /* FORMATS */
      if (!ret && ret !== 0) return;
      if (ret && ret.value) ret = ret.value;
      if (head.upper) {
        ret = ret.toUpperCase();
      }
      if (head.lower) {
        ret = ret.toLowerCase();
      }
      if (head.trim) {
        ret = ret.trim();
      }

      /* FORMAT DATES */
      if (head.type === "date" || head.type === "dateHour" || head.type === "dateYear" || head.type === "datePlusWeek") {
        if (Object.prototype.hasOwnProperty.call(ret, "toDate")) {
          ret = ret.toDate();
        } else if (ret.seconds && ret.nanoseconds) {
          // Retorno via Algolia não contem metodo toDate (agora o firebase tbm)
          ret = new this.$db.Timestamp(ret.seconds, ret.nanoseconds).toDate();
          // Retorno via Algolia não contem metodo toDate. (agora tem o underline na frente)
        } else if (ret._seconds && ret._nanoseconds) {
          ret = new this.$db.Timestamp(ret._seconds, ret._nanoseconds).toDate();
        }
      }
      if (head.type === "date") {
        ret = this.$moment(ret).format("DD/MM/YYYY");
      }
      if (head.type === "dateHour") {
        ret = this.$moment(ret).format("DD/MM/YYYY - HH:mm:ss");
      }
      if (head.type === "dateYear") {
        ret = this.$moment(ret).format("YYYY");
      }
      if (head.type === "datePlusWeek") {
        ret = this.$moment(ret).format("DD/MM/YYYY, dddd");
      }

      if (head.type === "money") ret = new Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(ret);

      // if (options.slice) { obj = (obj) ? obj.slice(options.slice) : '' }
      // if (options.multiplier) { obj = (obj) ? obj * options.multiplier : '' }

      return ret;
    },
    applyFilter() {
      this.computedDataTables.forEach(dTable => {
        const { query, register, order = {by: 'ID', desc: false}, page = 1 } = dTable
        const input = `externalSearchInput${register}`
        const filtersIn = [...this.computedFiltersProcessed.filter(f => f.selecteds.length > 0)]
        
        if (this.$refs[input]) {
          this.$refs[input].search(this.searchInputs[register], [], filtersIn)
        } else  {
          const ordem = `${order.by} ${order.by && order.desc ? 'DESC' : ''}`
          const externalRequest = { query, idField: "ID", register: `report${register}`, ordem, page, perPage: 101, filtersIn }
          this.getExternal(externalRequest)
        }
      })
    },
    async update({ dTable, allRegs = false, async = false, options: { sortBy: sort, sortDesc: desc, itemsPerPage, page: pageIndex } }) {
      if (pageIndex) dTable.page = pageIndex
      if (itemsPerPage) dTable.itemsPerPage = itemsPerPage
      if (sort) dTable.order.by = sort.length > 0 ? sort[0] : ''
      if (desc !== null) dTable.order.desc = desc


      const { query, register, order = {by: 'ID', desc: false}, page = 1, itemsPerPage: perPage } = dTable
      // console.info('dTable', dTable);
      const ordem = `${order.by} ${order.by && order.desc ? 'DESC' : ''}`
      const filtersIn = [...this.computedFiltersProcessed.filter(f => f.selecteds.length > 0)]
      const externalRequest = { 
        query, 
        idField: "ID", 
        register: `report${register}`, 
        ordem, 
        page,
        perPage: 101,
        nextPage: true,
        search: this.searchInputs[register],
        fieldsToSearch: dTable.headers.filter(x => x.searchable).map(x => x.searchableContent ||x.value),
        fieldsToMark: dTable.headers.filter((x) => x.searchable && x.searchableContent).map(({searchableContent, value}) => ({ searchableContent, value })),
        filtersIn,
        allRegs,
        async
      }
      if (async) {
        await this.getExternal(externalRequest)
      } else {
        this.getExternal(externalRequest)
      }
    },
    async getAll({dTable}) {
      const options = { sortBy: [dTable.order.by], sortDesc: dTable.order.desc, page: 1 }

      await this.update({dTable, async: true, allRegs: true, options})

      const items = (this.searchInputs[dTable.register] && this.searchInputs[dTable.register].length > 0) || (this.computedFiltersProcessed.find(cp => cp.selecteds.length > 0)) ? 
                    this.registers[`report${dTable.register}FilteredAll`] : 
                    this.registers[`report${dTable.register}All`] || []

      return items
    },
    async exportXlsx({dTable}) {
      this.setLoading({stats: true, message: 'Exportando excel'})
      const headers = dTable.headers.filter(h => h.export)
      
      if (!headers || headers.length === 0) {
        // this.$toast.error('Esta tabela não possui campos exportáveis.');
        this.showToast({ message: 'Esta tabela não possui campos exportáveis.', color: "error" });

        return
      }

      const header = headers.map(h => h.text);
      const itemsRegistereds = await this.getAll({dTable})
      
      if (!itemsRegistereds || itemsRegistereds.length === 0) {
        // this.$toast.error('Nenhum item a ser exportado.');
        this.showToast({ message: 'Nenhum item a ser exportado.', color: "error" });

        
        return
      }

      const items = itemsRegistereds.map(item => 
        headers.reduce((acc, header) => 
          ({...acc, [header.text]: (typeof item[header.value] === 'string') ? item[header.value].split('<em>').join('').split('</em>').join('') : item[header.value]}), 
          {}
        )
      )
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(items, { header });

      // adiciona as fórmulas em formato excel para as células das colunas que possuem essa configuração
      items.slice(1).forEach((_, idx) => {
        headers.forEach((header, i) => {
          if (!header.xlsxFormula)
            return
          
          const cellKey = `${String.fromCharCode(65 + i)}${idx + 2}`
          try {
            ws[cellKey].f = header.xlsxFormula.trim().replaceAll('x', `${idx + 2}`)
          } catch (err) {
            console.error(err)
          }
        })
      })
      this.setLoading({stats: false})
      this.showToast({message:"Arquivo exportado com sucesso!", color: "success"})

      XLSX.utils.book_append_sheet(wb, ws, `Sheet`)

      const exportFileName = `${dTable.title}.xlsx`;
      XLSX.writeFile(wb, exportFileName)
    },
  },
  computed: {
    ...registersState(["registers", "counters", 'loading']),
    computedSearchEnableds() {
      return this.searchEnableds;
    },
    computedConfigsShowed() {
      return this.configsShowed;
    },
    computedHeadersShowed() {
      return this.headersShowed
    },
    computedDataTables() {
      return this.dataTablesProcessed.map((dTable) => {
        return {...dTable};
      });
    },
    computedFiltersProcessed() {
      return this.filtersProcessed
    }
  },
  async created() {
    this.setLoading({stats: true, message: "Montando relatório"});
    this.initRegisters();
    this.searchEnableds = {...Object.assign({}, ...this.dataTables.map((dt) => ({[dt.register]: false})))};
    this.configsShowed = this.searchEnableds; //{...Object.assign({}, ...this.dataTables.map(dt=>  ( {[dt.register]: false} ))) }
    this.headersShowed = {
      ...Object.assign({}, 
      ...this.dataTables.map((dt) => 
        ({[dt.register]: Object.assign({}, ...dt.headers.map((h) => ({[h.value]: h.showable})))})
      )
    )};
  },
  setup(){
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    const event = ref();
    const isPopOverRef = ref(false);
    const showPopOver = (state, ev) => {
      isPopOverRef.value = state
      event.value = ev
    };

    return { isOpenRef, setOpen, funnelOutline, close, settingsOutline, ellipsisVertical, downloadOutline, isPopOverRef, showPopOver, event };
  },
};
</script>
