<template>
  <ion-page>
    <ion-toolbar slot="fixed">
      <ion-searchbar
        placeholder="Pesquisar"
        @ionChange="search($event)"
        @ionClear="
          () => {
            selectedVideoId = null;
          }
        "
      />
    </ion-toolbar>
    <VideoPlayer v-if="selectedVideoId" :videoId="selectedVideoId" @closePlayer="selectedVideoId = null" />
    <ion-content>
      <ion-list v-if="videosList.length === 0" :style="{paddingStart: '8px', paddingEnd: '8px'}">
        <template v-for="(playlist, index) in playlists" :key="index">
          <Playlist @click="expandPlayList({ playlist })" 
            @selectVideo="play" 
            :playlist="playlist" 
            :expanded="playlist.expanded"
            :opacity="playlist.opacity" 
            :style="{marginBottom: '8px'}"/>
        </template>
      </ion-list>
      <ion-list v-else>
        <template v-for="(video, index) in videosList" :key="index">
          <ion-card @click="play(video.id.videoId)">
            <ion-card-content>
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="5">
                    <ion-img
                      :style="{ height: '80px', objectFit: 'cover' }"
                      :src="video.snippet.thumbnails.default.url"
                    />
                  </ion-col>
                  <ion-col size="7" class="ion-padding-start">
                    <ion-row>{{ video.snippet.title }}</ion-row>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </template>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { mapMutations } from 'vuex'
import VideoPlayer from "../../components/Templates/VideoPlayer.vue";
import Playlist from "../../components/Templates/Playlist.vue";
// import List from "./List.vue";
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonSearchbar,
  IonList,
  // IonItem,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/vue";

const BASE_URL = "https://www.googleapis.com/youtube/v3/";
const API_KEY = "AIzaSyC2_RZBZYEwdLzcUWYJSWFIW0q3cP5kfPo";

export default {
  name: "CoursesList",
  props: ["courses"],
  // extends: List,
  components: {
    IonPage,
    IonContent,
    IonToolbar,
    IonSearchbar,
    IonList,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    VideoPlayer,
    Playlist,
  },
  data() {
    return {
      searchTerm: "",
      playlists: [],
      videosList: [],
      selectedVideoId: "",
    };
  },
  computed: {
    computedMyRoute() {
      return this.$route.path.includes('course')
    }
  },
  methods: {
    ...mapMutations(['setSyncable']),
    expandPlayList({playlist}) {
      const { expanded } = playlist
      this.playlists = this.playlists.map(pl => 
        ({
          ...pl, 
          expanded: pl.id === playlist.id ? !expanded : false,
          opacity: !expanded && pl.id !== playlist.id // vai expandir e não é minha playlist
        })
      )
    },
    async getPlaylists() {
      // recupero as playlists do canal específicado pelo channelId
      let playlistsAux = store.$_.cloneDeep(this.courses);
      // let playlistsAux = [];

      let playlistIds = "";
      // montando as playlists a partir de links fornecidos do youtube ( com id da playlist )
      for (let playlist of playlistsAux) {
        if (playlist.external) {
          playlist.playlistId = playlist.link.split("list=")[1];
          playlistIds += playlist.playlistId + `,`;
        } else {
          playlist.videosList = await Promise.all(
            playlist.videos.map((video) => {
              let videoId = video.data.link.split("watch?v=")[1];
              return this.getVideoDetails(videoId).then((res) => res[0]);
            })
          );
          playlist.snippet = {
            // resourceId: { videoId: videoId },
            title: playlist.title,
            thumbnails: {
              default: {
                url: playlist.videosList[0].snippet.thumbnails.default.url,
              },
            },
          };

          playlist['expanded'] = false;

          // console.info("playlist manual", playlist);
          this.playlists.push(playlist);
        }
      }

      if (playlistIds) {
        try {
          let ytResponse = await axios.get(BASE_URL + "playlists", {
            params: {
              key: API_KEY,
              id: playlistIds,
              maxResults: 50,
              part: "snippet",
            },
          });

          if (ytResponse.status === 200)
            this.playlists.push(...ytResponse.data.items);

          if (this.playlists.length > 0) {
            this.playlists.forEach((playlist) => {
              if (playlist.etag) {
                axios
                  .get(BASE_URL + "playlistItems", {
                    params: {
                      key: API_KEY,
                      maxResults: 50,
                      playlistId: playlist.id,
                      part: "snippet",
                    },
                  })
                  .then((res) => {
                    playlist.videosList = store.$_.cloneDeep(res.data.items);
                  })
                  .catch((error) => console.error(error));
              }
            });
          }
        } catch (error) {
          console.error("erro", error);
        }
      }
    },

    async getVideoDetails(videoId) {
      return await axios
        .get(BASE_URL + "videos", {
          params: {
            key: API_KEY,
            id: videoId,
            // channelId: 'UCKiDiDdZi0vtief2_noJTQQ',
            part: "snippet",
          },
        })
        .then((res) => res.data.items)
        .catch((error) => console.error("Erro", error));
    },

    search(event) {
      if (event.detail.value !== "") {
        axios
          .get(BASE_URL + "search", {
            params: {
              key: API_KEY,
              type: "video",
              // channelId: 'UCKiDiDdZi0vtief2_noJTQQ',
              part: "snippet",
              q: event.detail.value,
            },
          })
          .then((res) => {
            this.videosList = res.data.items;
          })
          .catch((error) => console.error(error));
      } else {
        this.videosList = [];
      }
    },

    play(videoId) {
      this.selectedVideoId = videoId;
    },
  },
  watch: {
    $route() {
      if (this.computedMyRoute) {
        this.setSyncable(false)
      }
    }
  },
  created() {
    if (this.computedMyRoute) this.setSyncable(false)
    this.getPlaylists();
  },
};
</script>

<style>
</style>