import App from "../App.vue";
import { getCurrentInstance } from 'vue'

const moment = require('moment')

moment.locale('pt-BR');
moment().utcOffset(-4);


const app = getCurrentInstance(App)

/* Vue Filters */
// app.filter('date', (value) => {
//   if (!value) return null
//   if (!(value instanceof Date) && !isNaN(value) && typeof value != 'string') { value = value.toDate() }
//   return moment(value).format("DD/MM/YYYY")
// })
// app.filter('hour', (value) => {
//   if (!value) return null
//   if (!(value instanceof Date) && !isNaN(value) && typeof value != 'string') { value = value.toDate() }
//   return moment(value).format("HH:mm")
// })
// app.filter('timeago', (value) => {
//   if (!value) return null
//   if (!(value instanceof Date) && !isNaN(value) && typeof value != 'string') { value = value.toDate() }
//   return moment(value).fromNow()
// })

export default moment