<template>
  <template v-if="computedFilters && computedFilters.length > 0">
    <ion-content>
      <FormDataTableFilters
        ref="form-data-table-filters"
        :filters="computedFilters"
        :filtersModels="filtersModels"
        :search="search"
        @searching="search.value = $event"
        @filtersChange="filtersModels = $event"
      />
        <ion-card v-for="(item, index) in computedItems.slice(0,maxLoadedItems)" :key="index" 
          :class="item.invalidItem ? 'invalid-item' : 'inherit'">
          <ion-card-header @click="showDetails(item)" :style="{ padding: '8px' }">
            <ion-grid class="ion-no-padding">
              <ion-row class="row-margin-bottom">
                <div
                  v-if="titleHeader"
                  class="ion-text-start"
                  :style="{ fontSize: '0.7em', marginRight: '5px' }"
                >
                  {{ item[titleHeader.value] }}
                </div>
                <small v-if="subTitleHeader">{{
                  item[subTitleHeader.value]
                }}</small>
              </ion-row>
              <template v-if="!item.show">
                <hr :style="{ height: '.25px', width: '100%' }" color="grey" />
                <ion-row class="ion-justify-content-between">
                  <div v-for="(header, index) of allHeaders" :key="index" 
                    :class="item.invalidItem && item.invalidField.header.value === header.value ? 'invalid-header' : 'inherit'">
                    <ion-row class="ion-justify-content-center">
                      <small>{{ header.text }}</small>
                    </ion-row>
                    <ion-row class="ion-justify-content-center">
                      {{ header.prefix ? header.prefix : ''}} {{ item[header.value] }} {{ header.suffix ? header.suffix : ''}} 
                    </ion-row>
                  </div>
                </ion-row>
              </template>
            </ion-grid>
          </ion-card-header>
          <template v-if="item.show">
            <ion-card-content 
                    :class="item.invalidItem ? 'ion-no-padding invalid-header' : 'ion-no-padding'">
              <ion-list>
                <template v-for="(field, index) in headers" :key="index">
                  <ion-item v-show="!field.hide" 
                    :class="item.invalidItem && item.invalidField.header.value === field.value ? 'invalid-header' : 'inherit'">
                    <ion-label position="floating">{{ field.text }}</ion-label>
                    <ion-input
                      :color="item.invalidItem && item.invalidField.header.value === field.value ? 'danger' : 'undefined'"
                      :type="field.input ? field.input.type : ''"
                      @click="$event.target.select()"
                      v-model="item[field.value]"
                      :disabled="readonly || !field.editable"
                      @change="blurField({item, header: field, value: $event.target.value})"
                    />
                  </ion-item>
                </template>
              </ion-list>
              <ion-button :color="item.invalidItem ? 'danger' : 'primary'"
               v-if="!readonly" @click="showDetails(item)" expand="block">
                <ion-icon :icon="checkmarkOutline"></ion-icon> OK {{item.invalidItem ? '(Item com erros)' : ''}}
              </ion-button>
            </ion-card-content>
          </template>
        </ion-card>
        <ion-infinite-scroll
          @ionInfinite="loadData($event)"
          threshold="100px"
          id="infinite-scroll"
          ref="infiniteScroll"
          :disabled="isDisabled">
          <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Buscando mais dados...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

    </ion-content>

  </template>
</template>

<script>
// import store from "../store";
import FormDataTableFilters from './FormDataTableFilters.vue'
import {  
  chevronDown, 
  chevronUp, 
  close,
  checkmarkOutline 
} from "ionicons/icons";
import {
  IonCard,
  IonContent,
  IonCardContent,
  IonCardHeader,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent
  // IonModal,
} from "@ionic/vue";

export default {
  name: "FormDataTable",
  components: {
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    // IonSearchbar,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    // IonToggle,
    // IonCol,
    IonButton,
    IonIcon,
    // IonProgressBar,
    FormDataTableFilters,
    IonInfiniteScroll,
    IonInfiniteScrollContent
    // IonModal,
  },
  emits: ["validated"],
  props: {
    headers: Array,
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "teal",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    exportXls: {
      type: Boolean,
      default: true,
    },
    xlsxName: {
      type: String,
      default: "itens",
    },
    filterItems: {
      type: Function,
      default: (item) => item,
    },
  },
  methods: {
    getModel() {
      const itemsProcessed = this.items.map(item => {
        this.headers.forEach(header => {
          if (header.type == 'integer') {
            item[header.value] = parseInt(item[header.value])
          } else if (header.type == 'float') {
            item[header.value] = parseFloat(item[header.value])
          }
        })
        return item
      })
      return itemsProcessed
    },
    showDetails(item) {
      item.show = !item.show;
    },
    validate() {
      const allValidations = this.items.filter(this.filterItems).every(item => 
        this.headers.filter(header => header.validation).every(header => 
          header.validation.every((f) => {
            const validated = f(item);
            if (typeof validated == "string") {
              // this.errorMessages.push(validated)
              return false;
            } else {
              return validated;
            }
          })
        )
      )

      return allValidations;
    },
    getTotalize() {
      return this.totalize;
    },
    getMaskedValue({header, item}) {
      const value = item[header.value] ? item[header.value] : header.default ? header.default : null
      if (typeof value === 'null' || typeof value === 'NaN') return ''

      const maskedValue = header.type == 'float' || header.type == 'integer'
        ? value.toLocaleString('pt-br', { minimumFractionDigits: header.precisionText, maximumFractionDigits: header.precisionText })
        : value

      const prefix = header.prefix ? header.prefix + " " : "";
      const suffix = header.suffix ? " " + header.suffix : "";

      return prefix + maskedValue + suffix;
    },
    async fetchFiltersCollections() {
      await Promise.all(
        this.filters.map(async (filter) => {
          if (filter.method && filter.method.collectionName) {
            const response = await this.get({
              collection: filter.method.collectionName,
              register: filter.method.collectionName,
              orderBy: "indice",
            });
            filter.method.values = response.map(
              (item) => item[filter.method.collectionField]
            );
            return response;
          }
          return Promise.resolve();
        })
      );
    },
    blurField({ item, value, header }) {
      this.process({ item, value, header }) 
      this.$emit('validated', this.validated)
    },
    process({ item, value, header }) {
      this.setValue({item, header, value})

      if (!this.validateField({header, item})) 
        return

      // Os headers/atributos a serem usados como busca não devem incluir o próprio elemento que está sendo alterado.
      const headers = this.headers.filter(h => h.value != header.value)

      // Array com os dependentes diretos do header, elimina a possibilidade do elemento ser dependente *direto* dele mesmo devido ao filter.
      const directDependentAttributes = this.getDependentAttributes(header, headers)

      // Headers a serem computados respeitando a ordem de precedência da menor posição do array pra maior.
      const dependencyChain = this.getDependencyChain(directDependentAttributes, headers)

      // for of para respeitar a ordem do array `dependencyChain`, que é a ordem de prioridade.
      for (const dependentHeaders of dependencyChain) {
        dependentHeaders.forEach(header => {
          this.computeItem({item, header})
        })
      }
    },
    /**
     * Retorna um array de headers que dependem *diretamente* do `header` passado no primeiro parâmetro
     */
    getDependentAttributes (header, headers) {
      return headers.filter(h => h.dependents && h.dependents.includes(header.value))
    },
    /**
     * Retorna uma cadeia de headers que são dependentes dos headers passados no primeiro argumento.
     * A ordem dos elementos no array indicam o grau de dependência dos headers.
     */
    getDependencyChain(dependents, headers, dependencyChain = []) {
      if (dependents.length == 0)
        return dependencyChain

      const filteredHeaders = headers.filter(h => !dependents.includes(h))
      const newDependents = dependents
        .map(h => this.getDependentAttributes(h, filteredHeaders))
        .reduce((acc,arr) => [...acc, ...arr.filter(el => !acc.includes(el))], [])

      return this.getDependencyChain(newDependents, filteredHeaders, [...dependencyChain, dependents])
    },
    computeItems(){
      this.items.forEach(item => {
        this.headers.forEach(header => {
          item[header.value] = item[header.value] != undefined ? 
                                item[header.value] : 
                                'default' in header ? 
                                  header.default : null
        })

        const computeFirstHeaders = this.headers.filter(h => h.computeFirst !== false)

        computeFirstHeaders.forEach((header) => 
          this.computeItem({item, header})
        )
        item = {...item, invalidItem: false}
      })
      this.loadingItems = false
      this.$emit('validated', this.validated)
    },

    computeItem({item, header}) {
      if (!header || !header.dependents) return 
      const itemDependent = this.getDependentsItem({item, header})
      const computedValue = header.computed({item: itemDependent}) 
      this.setValue({item, header, value: computedValue})
    },

    getDependentsItem({header, item}) {
      return header.dependents.reduce((acc, dependent) => {
        return { ...acc, [dependent]: item[dependent] }
      }, {})
    },

    setValue({value, item, header}, { validate = true, set = true, mask = true, verbose = true } = { validate: true, set: true, mask: true, verbose: true }) {

      if (value == null) {
        if (verbose)
          console.warn(`${header.value} "value" is null`)

        text = ""
        item[header.value] = value

        if (header.required) {
          // this.validated = false
          // this.errorMessages.push('Campo obrigatório')
        }
        return
      } 

      if (header.type == 'integer') {
        value = parseInt(value)
        if (isNaN(value)) {
          if (verbose) {
            console.warn(`${header.value} "value" is NaN`)
          }
          return
        }
      } else if (header.type == 'float') {
        value = parseFloat(value)
        if (isNaN(value)) {
          if (verbose) {
            console.warn(`${header.value} "value" is NaN`)
          }
          return
        } 
        value = this.fixPrecision(value, header.precisionValue)
      }

      if (set) {
        item[header.value] = header.set ? header.set({value, item}) : value
      } else {
        item[header.value] = value
      }

      // const text = this.getMaskedValue({header, item})

      // console.info('text', text);
      // this.validated = validate ? this.validate() : true

      // altera no item original somente se estiver validado
      // if (this.validated)
      //   this.row.item[this.name] = item[header.value]
    },

    validateField({item, header}) {
      item.errorMessages = []
      const { validation = [] } = header
      return validation.every(f => {
        const validated = f(item)
        if (typeof validated == 'string') {
          item.invalidField = { header }
          item.invalidItem = true
          item.errorMessages.push(validated)
          return false
        } else {
          item.invalidField = validated ? { header } : null
          item.invalidItem = !validated
          return validated
        }
      })

    },

    fixPrecision(value, precision = 4) {
      if (!Number.isFinite(value)) {
        return NaN
      }

      let fixed = value
      if (!Number.isInteger(fixed)) {
        let [integerPart, mantissa] = fixed.toString().split('.')
        
        if (mantissa && mantissa.length > precision) {
          const balanceDigit = parseInt(mantissa[precision])
          if (balanceDigit >= 5) {
            const fix = (parseInt(`${integerPart}${mantissa.slice(0, precision)}`) + 1).toString()
            
            integerPart = fix.slice(0, fix.length - precision)
            mantissa = fix.slice(-precision)
          } else {
            mantissa = mantissa.slice(0, precision)
          }
          fixed = parseFloat(integerPart + '.' + mantissa)
        }
      }

      return fixed
    },
    // pushData() {
    //   const max = this.loadedItems.length + 20;
    //   const min = max - 20;
    //   console.info('this.loadedItems', this.loadedItems);
    //   console.info('max', max);
    //   console.info('min', min);
    //   for (let i = min; i < max; i++) {
    //     this.loadedItems.push(this.computedItems[i]);
    //   }
    //   this.showItems = true
    // },
    // toggleInfiniteScroll() {
    //   this.isDisabled = !this.isDisabled;
    // },
    loadData(event) {
      this.maxLoadedItems += 20
      // setTimeout(() => {
      //   this.pushData();
        if (event) {
          event.target.complete()
        }

      //   // App logic to determine if all data is loaded
      //   // and disable the infinite scroll
        if (this.maxLoadedItems >= this.computedItems.length) {
        // if (this.loadedItems.length === this.computedItems.length) {
          if (event) {
            event.target.disabled = true;
          }
        }
      // }, 500);
    },
    // searchEnabled($event) {
    //   this.showItems = false
    //   this.search.value = $event
    //   this.loadedItems = []
    //   this.loadData()
    // }
  },
  computed: {
    headersMobile() {
      return this.headers.filter((h) => h.mobile);
    },
    computedFilters() {
      const filters = this.headers.filter(x => x.filterable === true)
      filters.forEach(el => {
        const ixH = this.headers.findIndex(x => x.value === el.value)
        switch (el.filterType) {
          case 'switch':
            this.headersData[ixH].filter = (header, item) => {
              const validated = (item[header.value] || !this.filtersModels[el.value])
              if (this.filtersModels.invalidItem)
                return validated /*&& !row.validated()*/
              return validated
            }
            break;
        
          default:
            this.headersData[ixH].filterItems =  [ ...new Set(this.items.map(x => x[el.value])) ]
            this.headersData[ixH].filter = (header, item) => {
              if (!this.filtersModels[el.value])
                return true
              if (this.filtersModels[el.value].length === 0)
                return true

              const validated = this.filtersModels[el.value].findIndex(n => n == item[header.value]) > -1
              if (this.filtersModels.invalidItem)
                return validated /*&& !row.validated()*/
              return validated
            }
            break;
        }
      })
      
      filters.push({
        filterType: 'switch',
        filterLabel: 'Com erro',
        value: 'invalidItem',
        hide: this.readonly
      })
      return filters
    },
    totalize() {
      let totalize = null;

      if (this.items && this.items.length > 0) {
        totalize = [];
        this.headersMobile
          .filter((x) => x.totalize)
          .forEach((elx) => {
            const total = this.items
              .map((x) => x[elx.value])
              .reduce((acc, cur) => acc + cur);
            const prefix = elx.prefix ? elx.prefix + " " : "";
            const suffix = elx.suffix ? " " + elx.suffix : "";
            totalize.push({
              label: elx.text,
              text:
                prefix +
                total.toLocaleString("pt-br", {
                  minimumFractionDigits: elx.precisionText,
                  maximumFractionDigits: elx.precisionText,
                }) +
                suffix,
              value: elx.value,
            });
          });
      }
      return totalize;
    },
    isFiltered() {
      if (this.search.value.length > 0)
        return true
      
      return Object.entries(this.filtersModels)
        .some(([_, model]) => {
          if (Array.isArray(model) && model.length > 0)
            return true
          if (typeof model == "boolean" && model)
            return true
          return false
        })
    },
    computedItems() {
      const filters = Object.entries(this.filtersModels).filter(([key,value]) => Array.isArray(value) && value.length === 0 ? false : true).map(([key, value]) =>  ({header: key, value}) )
      return (!this.isFiltered) ? this.items : this.items.filter(i => {
        const validFilters = filters.every(f => {
          if (typeof f.value === 'boolean') {
            return !f.value || i[f.header]
          } else if (typeof i[f.header] === 'undefined') { 
            return true
          } else {
            return f.value.some(filter => (filter+'').trim().toLowerCase() === (i[f.header]+'').trim().toLowerCase())
          }
        })
        const searcher = this.headersMobile.map(hm => (i[hm.value] + '').toLowerCase().trim()).join('')
        return validFilters && searcher.includes(this.search.value.toLowerCase().trim())
      })
    },
    validated() {
      return !(this.items.filter(i => i.invalidItem).length > 0) && this.items.filter(this.filterItems).length > 0
    },
  },
  setup() {
    return {
      chevronDown,
      chevronUp,
      close,
      checkmarkOutline
    };
  },
  data() {
    return {
      quantity: 0,
      titleHeader: {},
      subTitleHeader: {},
      allHeaders: [],
      errorMessages: [],
      headersData: [],
      loadingItems: true,
      // loadedItems: [],
      showItems: false,
      isDisabled: false,
      maxLoadedItems: 20,
      invalidItems: [],
      search: {
        value: "",
      },
      filtersModels: {
        invalidItem: false,
      },
    };
  },
  created() {
    this.headersData = this.headers
    this.computeItems()
    // this.pushData()
    this.fetchFiltersCollections()
    this.titleHeader = this.headersMobile.find(
      (hm) => hm.mobile.type === "title"
    );
    this.subTitleHeader = this.headersMobile.find(
      (hm) => hm.mobile.type === "subTitle"
    );
    this.allHeaders = this.headersMobile
      .filter((hm) => hm.mobile.type === "all")
      .sort((h1, h2) => {
        if (h1.mobile.horizontalPosition > h2.mobile.horizontalPosition)
          return 1;
        if (h1.mobile.horizontalPosition < h2.mobile.horizontalPosition)
          return -1;
        return 0;
      });
  },
};
</script>

<style scoped>
  .row-margin-bottom {
    margin-bottom: 8px;
  }
  .invalid-item, .invalid-header {
    border: 1pt solid var(--ion-color-danger);
  }
  .invalid-header *:not(ion-button, ion-icon) {
    color: var(--ion-color-danger)
  }
</style>