<template>
  <ion-page>
    <ion-content>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-card-title> Monitor </ion-card-title>
              </ion-card-header>
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-padding-bottom">
                  <ion-row class="ion-padding-horizontal" :style="{borderBottomStyle: 'solid'}">
                    <ion-col class="ion-align-items-baseline" v-for="(header, index) of dataTables.periodSales.headers" :key="index" :style="{fontSize: '0.8em'}" >
                      <strong>{{ header.text }}</strong>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-padding-horizontal" v-for="(data, index) of dataTables.periodSales.data" :key="index" :style="{fontSize: '0.6em', paddingTop: '16px'}">
                    <ion-col v-for="(header, index) of dataTables.periodSales.headers" :key="index" >
                      {{ data[header.value] }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <!-- <table>
                  <tr style="width: 100%">
                    <th
                      v-for="(header, index) of dataTables.periodSales.headers"
                      :key="index"
                      :style="{ fontSize: '0.8em' }"
                    >
                      <strong>{{ header.text }}</strong>
                    </th>
                  </tr>
                  <tr
                    v-for="(data, index) of dataTables.periodSales.data"
                    :key="index"
                  >
                    <td
                      v-for="(header, index) of dataTables.periodSales.headers"
                      :key="index"
                      
                      :style="{ fontSize: '0.6em' }"
                    >
                      {{ data[header.value] }}
                    </td>
                  </tr>
                </table> -->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-card-title> Clientes </ion-card-title>
              </ion-card-header>
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-padding-bottom">
                  <ion-row class="ion-padding" :style="{borderBottomStyle: 'solid'}">
                    <ion-col v-for="(header, index) of dataTables.customerSales.headers" :key="index" :style="{fontSize: '0.8em'}" >
                      <strong>{{ header.text }}</strong>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-padding-horizontal" v-for="(data, index) of dataTables.customerSales.data" :key="index" :style="{fontSize: '0.6em', paddingTop: '16px'}">
                    <ion-col v-for="(header, index) of dataTables.customerSales.headers" :key="index" >
                      {{ data[header.value] }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <!-- <table>
                  <tr>
                    <th
                      v-for="(header, index) of dataTables.customerSales
                        .headers"
                      :key="index"
                      :style="{ fontSize: '0.8em' }"
                    >
                      <strong>{{ header.text }}</strong>
                    </th>
                  </tr>
                  <tr
                    v-for="(data, index) of dataTables.customerSales.data"
                    :key="index"
                  >
                    <td
                      v-for="(header, index) of dataTables.customerSales
                        .headers"
                      :key="index"
                      
                      :style="{ fontSize: '0.6em' }"
                    >
                      {{ data[header.value] }}
                    </td>
                  </tr>
                </table> -->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-card-title> Produtos </ion-card-title>
              </ion-card-header>
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-padding-bottom">
                  <ion-row class="ion-padding" :style="{borderBottomStyle: 'solid'}">
                    <ion-col v-for="(header, index) of dataTables.productSales.headers" :key="index" :style="{fontSize: '0.8em'}" >
                      <strong>{{ header.text }}</strong>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-padding-horizontal" v-for="(data, index) of dataTables.productSales.data" :key="index" :style="{fontSize: '0.6em', paddingTop: '16px'}">
                    <ion-col v-for="(header, index) of dataTables.productSales.headers" :key="index" >
                      {{ data[header.value] }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <!-- <table style="display: block">
                  <tr>
                    <th
                      v-for="(header, index) of dataTables.productSales.headers"
                      :key="index"
                      :style="{ fontSize: '0.8em' }"
                    >
                      <strong>{{ header.text }}</strong>
                    </th>
                  </tr>
                  <tr
                    v-for="(data, index) of dataTables.productSales.data"
                    :key="index"
                  >
                    <td
                      v-for="(header, index) of dataTables.productSales.headers"
                      :key="index"
                      
                      :style="{ fontSize: '0.6em' }"
                    >
                      {{ data[header.value] }}
                    </td>
                  </tr>
                </table> -->
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
} from "@ionic/vue";
import store from "../store"
import { createNamespacedHelpers, mapMutations } from "vuex";
const { mapActions: registersActions } = createNamespacedHelpers("registers");
const { mapState: authState } = createNamespacedHelpers("auth");

export default {
  name: "Home",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
  },
  data() {
    return {
      dialog: false,
      dataTables: {
        customerSales: {
          headers: [
            {
              text: "Código",
              value: "ID_CLIENTE",
              type: "string",
              clickerFilter: { field: "ID_CLIENTE" },
              hidden: true,
            },
            { text: "Cliente", value: "A1_NOME", type: "string" },
            { text: "Faturado c/ IPI", value: "VAL_IPI" },
            {
              text: "Não Faturado",
              value: "NAO_FAT",
              class: "red--text text--darken-4",
              cellClass: "red--text text--darken-4",
            },
          ],
          data: [],
          expansable: false,
          searchable: true,
          searchEnabled: false,
          expanded: [],
          singleExpand: false,
          search: "",
          clickedFilters: {
            dataTablesTarget: ["productSales"],
            field: null,
            operator: "=",
            value: null,
          },
          itemFiltered: {
            item: null,
            index: null,
            level: null,
          },
        },
        productSales: {
          headers: [
            { text: "Produto", value: "BM_DESC" },
            { text: "Faturado c/ IPI", value: "VAL_IPI", type: "money" },
            {
              text: "Não Faturado",
              value: "NAO_FAT",
              type: "money",
              class: "red--text text--darken-4",
              cellClass: "red--text text--darken-4",
            },
          ],
          subHeaders: {
            products: [
              { text: "Produto", value: "B1_DESC" },
              { text: "Faturado c/ IPI", value: "VAL_IPI", type: "money" },
              {
                text: "Não Faturado",
                value: "NAO_FAT",
                type: "money",
                class: "red--text text--darken-4",
                cellClass: "red--text text--darken-4",
              },
            ],
          },
          data: [],
          expansable: true,
          searchable: true,
          searchEnabled: false,
          expanded: [],
          singleExpand: false,
          search: "",
        },
        periodSales: {
          headers: [
            {
              text: "Ano",
              value: "ANO",
              type: "string",
              cellClass: "font-weight-bold",
              clickerFilter: { field: "LEFT(EMISSAO, 4)" },
            },
            {
              text: "Qtd Faturado",
              value: "QTD_FAT",
              type: "number",
              cellClass: "font-weight-bold",
            },
            {
              text: "Faturado c/ IPI",
              value: "VAL_IPI",
              type: "money",
              cellClass: "font-weight-bold",
            },
            {
              text: "Qtd Não Faturado",
              value: "QTD_N_FAT",
              type: "number",
              class: "red--text text--darken-4",
              cellClass: "font-weight-bold red--text text--darken-4",
            },
            {
              text: "Não Faturado",
              value: "NAO_FAT",
              type: "money",
              class: "red--text text--darken-4",
              cellClass: "font-weight-bold red--text text--darken-4",
            },
          ],
          subHeaders: {
            yearMonth: [
              {
                text: "Mês",
                value: "ANOMES",
                type: "string",
                cellClass: "font-weight-bold",
                clickerFilter: { field: "LEFT(EMISSAO, 6)" },
              },
              {
                text: "Qtd Faturado",
                value: "QTD_FAT",
                type: "number",
                cellClass: "font-weight-bold",
              },
              {
                text: "Faturado c/ IPI",
                value: "VAL_IPI",
                type: "money",
                cellClass: "font-weight-bold",
              },
              {
                text: "Qtd Não Faturado",
                value: "QTD_N_FAT",
                type: "number",
                class: "red--text text--darken-4",
                cellClass: "font-weight-bold red--text text--darken-4",
              },
              {
                text: "Não Faturado",
                value: "NAO_FAT",
                type: "money",
                cellClass: "font-weight-bold red--text text--darken-4",
              },
            ],
            type: [
              {
                text: "Tipo",
                value: "TIPO",
                type: "string",
                clickerFilter: { field: "TIPO" },
              },
              {
                text: "Qtd Faturado",
                value: "QTD_FAT",
                type: "number",
                cellClass: "font-weight-bold",
              },
              { text: "Faturado c/ IPI", value: "VAL_IPI", type: "money" },
              {
                text: "Qtd Não Faturado",
                value: "QTD_N_FAT",
                type: "number",
                class: "red--text text--darken-4",
                cellClass: "font-weight-bold red--text text--darken-4",
              },
              {
                text: "Não Faturado",
                value: "NAO_FAT",
                type: "money",
                cellClass: "red--text text--darken-4",
              },
            ],
          },
          data: [],
          expansable: true,
          searchable: false,
          searchEnabled: false,
          expanded: [],
          singleExpand: false,
          search: "",
          clickedFilters: {
            dataTablesTarget: ["customerSales", "productSales"],
            field: null,
            operator: "=",
            value: null,
          },
          itemFiltered: {
            item: null,
            index: null,
            level: null,
          },
        },
      },
      filters: {
        year: {
          data: [],
          selecteds: [],
          textFilter: "Ano",
          field: "LEFT(EMISSAO, 4)",
          cols: 4,
          chips: true,
          multiple: true,
        },
        yearMonth: {
          data: [],
          selecteds: [],
          textFilter: "Ano/Mês",
          field: "LEFT(EMISSAO, 6)",
          cols: 8,
          chips: true,
          multiple: true,
        },
        superSeller: {
          data: [],
          selecteds: [],
          textFilter: "Coordenador",
          field: [
            "ID_FILIAL + ISNULL(SA3010.A3_SUPER, SA3020.A3_SUPER)",
            "ID_FILIAL + ISNULL(SA3010.A3_GEREN, SA3020.A3_GEREN)",
          ],
          chips: true,
          multiple: true,
          orAnd: "OR",
        },
        seller: {
          data: [],
          selecteds: [],
          textFilter: "Vendedor",
          field: "ID_VENDEDOR",
          chips: true,
          multiple: true,
        },
        customer: {
          data: [],
          selecteds: [],
          textFilter: "Clientes",
          field: "ID_CLIENTE",
          dense: true,
          chips: true,
          multiple: true,
        },
      },
    };
  },
  computed: {
    ...authState(["user"]),
    allRequests() {
      const dataTables = []; // [{ id: 'customer'}, {id: 'product'}]
      for (const key in this.dataTables) {
        dataTables.push({ resource: key });
      }
      return dataTables;
    },
    clicksFiltered() {
      let filters = [];
      for (const dTable in this.dataTables) {
        if (
          !(
            this.dataTables[dTable] &&
            this.dataTables[dTable].clickedFilters &&
            this.dataTables[dTable] &&
            this.dataTables[dTable].clickedFilters.field
          )
        ) {
          continue;
        }
        const { field, operator, value } =
          this.dataTables[dTable].clickedFilters;
        filters = this.dataTables[dTable].clickedFilters.dataTablesTarget.map(
          (dt) => ({ dt, field, operator, value })
        );
      }
      return filters;
    },
  },
  methods: {
    ...registersActions(["getDashboardRequest", "getGpEmpresa"]),
    ...mapMutations(["setLoading"]),
    // getStyle({header, item}){
    //   if (item.filtered) return {backgroundColor: 'lightgray!important'}
    //   return {}
    // },
    isItemFiltered({ item, index, dataTable, level }) {
      return (
        dataTable &&
        dataTable.itemFiltered &&
        dataTable.itemFiltered.item &&
        index === dataTable.itemFiltered.index &&
        level === dataTable.itemFiltered.level
      );
    },
    request({ dataTables, filters, filterExtras }) {
      this.setLoading({ stats: true });

      // const allPromises = dataTables.map((dt) =>
      //   this.getDashboardRequest({
      //     resource: dt.resource,
      //     filters,
      //     filterExtras,
      //   }).then((result) => ({ resource: dt.resource, result }))
      // );

      // Promise.all(allPromises).then((values) => {
        // values.map((v) => (this.dataTables[v.resource].data = v.result));
        this.setLoading({ stats: false });
      // });
    },
    rowSelect(selectedYear, selectedMonth, selectedStatus) {
      this.selectedYearRow = selectedYear;
      this.selectedMonthRow = selectedMonth;
      this.selectedStatusRow = selectedStatus;
    },
    async preFilter() {
      let retorno = ``;
      if (this.user && this.user.claims && this.user.claims.admin)
        retorno = ` 1=1 `;
      else {
        const gpEmpresa = await this.getGpEmpresa("SA3");
        retorno = ` '${
          (this.user && this.user.SA3 && this.user.SA3.A3_COD) || ``
        }' IN (SA3${gpEmpresa}.A3_COD, SA3${gpEmpresa}.A3_SUPER, SA3${gpEmpresa}.A3_GEREN) `;
      }
      return retorno;
    },
    toggle(key) {
      this.$nextTick(() => {
        if (this.likesAll(key)) {
          key.selecteds = [];
        } else {
          key.selecteds = key.data.map((d) => d.value).slice();
        }
      });
    },
    icon(key) {
      if (this.likesAll(key)) return "mdi-close-box";
      if (this.likesSome(key)) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAll(key) {
      return key.selecteds.length === key.data.length;
    },
    likesSome(key) {
      return key.selecteds.length > 0 && !this.likesAll(key);
    },
    async generateFilters() {
      // this.setLoading({ stats: true });
      const preFilter = await this.preFilter();
      const result = await this.getDashboardRequest({
        resource: "generateFilters",
        preFilter,
      });
      
      for (const r of result) {
        this.filters[r.filter].data = r.data;
        this.filters[r.filter].selecteds = r.data.map((d) => d.value);
      }
      // this.setLoading({ stats: false });
    },
    applyFilter({ dataTables }) {
      const filtersSelecteds = Object.keys(this.filters)
        .filter((f, index) => !this.likesAll(this.filters[f]))
        .map((f, index) => {
          const { field, selecteds, orAnd } = this.filters[f];
          const values =
            selecteds && selecteds.length > 0
              ? selecteds.map((s) => `'${s}'`).join(",")
              : "''";
          if (typeof field === "string") return `${field} IN (${values}) `;
          else
            return `(${field
              .map((f) => `${f} IN (${values})`)
              .join(` ${orAnd} `)})`;
        });

      const filterExtras = this.clicksFiltered;

      if (!dataTables) dataTables = this.allRequests;
      this.request({
        dataTables,
        filters: filtersSelecteds.join(" AND "),
        filterExtras,
      });
      this.dialog = false;
    },
    clickFilter({ dataTable, header, item, subHeaders, index, level }) {
      const headersTable = subHeaders || dataTable.headers;
      const headerFilterer = header.clickerFilter
        ? header
        : headersTable.find((h) => h.clickerFilter);
      if (!headerFilterer) return;

      const dataTablesTarget = dataTable.clickedFilters.dataTablesTarget.map(
        (dtt) => ({ resource: dtt })
      );

      const justClear = this.isItemFiltered({ item, index, dataTable, level });

      dataTable.itemFiltered = {
        item: null,
        index: null,
        header: null,
        level: null,
      };

      if (justClear) {
        dataTable.clickedFilters.field = null;
        dataTable.clickedFilters.value = null;
      } else {
        if (headerFilterer.type === "string") {
          dataTable.clickedFilters.field = headerFilterer.clickerFilter.field;
          dataTable.clickedFilters.value = `'${item[headerFilterer.value]}'`;
          dataTable.itemFiltered = { item, index, header, level };
        }
      }

      this.applyFilter({ dataTables: dataTablesTarget });
    },
  },
  async created() {
    await this.generateFilters();
    this.applyFilter({});
  },
};
</script>
