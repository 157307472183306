<template>
  <ion-card>
    <div :style="{position: 'relative', width: 'auto'}">
      <ion-fab
        @click="$emit('closePlayer')"
        slot="fixed"
        vertical="top"
        horizontal="end">
        <ion-icon 
          name="close"></ion-icon>
      </ion-fab>
      <iframe :src="'https://www.youtube.com/embed/'+videoId" :style="{border: '0px', height: 'auto', width: '100%'}"/>
    </div>
  </ion-card>
</template>

<script>
  import {
    IonCard,
    IonFab,
    IonIcon
  } from '@ionic/vue'
  export default {
    name: 'VideoPlayer',
    props: ['videoId'],
    components: {
      IonFab,
      IonCard,
      IonIcon,
    }
  }
</script>

<style>
  ion-card {
    margin: 0;
  }
  ion-fab ion-icon {
    cursor: pointer;
  }
</style>