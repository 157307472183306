<template>
  <ion-page>
    <ion-content>
      <ion-list :style="{paddingStart: '8px', paddingEnd: '8px'}">
        <template v-for="(report, index) in reports" :key="index">
          <ion-card @click="showReport(report.id)" class="ion-activatable ripple-parent" style="margin-bottom: 8px;">
            <ion-ripple-effect></ion-ripple-effect>
            <ion-card-title class="ion-padding">
              <ion-grid class="ion-no-padding">
                <ion-row class="ion-no-padding">
                  <ion-col size="1">
                    <ion-icon :icon="report.icon"></ion-icon>
                  </ion-col>
                  <ion-col size="11">
                    <ion-label>{{ report.name }}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-title>
          </ion-card>
        </template>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonList,
  IonCard,
  IonCardTitle,
  // IonCardContent,
  IonLabel,
  IonPage,
  IonContent,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
} from "@ionic/vue";
import {documentText} from "ionicons/icons"
import store from "../../store";
import { mapMutations } from 'vuex'

export default {
  name: "ReportsList",
  props: ["reports"],
  components: {
    IonList,
    IonCard,
    IonCardTitle,
    // IonCardContent,
    IonLabel,
    IonPage,
    IonContent,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonRippleEffect,
  },
  watch: {
    $route() {
      if (this.computedMyRoute) {
        this.setSyncable(false)
      }
    }
  },
  computed: {
    computedMyRoute() {
      return this.$route.path.includes('report')
    }
  },
  methods: {
    ...mapMutations(['setSyncable']),
    showReport(reportId) {
      const path = store.$router.currentRoute.value.path;
      store.$router.push(path + "/detalhes/" + reportId);
    },
  },
  created() {
    if (this.computedMyRoute) this.setSyncable(false)
  },
  setup(){
    return{
      documentText
    }
  }
};
</script>

<style>
</style>