export default {
  data() {
    return {
      registration: null,
      isOffline: false,
    }
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swOffline', this.setConnected)
  },
  methods: {
    setConnected(event) {
      this.registration = event.detail
      this.isOffline = !event.detail.connected
    }
  }
}