import {createRouter, createWebHistory} from "@ionic/vue-router";
import Home from "../views/Home.vue";
import Splash from "../views/Splash.vue";
import SignIn from "@/views/Auth/SignIn";
import List from "../views/Registers/List.vue";
import Register from "@/views/Registers/Register.vue"
import CoursesList from "@/views/Registers/CoursesList.vue"
import TicketsList from "@/views/Registers/TicketsList.vue"
import TicketsRegister from "@/views/Registers/TicketsRegister.vue"
import NotificationsList from "@/views/Registers/NotificationsList.vue"
import TabNavigation from '@/components/Templates/TabNavigation'
import ReportsList from '@/views/Registers/ReportsList.vue'
import GeneratedReportsRegister from '@/views/Registers/GeneratedReportsRegister.vue'

const createTabsRoutes = ({ route, props, listComponent }) => {
  route.path = route.path
  route.component = listComponent //Content
  route.props = props
  route.meta = { ...route.meta }

  return route
}

const createCrudRoutes = ({ route, props, registerComponent }) => {
    let routes = []

 if (props.claims.view ) 
    routes.push(
    {
      path: '/tabs/'+route.path+"/detalhes/:id",
      name: `Detalhes do Item - ${route.name}`,
      component: registerComponent,
      props: { ...props, readonly: false },
      meta: { ...route.meta, menu: false, claimRoute: 'view', title: "Detalhes" }
    })
   
  // if (props.claims.add && (userData?.claims?.admin || userData?.rules?.find(rId => userRules.find(ur => ur.id === rId && eval(`ur.claims?.${props.register}?.add`)))))
  if (props.claims.add){
    routes.push({
      path: '/tabs/'+route.path+"/cadastrar",
      name: `Cadastrar ${route.name}`,
      component: registerComponent,
      props,
      meta: { ...route.meta, menu: true, title: "Cadastrar", claimRoute: 'add', icon: "mdi-plus" }
    })

    /**
     * TO-DO
     * Lembrar de fazer esse tratamento para cópia e deixar algo mais genérico
     */
    if (route.register === 'SC5')
    routes.push({
      path: '/tabs/'+route.path+"/bonificar/:id",
      name: `Bonificar ${route.name}`,
      component: registerComponent,
      props,
      meta: { ...route.meta, menu: false, title: "Cadastrar", claimRoute: 'add', icon: "mdi-plus" }
    })
  }

  // if (props.claims.edit && (userData?.claims?.admin || userData?.rules?.find(r => eval(`r.claims?.${props.register}?.edit`))))
  if (props.claims.edit)
    routes.push({
      path: '/tabs/'+route.path+"/editar/:id",
      name: `Editar Item - ${route.name}`,
      component: registerComponent,
      props: { ...props, edit: true },
      meta: { ...route.meta, menu: false, claimRoute: 'edit', title: "Editar" }
    })

  return routes
}

const createDashRoutes = ({ route, registerComponent, reports }) => {
  let routes = []

  reports.map(propReport => {
    routes.push({
      path: route.path+'/detalhes/'+propReport.id,
      name: propReport.name,
      component: registerComponent,
      props: propReport,
      meta: {...route.meta, icon: propReport.icon, menu: true, claimRoute: 'view'}
    })
  })

  return routes
}


const createCustomRouter = (integrations, userRules = [], reports=[], courses=[]) => {
  let generatedTabsRoutes = []
  let generatedCrudRoutes = []

  // Cria as rotas de listagem, edição, cadastro e visualização de cada integração cadastrada  
  const routesFromIntegrations = integrations.map(integration => {
    let orderBy = integration.orderBy || ''
    try {
      if (['{', '['].some(symbol => orderBy.includes(symbol)))
        orderBy = JSON.parse(orderBy)
    } catch (e) {
      console.warn(`Error when trying to define "orderBy" in route ${integration.name}.`, e)
    }

    let filters = ''
    try {
      filters = (integration.filters) ? JSON.parse(integration.filters) : null
    } catch (e) {
      console.warn(`Error when trying to define "filters" in route ${integration.name}.`, e)
    }

    let orderSubcollection = integration.orderSubcollection || ''
    try {
      if (['{', '['].some(symbol => orderSubcollection.includes(symbol)))
        orderSubcollection = JSON.parse(orderSubcollection)
    } catch (e) {
      console.warn(`Error when trying to define "orderSubcollection" in route ${integration.name}.`, e)
    }

    const parentRoute = {
      path: `${integration.collection}`,
      name: integration.name,
      register: integration.register,
      meta: {
        menu: true,
        icon: integration.listIcon || 'mdi-lan-connect',
        requiresAdmin: integration.requiresAdmin || false
      },
    }

    // props comum a todas as rotas do crud
    const props = {
      ...integration,
      integration: true,
      filters,
      orderBy,
      orderSubcollection,
      claims: {
        add: ('add' in integration) ? integration.add : false,
        sync: ('sync' in integration) ? integration.sync : false,
        view: ('view' in integration) ? integration.view : true,
        edit: ('edit' in integration) ? integration.edit : false,
        delete: ('delete' in integration) ? integration.delete : false,
        offline: ('offline' in integration) ? integration.offline : false,
      },
    }

    generatedTabsRoutes.push(createTabsRoutes({route: parentRoute, props, listComponent: List}))
    generatedCrudRoutes.push(...createCrudRoutes({route: parentRoute, props, registerComponent: Register}))
  })

  /** Dashboard routes */
  const reportsRoute = {
    path: '/tabs/reports',
    name: 'Relatórios',
    register: 'reports',
  }

  
  const propsFromReports = reports.map(report => ({
    ...report,
    claims: {
      add: false,
      sync: false,
      view: true,
      edit: false,
      delete: false,
    }
  }))

  /** Courses routes */
  const coursesRoute = {
    path: '/tabs/courses',
    name: 'Cursos',
    register: 'courses',
  }

  generatedCrudRoutes.push(
    ...createDashRoutes({route: reportsRoute, registerComponent: GeneratedReportsRegister, userRules, reports: propsFromReports}),
  )

  const routes = [
    ...generatedCrudRoutes,
    
    {
      path: "/login",
      name: "Entrar",
      component: SignIn,
      meta: {public: true, menuPublic: true},
    },
    {
      path: "/splash",
      name: "Aguarde",
      component: Splash,
      meta: {},
    },
    {
      path: "/notifications/listar",
      component: NotificationsList,
    },
    {
      path: "/tabs/tickets/detalhes/:id",
      component: TicketsRegister,
      props:{
        readonly: true,
        register: 'tickets',
        collection: 'tickets'
      }
    },
    {
      path: "/tabs/tickets/cadastrar",
      component: TicketsRegister,
    },
    {
      path: "/",
      redirect: "/tabs/SC5",
    },
    {
      path: '/tabs/',
      component: TabNavigation,
      children:[
        {
          path: '',
          redirect: '/tabs/SC5'
        },
        {
          path: "dashboard",
          component: Home,
        },
        {
          path: "courses",
          component: CoursesList,
          props: {courses}
        },
        {
          path: "reports",
          component: ReportsList,
          props: {reports: propsFromReports}
        },
        {
          path: "tickets",
          component: TicketsList,
        },

        ...generatedTabsRoutes
      ]
    },
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  return router
}

export default createCustomRouter