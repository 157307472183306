<template>
  <ion-badge class="bottom-right" v-if="justBadge && (unreadNotifications.length > 0)" >
    {{ unreadNotifications.length }}
  </ion-badge>

  <ion-card v-if="!justBadge">
    <ion-card-header @click="showUnreadNotifications = !showUnreadNotifications">
      <ion-card-title>
        <ion-row class="ion-align-items-center">
          <ion-icon :icon="notificationsIcon" :style="{marginEnd: '15px'}"/>
          <ion-label>Notificações</ion-label>
        </ion-row>
      </ion-card-title>
      <ion-badge class="center-right" v-if="!justBadge && (unreadNotifications.length > 0)">
        {{ unreadNotifications.length }}
      </ion-badge>
    </ion-card-header>
    <ion-card-content v-if="showUnreadNotifications && (unreadNotifications.length > 0 || readNotifications.length > 0)" class="ion-no-padding">      <ion-list class="ion-no-padding">
        <ion-item button v-for="notification in unreadNotifications" :key="notification"  @click="notification.showNotification = !notification.showNotification">
          <ion-grid >
            <ion-row class="ion-align-items-center">
              <ion-col size="1">
                <ion-icon :color="notification.type" :icon="config && config.types[notification.type].icon || alertCircle" />
              </ion-col>
              <ion-col size="10">
                <ion-label>
                  {{ notification.title }}
                </ion-label>
                <small :style="{opacity: '0.3', textIndent: '10px'}">
                  {{ notification.message }}
                </small>
              </ion-col>
              <ion-col size="1" v-show="!notification.status.read">
                <ion-icon :icon="ellipse" :style="{width: '10px', height: '10px', color: 'var(--ion-color-primary)'}"/>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-alert
            :is-open="notification.showNotification"
            :header="notification.title"
            :message="notification.details"
            :buttons="buttons"
            @didDismiss="markAsRead(notification)"/>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item :style="{opacity: '0.6'}" button v-for="notification in readNotifications" :key="notification"  @click="notification.showNotification = !notification.showNotification">
          <ion-grid >
            <ion-row class="ion-align-items-center">
              <ion-col size="1">
                <ion-icon :color="notification.type" :icon="config && config.types[notification.type].icon || alertCircle" />
              </ion-col>
              <ion-col size="11">
                <ion-label>
                  {{ notification.title }}
                </ion-label>
                <small :style="{opacity: '0.3', textIndent: '10px'}">
                  {{ notification.message }}
                </small>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-alert
            :is-open="notification.showNotification"
            :header="notification.title"
            :message="notification.details"
            :buttons="buttons"
            @didDismiss="markAsRead(notification)"/>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex'
import { 
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonRow,
  IonCardTitle,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonCardContent,
  IonCard,
  IonBadge,
  IonAlert,
} from "@ionic/vue"
const { mapState:registersState, mapActions:registersActions } = createNamespacedHelpers('registers')
const { mapState:authState } = createNamespacedHelpers('auth')
import { notifications,  ellipse, alertCircle} from "ionicons/icons";
import store from '../../store'
export default {
  name: 'Notification',
  props: {
    justBadge: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components:{
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonRow,
    IonCardTitle,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonCardContent,
    IonCard,
    IonBadge,
    IonAlert,
  },
  data() {
    return {
      model: [],
      reset: false,

      /**
       * Usado para evitar marcar a mesma notificação como lida mais de uma vez enquanto a primeira chamada ainda não foi resolvida
       */
      loading: [],

      /**
       * Configuração
       */
      width: '400px',
      noTitle: '(Sem Título)',
      limit: 10,
      markingDelivered: false,
      showUnreadNotifications: false
    }
  },
  computed: {
    ...mapState({ config: 'notifications' }),
    ...registersState(['registers']),
    ...authState(['user']),
    userId() {
      return this.user ? this.user.uid : null
    },
    notifications() {
      return this.registers.notifications && this.registers.notifications.map(_item => {
        const item = { ..._item, showNotification: false }
        return item
      }) || []

    },
    unreadNotifications() {
      return this.registers.notifications ? this.registers.notifications.filter(notification => notification.status && !notification.status.read) || [] : []
    },
    readNotifications(){
      return this.registers.notifications ? this.registers.notifications.filter(notification => notification.status && notification.status.read) || [] : []
    },
    undeliveredNotifications() {
      return this.registers.notifications ? this.registers.notifications && this.registers.notifications.filter(notification => notification.status && !notification.status.delivered) || [] : []
    },
  },

  watch: {
    notifications() {
      this.model = [...this.unreadNotifications]
      this.notifications.forEach(item => {
        if (item.type == "success")
        if (item.status && (!item.status.read || this.loading.indexOf(item.id) == -1))
          return

        this.loading.splice(this.loading.indexOf(item.id), 1)
      })
    },
    async undeliveredNotifications() {
      if (this.undeliveredNotifications.length > 0 && !this.markingDelivered)
        await this.markAllAsDelivered()
    }
  },

  async created() {
    if(this.userId)
      this.initRegisters()
      
    this.model = [...this.unreadNotifications]

    // if (this.undeliveredNotifications.length > 0)
    //   await this.markAllAsDelivered()
  },

  methods: {
    ...registersActions(['observer']),
    initRegisters() {
      this.observer({
        collection: 'notifications', 
        register: 'notifications', 
        filters: [{ key: 'userId', operator: '==', value: this.userId }],
        orderBy: { key: 'lifeControl.createdAt', mod: 'desc' },
        limit: 9999,
        forceFilter: true,
      })
    },
    setModel() {
      if (this.reset) {
        this.model = [...this.unreadNotifications]
        this.reset = false
      }
    },
    async markAsRead(item) {
      if (item.status.read || this.loading.indexOf(item.id) >= 0)
        return

      this.loading.push(item.id)

      return await store.$api.post(`/notifications/markAsRead/${item.id}`)
    },
    async markAllAsRead() {
      if (this.unreadNotifications.length > 0) {
        return await store.$api.post(`/notifications/batch/markAsRead`, this.unreadNotifications.map(({ id }) => ({ id })))
      }
    },
    async markAsDelivered(item) {
      if (!item.status.delivered) {
        this.markingDelivered = true
        const result = await store.$api.post(`/notifications/markAsDelivered/${item.id}`)
        this.markingDelivered = false
        this.notify({notification: item})
        return result
      }
    },
    async markAllAsDelivered() {
      const undeliveredNotifications = this.undeliveredNotifications
      this.markingDelivered = true
      const result = await store.$api.post(`/notifications/batch/markAsDelivered`, undeliveredNotifications.map(({ id }) => ({ id })))
      this.markingDelivered = false
      undeliveredNotifications.map(un => this.notify({notification: un}))
      return result
    },
    notify ({notification}) {
      let { title, message, type } = notification
      type = type === 'system' || type === 'update' ? 'default' : type
      // this.$toast.open({ position: 'top-right', type, message: `<h5>${title}</h5> - ${message}` })
    }
  },
  setup() {
    const buttons = ['Ok'];

    return { notificationsIcon: notifications, ellipse, alertCircle, buttons }
  }
}
</script>

<style>
  .notification--card .ps { max-height: 250px; }

  .center-right{
    position: absolute;
    top: 25%;
    right: 10px;
  }

  .center-left{
    position: absolute;
    top: 25%;
    left: 10px;
  }

  .top-left{
    position: absolute;
    top: 10px;
    left: 5px;
  }

  .top-right{
    position: absolute;
    top: 10px;
    right: 5px;
  }

  .bottom-left{
    position: absolute;
    bottom: 10px;
    left: 5px;
  }

  .bottom-right{
    position: absolute;
    bottom: 10px;
    right: 5px;
  }
</style>