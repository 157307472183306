<template>
  <ion-input :placeholder="placeholder" @input="onInput" >

  </ion-input>
</template>

<script>
  import { IonInput } from '@ionic/vue'
  import { createNamespacedHelpers, mapActions } from 'vuex'
  const { mapActions:registersActions } = createNamespacedHelpers('registers')
  export default {
    name: 'VTextFieldAlgolia',
    components: {
      IonInput
    },
    props: {
      /**
       * Collection do algolia
       */
      index: {
        type: String,
        required: true
      },

      /**
       * Função de tratamento da requisição da busca.
       * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
       */
      handler: {
        type: Function,
        required: false
      },

      /**
       * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
       */
      delay: {
        type: Number,
        required: false,
        default: () => 0
      },

      placeholder: String,

      filters: Array,
      attributesToHighlight: Array,
    },
    data() {
      return {
        timer: null, 
      }
    },
    async created() {
      // emitter.on('click:clear', (query) => {
      //   if (this.timer) {
      //     clearTimeout(this.timer)
      //     this.timer = null
      //   }
      // })
      
      // emitter.on('keydown', (e) => {
      //   if (e.key == 'Enter') {
      //     if (this.timer) {
      //       clearTimeout(this.timer)
      //       this.timer = null
      //     }
      //     const query = this.lazyValue
      //     this.search(query, this.filters)
      //   }
      // })
      
    },
    methods: {
      ...registersActions(['searchAlgolian']),
      onInput(event) {
        const query = event.target.value
        if (this.delay)
          this.timeoutSearch(query, this.filters)
        else
          this.search(query, this.filters)
      },
      timeoutSearch(query, filters) {
        if (query || filters) {
          if (this.timer)
            clearTimeout(this.timer)

          this.timer = setTimeout(() => {
            this.timer = null
            this.handler(this.algoliaSearch(query, filters))
          }, this.delay)
        }
      },
      search(query, filters) {
        if (query || filters.length > 0)
          this.handler(this.algoliaSearch(query, filters))
      },
      algoliaSearch(query, filters) {
        if (query || filters.length > 0)
          return this.searchAlgolian({index: this.index, query, facetFilters: filters, attributesToHighlight: this.attributesToHighlight})
        else return { hits: [] }
      }
    }
  }
</script>