<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Detalhes do chamado</ion-title>
        <ion-back-button slot="start" />
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form @submit.prevent="validate() && submit()" ref="form">
        <ion-card>
          <ion-card-content>
            <ion-list class="ion-no-padding">
              <!-- DEPARTAMENTO -->
              <ion-item class="ion-no-padding">
                <ion-label position="floating"> Departamento </ion-label>
                <template v-if="$route.fullPath.includes('detalhes')">
                  <ion-input
                    readonly
                    v-model="model['departamento']"
                    label="Departamento"
                  ></ion-input>
                </template>
                <template v-else>
                  <ion-select v-model="model['id_departamento']">
                    <template
                      v-for="(department, index) in departments"
                      :key="index"
                    >
                      <ion-select-option :value="department.id">
                        {{ department.nome }}
                      </ion-select-option>
                    </template>
                  </ion-select>
                </template>
              </ion-item>
              <!-- TITULO -->
              <ion-item class="ion-no-padding">
                <ion-label position="floating">Título</ion-label>
                <ion-input
                  v-model="model['titulo']"
                  :readonly="readonly"
                ></ion-input>
              </ion-item>
              <!-- MENSAGEM -->
              <template v-if="$route.fullPath.includes('detalhes')">
                <ion-item class="ion-no-padding">
                  <ion-label position="stacked">Mensagem</ion-label>
                  <ion-textarea readonly v-html="model['mensagem']" auto-grow />
                </ion-item>
              </template>
              <template v-else>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">Mensagem</ion-label>
                  <ion-textarea v-model="model['mensagem']" auto-grow />
                </ion-item>
              </template>
              <!-- ANEXOS -->
              <!-- VISUALIZAÇÃO DE ANEXOS -->
              <template v-if="$route.fullPath.includes('detalhes')">
                <template v-if="model['anexos']">
                  <ion-grid class="ion-no-padding">
                    <ion-row>
                      <ion-label class="ion-padding-vertical"><h2>Anexos</h2></ion-label>
                    </ion-row>
                    <ion-row :style="{ border: 'dashed', borderRadius: '10px' }">
                      <ion-col
                        @click="setOpen(true)"
                        size="4"
                        v-for="(anexo, index) in model['anexos']"
                        :key="index"
                        :style="{ padding: '8px' }"
                      >
                        <div>
                          <ion-img :src="anexo.url" class="anexo" />
                          <ion-label>{{ anexo.nome }}</ion-label>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </template>
              </template>
              <!-- ADIÇÃO DE ANEXOS -->
              <template v-else>
                <ion-toolbar style="--background: transparent">
                  <ion-button fill="outline" size="small" @click="$refs.attachments.click()" class="ion-margin-vertical" >
                    <ion-icon slot="start" :icon="attach"></ion-icon>
                    <ion-label slot="end">Adicionar anexos</ion-label>
                  </ion-button>
                  <ion-buttons slot="end" v-if="model['anexos'].length > 0">
                    <ion-button fill="clear" @click="clearAttachments">
                      <ion-icon slot="icon-only" :icon="close"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                  <input ref="attachments" class="ion-hide" type="file" multiple @change="onFileChange($event)" id="file-input" />
                </ion-toolbar>
                <template v-if="model['anexos'].length > 0">
                  <ion-grid class="ion-no-padding" :style="{ border: 'dashed', borderRadius: '3%' }" >
                    <ion-row>
                      <ion-col size="4" v-for="(anexo, index) in model['anexos']" :key="index" :style="{ padding: '8px' }" >
                        <ion-icon :icon="close" @click="removeAttachment(index)"/>
                        <div @click="setOpen(true)">
                          <ion-img :src="anexo.url" class="anexo" />
                          <ion-label>{{ anexo.nome }}</ion-label>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </template>
              </template>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </form>
      <ion-fab horizontal="end" vertical="bottom" slot="fixed" v-if="!readonly">
        <ion-button fill="solid" shape="round" @click="submit()">
          <ion-icon :icon="exitOutline" />
          <ion-label :style="{ fontSize: '0.9em', marginLeft: '8px' }"
            >Gravar para Envio</ion-label
          >
        </ion-button>
      </ion-fab>
      <ion-modal :is-open="isOpenRef" @didDismiss="setOpen(false)">
        <ion-page>
          <ion-toolbar style="--background: transparent">
            <ion-buttons slot="end">
              <ion-button @click="setOpen(false)">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-content>
            <ion-slides :style="{ height: '100%' }">
              <template v-for="(anexo, index) in model['anexos']" :key="index">
                <ion-slide>
                  <ion-img :src="anexo.url" :style="{ width: '100%' }" />
                </ion-slide>
              </template>
            </ion-slides>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>
<script>
// import Toolbar from '../../components/Templates/Toolbar.vue'
import { arrowBack, exitOutline, attach, close } from "ionicons/icons";
import { ref } from "vue";
import store from "@/store";
import { createNamespacedHelpers, mapMutations } from "vuex";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonInput,
  IonTextarea,
  IonLabel,
  IonItem,
  IonImg,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButton,
  IonIcon,
  IonFab,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonModal,
  IonButtons,
  IonSlides,
  IonSlide,
} from "@ionic/vue";
const { mapState: authState } = createNamespacedHelpers("auth");
const { mapActions: registersActions } = createNamespacedHelpers("registers");

export default {
  name: "TicketsRegister",
  components: {
    // Toolbar,
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonInput,
    IonImg,
    IonTextarea,
    IonLabel,
    IonItem,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButton,
    IonIcon,
    IonFab,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol,
    IonBackButton,
    IonModal,
    IonButtons,
    IonSlides,
    IonSlide,
  },
  props: {
    readonly: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...authState(["user"]),
  },
  data() {
    return {
      model: {
        tipo_identificador: "E",
        prioridade: 3,
        id_departamento: "",
        anexos: [],
      },
      formData: {},
      ticketId: this.$route.params.id,
      selectedDepartment: {},
      departments: [],
    };
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state) => {
      isOpenRef.value = state;
    };
    return {
      arrowBack,
      exitOutline,
      attach,
      close,
      isOpenRef,
      setOpen,
    };
  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...registersActions(["get", "showToast"]),
    removeAttachment(index){

      this.model['anexos'].length === 1 ? this.model['anexos'] = [] : this.model['anexos'].splice(index, 1)

      document.getElementById('file-input').value = ''
    },
    clearAttachments(){
      this.model['anexos'] = []
      document.getElementById('file-input').value = ''

    },
    async onFileChange(e) {
      await new Promise((resolve, reject) => {
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop

        Object.keys(e.target.files).map((index) => {
          let file = e.target.files[index];
          this.formData.append("anexos", file);
          this.model["anexos"].push({
            url: URL.createObjectURL(file),
            nome: file.name,
          });
        });

        resolve();
      });
    },
    cancelRegister() {
      store.$router.back();
    },
    setFormData({ data }) {
      this.model["departamento"] = data["departamento"];
      this.model["titulo"] = data["titulo"];
      this.model["mensagem"] = data["mensagem"];
      this.model["anexos"] = data["anexos"];
    },
    getTicket() {
      this.setLoading({ stats: true });

      let options = { params: { ticket_id: this.ticketId } };
      store.$api
        .get("/tickets/" + this.ticketId, options)
        .then((response) => {
          this.setFormData(response);
          this.setLoading({ stats: false });
        })
        .catch((error) => {
          this.setLoading({ stats: false });
        });
    },
    goBack() {
      this.$router.push(`/tickets/listar`);
    },
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      this.setLoading({ stats: true });

      Object.keys(this.model).map((field, index) => {
        this.formData.append(field, this.model[field]);
      });

      this.formData.append("cliente", this.user.email);
          this.setLoading({ stats: true });

      store.$api
        .post("/tickets", this.formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.setLoading({ stats: false });
          if (response.data.erro)
            this.showToast({ message: response.data.mensagem, color: "error" });
          // this.$toast.error(response.data.mensagem, {
          //   position: "top-right",
          // });
          else
            this.showToast({
              message: response.data.mensagem,
              color: "success",
            });
          // this.$toast.success(response.data.mensagem, {
          //   position: "top-right",
          // });
          store.$router.back();
        })
        .catch((error) => {
          this.setLoading({ stats: false });
          this.$toast.error(error.data.mensagem, {
            position: "top-right",
          });
        });
    },
    async getDepartments() {
      this.departments = (
        await this.get({ collection: "ticketDepartments" })
      ).filter((d) => d.selected === true);
    },
  },
  created() {
    
    
    if (this.ticketId) {
      this.getTicket();
    } else {
      this.getDepartments()
      this.formData = new FormData();
    }
  },
};
</script>

<style>
.card-attachments {
  margin: 0;
  margin-top: 0.5em;
}
.col-img {
  height: auto;
  margin: 0.5em;
}
.anexo {
  border-radius: 5% !important;
  overflow: hidden;
}
</style>