<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ setTitle }}</ion-title>
        <ion-back-button slot="start"/>
        <!-- <ion-buttons slot="start">
          <ion-button @click="cancelRegister()">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons> -->
      </ion-toolbar>
      <ion-toolbar v-if="collection === 'SC5' && collectionDetails && tableIdFromValue">
        <ion-segment :value="segment" @ionChange="changeSegment($event)">
          <ion-segment-button value="data">
            <ion-label>Dados Cadastrais</ion-label>
          </ion-segment-button>
          <ion-segment-button value="items" v-if="typeDetails !== 'none'">
            <ion-label>Itens</ion-label>
          </ion-segment-button>
          <ion-segment-button value="taxes" v-if="typeDetails !== 'none' && !offline">
            <ion-label>Impostos</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <keep-alive>
      <ion-content name="data" v-if="segment === 'data'">
        <ion-card v-if="item.errorErp" class="card-errors" color="danger">
          <ion-card-header>
            <ion-button :color="'danger'" 
              @click="showErrors = !showErrors"
              expand="full">
              <ion-icon :icon="alertCircleOutline"></ion-icon> | Ver Erros do Erp
            </ion-button>
          </ion-card-header>
          <ion-card-content v-if="showErrors">
            <h1>Erros do ERP</h1>
            <p>{{item.errorErp}}</p>
          </ion-card-content>
        </ion-card>
        <Form
          :inputs="computedInputsMaster"
          :collection="collection"
          :model="item"
          :color="color"
          :collectionDetails="collectionDetails"
          :readonly="readonlyComputed"
          @changeForm="changeForm"
          @submit="submit()"
          ref="formMaster"
        />

        <div :style="{ height: '60px' }" />
      </ion-content>
      <ion-content name="items" v-else-if="segment === 'items'">
        <FormDataTable
          :headers="computedHeaders"
          :items="inputsDetails"
          :filters="computedFilters"
          :xlsxName="xlsxName"
          :color="color"
          :readonly="readonlyComputed"
          :filterItems="filterItemsFunction"
          ref="formDetails"
          @validated="validatedDetails = $event"
        />

        <div :style="{ height: '60px' }" />
      </ion-content>
      <ion-content name="taxes" v-else-if="segment === 'taxes'">
        <!-- IMPOSTOS -->
        <ion-card class="card-taxes">
          <ion-card-header v-if="!item.bloqueado">
            <ion-button
              size="small" 
              expand="full"
              fill="outline"
              @click="calcImps()"
              :disabled="impostos.loading"
            >
              {{impostos.loading ? impostosMessage : 'Calcular Impostos'}}
            </ion-button>
            <ion-progress-bar type="indeterminate" v-if="impostos.loading"></ion-progress-bar>
          </ion-card-header>
          <ion-card-content v-if="impostos && impostos.headers && impostos.headers.length > 0">
            <ion-list>
              <ion-item
                v-for="(header, index) in impostos.headers"
                :key="index"
              >
                <ion-label position="floating">{{ header.text }}</ion-label>
                <ion-input
                  :value="
                    'R$ ' +
                    impostos.itens[0][header.value].toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  "
                  disabled
                />
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-content>
    </keep-alive>
    <ion-fab
      horizontal="end"
      vertical="bottom"
      slot="fixed"
    >
      <!-- v-if="!readonlyComputed && computedClaims.add" -->
      <ion-fab-button>
        <ion-icon :icon="ellipsisVertical" />
      </ion-fab-button>
      <ion-fab-list side="top">
        <div :style="{ position: 'absolute', right: '6px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}">
          <ion-button
            v-if="!readonlyComputed && computedClaims.sync"
            :disabled="!validatedDetails"
            expand="block"
            fill="solid"
            shape="round"
            color="success"
            @click="submit()">

            <ion-icon :icon="saveOutline" />
            <ion-label :style="{ fontSize: '0.9em', marginLeft: '8px' }">Gravar {{offline ? 'Offline' : 'Rascunho'}}</ion-label>
          </ion-button>
          <ion-button fill="solid" shape="round" 
            :disabled="!validatedDetails" 
            @click="submit(true)" 
            v-if="!readonlyComputed && computedClaims.sync && !offline">
            <ion-icon :icon="exitOutline"/>
            <ion-label :style="{fontSize: '0.9em', marginLeft: '8px'}">Gravar para Envio</ion-label>
          </ion-button>
          <ion-button
            v-if="collection.includes('SC5') && item.C5_NUM && (!item.C5_YPEDORI || item.C5_YPEDORI === '') && computedClaims.add"
            expand="block"
            fill="solid"
            shape="round"
            color="primary"
            @click="goBonus()">

            <ion-icon :icon="gift" />
            <ion-label :style="{ fontSize: '0.9em', marginLeft: '8px' }">Bonificar PV</ion-label>
          </ion-button>
          <template v-for="(cAction, index) of computedCustomActions" :key="index">
            <ion-button  
              fill="solid" 
              shape="round"
              v-show="showAction({item, customAction: cAction})" 
              @click="clickAction({item, customAction: cAction})" 
              :style="customActionStyle(cAction.color)" >
              <!-- <ion-icon :icon/> -->
              <ion-label :style="{fontSize: '0.9em', marginLeft: '8px'}">{{cAction.name}}</ion-label>
            </ion-button>
          </template >
        </div>
      </ion-fab-list>
    </ion-fab>
  </ion-page>
</template>

<script>
import store from "../../store";
import FormDataTable from "@/components/FormDataTable.vue";
import {
  createNamespacedHelpers,
  mapActions,
  mapMutations,
  mapState,
} from "vuex";
import {
  arrowBack,
  ellipsisVertical,
  checkmark,
  search,
  saveOutline,
  gift,
  exitOutline,
  add,
  alertCircleOutline
} from "ionicons/icons";
import {
  IonFab,
  IonContent,
  IonPage,
  IonFabList,
  IonToolbar,
  IonProgressBar,
  // IonButtons,
  IonButton,
  IonHeader,
  IonTitle,
  IonIcon,
  IonLabel,
  IonFabButton,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonList,
  IonInput,
  IonBackButton
} from "@ionic/vue";
const {
  mapState: registersState,
  mapActions: registersActions,
  mapMutations: registersMutations,
} = createNamespacedHelpers("registers");
const { mapState: authState } = createNamespacedHelpers("auth");
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as fs from "file-saver";
import Form from "@/components/Registers/Form";
import axios from "axios";
export default {
  name: "Register",
  setup() {
    return {
      arrowBack,
      ellipsisVertical,
      checkmark,
      search,
      saveOutline,
      gift,
      exitOutline,
      add,
      alertCircleOutline
    };
  },
  components: {
    Form,
    FormDataTable,
    IonFab,
    IonContent,
    IonPage,
    IonFabList,
    IonToolbar,
    // IonButtons,
    IonButton,
    IonHeader,
    IonTitle,
    IonIcon,
    IonLabel,
    IonFabButton,
    IonSegment,
    IonSegmentButton,
    IonCard,
    IonProgressBar,
    IonCardContent,
    IonCardHeader,
    IonItem,
    IonList,
    IonInput,
    IonBackButton
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    collection: {
      type: String,
      required: true,
    },
    register: {
      type: String,
      required: true,
    },
    inputsMaster: {
      type: Array,
      default: () => [],
    },
    blockedField: String,
    color: {
      required: false,
      type: String,
      default: "indigo",
    },
    readonly: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    edit: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    integration: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    dispatch: {
      type: Array,
      default: () => [],
    },
    collectionDetails: {
      required: false,
      type: String,
    },
    headersDetails: {
      type: Array,
      default: () => [],
    },
    tableCollection: {
      type: String,
      default: "",
    },
    tableSubcollection: {
      type: String,
      default: "",
    },
    orderSubcollection: {
      required: false,
    },
    tableIdFrom: {
      type: String,
      default: "",
    },
    filtersDetails: {
      type: Array,
      default: () => [],
    },
    filterItems: String,
    xlsxName: String,
    typeDetails: String,
    impostosPdfText: String,
    totaisPdfText: String,
    colorPdfTable: String,
    claims: {
      type: Object,
      required: true,
      default: () => ({
        add: false,
        view: false,
        edit: false,
        delete: false,
      }),
    },
    exportPdf: {
      type: Boolean,
      required: false,
      default: true,
    },
    customActions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      segment: "data",
      headerTitle: "",
      ready: false,
      showErrors: false,
      item: {},
      itemsDetails: [],
      tableItems: [],
      autoSave: {
        active: true,
        firstSaveTime: 5 * 1000,
        saveTime: 60 * 1000,
        timer: null,
      },
      impostos: {
        headers: [],
        itens: [],
        noDataDefault: "Não há dados disponíveis",
        noData: "Não há dados disponíveis",
        loading: false,
      },
      jsPdf: {
        doc: null,
        dialog: false,
        sendEmail: {
          sending: false,
          dialog: false,
          inputs: [
            { type: "text-field", value: "to", label: "Email de Destino" },
            { type: "text-field", value: "cc", label: "Cc", md: 6 },
            { type: "text-field", value: "bcc", label: "Cco", md: 6 },
            { type: "text-field", value: "subject", label: "Assunto" },
            {
              type: "textarea",
              value: "text",
              label: "Corpo do Email",
              rows: 4,
            },
          ],
          model: null,
        },
        blob: null,
      },
      validatedDetails: false,
      tableIdFromValue: null,
      /**
       * @TODO
       * Código provisório, remover assim que possível
       * Guarda o valor do atributo 'A1_XPTDESC' do cliente selecionado em 'SA1' no form cabelalho
       */
      A1_XPTDESC: null,
    };
  },

  computed: {
    ...registersState(["registers"]),
    ...mapState(["companies", "offline"]),
    ...authState(["user"]),

    setTitle() {
      return store.$router.currentRoute.value.name;
    },

    readonlyComputed() {
      const readOnly =
        this.item && this.blockedField
          ? this.item[this.blockedField] || this.readonly
          : this.readonly;
      return readOnly;
    },

    computedInputsMaster() {
      return this.inputsMaster.map((i) => {
        const input = { ...i };

        if (input.set) input.set = eval(input.set);

        if (input.computed) input.computed = eval(input.computed);

        if (typeof input.itemText == "string" && input.itemText.includes("=>"))
          input.itemText = eval(input.itemText);

        if (typeof input.items == "string" && input.items.includes("=>"))
          input.items = eval(input.items);

        if (input.type === "select") {
          if (
            input.compareWith &&
            typeof input.compareWith == "string" &&
            (input.compareWith.includes("=>") ||
              input.compareWith.includes("function"))
          ) {
            input.compareWith = eval(input.compareWith);
          } else {
            input.compareWith = (o1, o2) => {
              return o1.value === o2.value;
            };
          }
        }

        if (input.model) input.model = eval(input.model);

        if (input.rules) {
          input.rules.forEach((el, ix) => {
            input.rules[ix] = eval(el);
          });
        }

        if (input.markers) {
          input.markers = Object.entries(input.markers).reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim().toLocaleLowerCase()]: val,
            }),
            {}
          );

          input.markers = {...input.markers, offline: 'offline' }
        }

        // envia todos os campos caso esteja cadastrando
        if (!this.id) input.send = true;

        if (typeof input.hide == "string" && input.hide.includes("=>"))
          input.hide = eval(input.hide);

        return input;
      });
    },

    inputsDetails() {
      if (this.readonlyComputed) return this.itemsDetails;

      return this.itemsDetails.length > 0
        ? this.tableItems.map(
            (item) =>
              this.itemsDetails.find((_item) =>
                this.headersDetails
                  .filter((h) => h.identifier)
                  .every((h) => item[h.value] == _item[h.value])
              ) || item
          )
        : this.tableItems;
    },

    id() {
      return this.$route.params.id || this.item.id;
    },

    claimRoute() {
      return this.$route.meta.claimRoute;
    },

    computedHeaders() {
      return this.headersDetails.map((h) => {
        const header = { ...h };

        if (header.computed) header.computed = eval(header.computed);

        if (header.validation && Array.isArray(header.validation))
          header.validation = header.validation.map((strFn) => eval(strFn));

        // header.mask = (value) => {
        //     const prefix = header.prefix
        //       ? header.prefix + ' '
        //       : ''

        //     const maskedValue = header.type == 'float' || header.type == 'integer'
        //       ? value.toLocaleString('pt-br', { minimumFractionDigits: header.precisionText, maximumFractionDigits: header.precisionText })
        //       : value

        //     const suffix = header.suffix
        //       ? ' ' + header.suffix
        //       : ''

        //     return prefix + maskedValue + suffix
        //   }

        if (header.set) header.set = eval(header.set);

        // const set = ({value, item}) => {
        //   if (header.set) {
        //     return header.set({ value, item })
        //   } else {
        //     return value
        //   }
        // }

        // header.set = set

        // /**
        //  * @TODO
        //  * Código provisório, remover assim que possível
        //  * Altera os valores default e a função SET para mudar os limites da coluna de desconto
        //  */
        // if (this.A1_XPTDESC && header.value == "C6_XDESCON") {
        //   header.default = this.A1_XPTDESC;
        //   header.set = ({ value }) =>
        //     Math.min(this.A1_XPTDESC, Math.max(0, value));
        // }

        return header;
      });
    },

    computedDispatch() {
      return this.dispatch;
    },

    computedFilters() {
      return this.filtersDetails.map((filter) => {
        if (filter.filter) filter.filter = eval(filter.filter);
      });
    },

    computedCustomActions() {
      return this.customActions.map((action) =>
        action.data ? action.data : action
      );
    },

    filterItemsFunction() {
      const _default = (_) => true;
      try {
        const val = eval(this.filterItems);
        if (!val || !val.apply) return _default;

        return val;
      } catch (err) {
        return _default;
      }
    },

    method() {
      return this.edit ? "edit" : "new";
    },

    jsPdfOutput() {
      if (this.jsPdf.doc) return this.jsPdf.doc.output("datauristring");
      else if (this.jsPdf.blob) {
        // const base64 = await this.blobTo64({blob: this.jsPdf.blob})
        return URL.createObjectURL(this.jsPdf.blob);
      } else if (this.jsPdf.url) return this.jsPdf.url;

      return null;
    },

    company() {
      return this.companies.find(
        (c) => c.id == `${this.item.gpEmpresa}${this.item.filial}`
      );
    },

    computedClaims() {
      let claims = this.claims;

      claims.view =
        (claims.view &&
          this.user &&
          this.user.claims &&
          this.user.claims.admin) ||
        (this.user &&
          this.user.rules.some(
            (x) =>
              x.claims &&
              x.claims[this.register] &&
              x.claims[this.register].view
          ));
      claims.add =
        (claims.add &&
          this.user &&
          this.user.claims &&
          this.user.claims.admin) ||
        (this.user &&
          this.user.rules.some(
            (x) =>
              x.claims && x.claims[this.register] && x.claims[this.register].add
          ));
      claims.sync =
        (claims.sync &&
          this.user &&
          this.user.claims &&
          this.user.claims.admin) ||
        (this.user &&
          this.user.rules.some(
            (x) =>
              x.claims &&
              x.claims[this.register] &&
              x.claims[this.register].sync
          ));
      claims.edit =
        (claims.edit &&
          this.user &&
          this.user.claims &&
          this.user.claims.admin) ||
        (this.user &&
          this.user.rules.some(
            (x) =>
              x.claims &&
              x.claims[this.register] &&
              x.claims[this.register].edit
          ));
      claims.delete =
        (claims.delete &&
          this.user &&
          this.user.claims &&
          this.user.claims.admin) ||
        (this.user &&
          this.user.rules.some(
            (x) =>
              x.claims &&
              x.claims[this.register] &&
              x.claims[this.register].delete
          ));

      return claims;
    },

    computedExportPdf() {
      return this.readonlyComputed && this.exportPdf;
    },
  },

  methods: {
    ...mapActions(["dispatchAction"]),
    ...registersActions([
      "save",
      "saveOffline",
      "getById",
      "get",
      "getApi",
      "getGpEmpresa",
      "observer",
      "calculaImpostos",
      "showToast"
    ]),
    ...mapMutations(["setLoading"]),
    ...registersMutations(["resetRegister"]),
    changeForm({ formData }) {
      if (this.tableIdFromValue !== formData[this.tableIdFrom]) {

        // console.info('this.tableIdFromValue', this.tableIdFromValue);
        // console.info('formData[this.tableIdFrom]', formData[this.tableIdFrom]);

        this.tableIdFromValue = formData[this.tableIdFrom]

        if (formData[this.tableIdFrom]) {
          this.getTableItems({collection: this.tableCollection, subCollection: this.tableSubcollection, orderSubcollection: this.orderSubcollection, id: this.tableIdFromValue})
        } else {
          this.tableItems = []
        }
      }
    },

    customActionStyle(color){
      return 'background-color: ' + color;
    },

    getMaskedValue(header, value) {
      const prefix = header.prefix ? header.prefix + " " : "";

      const maskedValue =
        header.type == "float" || header.type == "integer"
          ? value.toLocaleString("pt-br", {
              minimumFractionDigits: header.precisionText,
              maximumFractionDigits: header.precisionText,
            })
          : value;

      const suffix = header.suffix ? " " + header.suffix : "";

      return prefix + maskedValue + suffix;
    },
    changeSegment(event) {
      this.segment = event.target.value;
    },
    cancelRegister() {
      store.$router.back();
    },
    showAction({ item, customAction }) {
      const showAction = eval(customAction.hide);
      return typeof showAction === "function"
        ? !showAction({ item })
        : !showAction;
    },

    clickAction({ item, customAction }) {
      const self = {
        item,
        ...this
      }
      const actionRunner = eval(customAction.actionRunner) //eval('async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: "Obtendo Danfe..."}); try { const {base64} = await getApi({url: "/protheus/", options: {params: {path: "Danfe64/" + item.C5_FILIAL + "/" + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };')
      actionRunner(self)
    },

    async submit(submitToErp = false) {
      if (this.readonlyComputed) {
        this.goBack()
        return
      }

      if (this.validate()) {
        try {
          const response = await this.send(submitToErp)
          this.goBack()
        } catch (err) {
          this.setLoading({ stats: false});
        this.showToast({ message: 'Verifique os campos e tente novamente', color: "error" })
        }
      }
    },

    validate() {
      return (
        this.$refs.formMaster.validate() &&
        (this.typeDetails != "data-table" || this.$refs.formDetails.validate())
      );
    },

    async calcImps(impostos) {
      this.setLoading({ stats: true, message: 'Calculando. Aguarde...' });
      if (!impostos) {
        this.impostosMessage = "Calculando...";
        this.impostos.itens = [];
        this.impostos.noData = this.impostos.noDataDefault;
        const item = this.$refs.formMaster.getModel();
        this.itemsDetails = this.$refs.formDetails.getModel()
        const itemsDetails = this.itemsDetails
          .filter(this.filterItemsFunction)
          .map((item) => ({ data: item }));
        if (!itemsDetails || !itemsDetails.length > 0)
          return (this.impostos.noData =
            "Não foi preenchido nenhum produto no pedido");
        this.impostos.loading = true;
        impostos = await this.calculaImpostos({
          item,
          itemsDetails,
          collectionDetails: this.collectionDetails,
        });
        if (impostos.response && impostos.response.status == 500)
          impostos = {
            Erros:
              (impostos.response &&
                impostos.response.data &&
                impostos.response.data.error) ||
              "Requisição cancelada, servidor Protheus offline.",
          };

        if (impostos.code == "ECONNABORTED")
          impostos = { Erros: "Requisição cancelada, tempo excedido." };
      }
      this.impostos.itens.push(impostos);
      this.impostos.headers = Object.entries(impostos)
        .sort((a, b) => (a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0))
        .map((x) => ({ align: "center", text: x[0], value: x[0] }));
      this.impostos.noData = this.impostos.noDataDefault;
      this.impostos.loading = false;
      this.setLoading({ stats: false  });
    },

    async send(submitToErp) {

      this.setLoading({ stats: true, message: 'Salvando. Aguarde...' });

      const item = this.$refs.formMaster.getModel();
      if (
        this.impostos &&
        this.impostos.itens &&
        this.impostos.itens.length > 0
      )
        item.impostos = this.impostos.itens[0];

      let itemsDetails;
      if (this.typeDetails == "data-table")
        itemsDetails = this.$refs.formDetails
          .getModel()
          .filter(this.filterItemsFunction)
          .map((item) => {
            delete item.show
            delete item.invalidItem
            delete item.invalidField
            return { data: item }
          });

      if (
        this.collectionDetails &&
        (!itemsDetails || !itemsDetails.length > 0)
      ) {
        this.$toast.error("Itens não informados.", { position: "top-right" });
        throw "Itens não informados.";
      }

      if (!(this.claimRoute === "add" && this.id)) {
        const id = this.id;
        if (!item.id && id) item.id = id;
      }

      // Dispatch Before
      if (this.computedDispatch.length > 0) {
        this.computedDispatch
          .filter((x) => x.when == "before" && x.method == this.method)
          .forEach((el) => {
            if (!el.switch || item[el.switch]) {
              this.dispatchAction({
                action: el.action,
                params: this.proccessParams({ params: el.params, item }),
              });
            }
          });
      }

      let response

      if (this.offline) {
        response = await this.saveOffline({
          integration: this.integration,
          collection: this.collection,
          collectionDetails: this.collectionDetails,
          item,
          itemsDetails,
          register: this.register
        })
      } else {
        response = await this.save({
          integration: this.integration,
          collection: this.collection,
          collectionDetails: this.collectionDetails,
          item,
          itemsDetails,
          submitToErp,
        })
  
        // Dispatch After
        if (this.computedDispatch.length > 0) {
          this.computedDispatch
            .filter((x) => x.when == "after" && x.method == this.method)
            .forEach((el) => {
              if (!el.switch || item[el.switch]) {
                this.dispatchAction({
                  action: el.action,
                  params: this.proccessParams({ params: el.params, item }),
                });
              }
            });
        }
      }


      this.setLoading({ stats: false });

      return response;
    },

    proccessParams({ item, params }) {
      const ret = {};
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const el = params[key];
          const pathItem = el.split(".");
          const lenItem = pathItem.length;
          if (lenItem == 1) {
            ret[key] = pathItem[0];
          } else {
            for (let ix = 1; ix < pathItem.length; ix++) {
              const el2 = pathItem[ix];
              if (typeof item[el2] != "undefined") {
                ret[key] = item[el2];
              } else {
                ret[key] = "";
                break;
              }
            }
          }
        }
      }
      return ret;
    },

    goBack() {
      this.$router.push(`/tabs/${this.register}`);
    },

    goBonus() {
      this.$router.push(`/tabs/${this.register}/bonificar/${this.item.id}`);
    },

    handleItem(item) {
      return item;
    },

    async fetchDetails() {
      if (
        !this.collection ||
        !this.$route.params.id ||
        !this.collectionDetails
      ) {
        this.ready = true;
        return;
      }

      const collection =
        this.collection + (await this.getGpEmpresa(this.collection));
      const collectionDetails =
        this.collectionDetails +
        (await this.getGpEmpresa(this.collectionDetails));
      const ref = store
        .$db()
        .collection(collection)
        .doc(this.$route.params.id)
        .collection(collectionDetails);
      const res = await this.get({ collection, ref, limit: 200 });

      if (res) this.itemsDetails = res;
      this.ready = true;
      return res;
    },

    startAutoSave() {
      this.autoSave.timer = setTimeout(
        this.tryAutoSave,
        this.autoSave.firstSaveTime
      );
    },

    async tryAutoSave() {
      this.autoSave.timer = null;
      try {
        const response = await this.send();
        this.$set(this.item, "id", response.data.data.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.autoSave.timer = setTimeout(
          this.tryAutoSave,
          this.autoSave.saveTime
        );
      }
    },

    /**
     * Transforma { a: { b: 1 } } em {'a.b': 1}
     * Ps: Funciona apenas para um nível de objeto
     * @TODO Generalizar para mais níveis
     * @TODO Funcionar com arrays.
     */
    normalizeItem(item) {
      Object.entries(item).forEach(([key, val]) => {
        if (typeof val == "object" && val) {
          Object.entries(val).forEach(([k, v]) => {
            if (typeof v == "object" && v) {
              Object.entries(v).forEach(([i, j]) => {
                const newKey = key + "." + k + "." + i;
                item[newKey] = j;
              });
            }
            const newKey = key + "." + k;
            item[newKey] = v;
          });
        }
      });
    },

    async getTableItems({ collection, id, subCollection, orderSubcollection }) {
      subCollection = subCollection + (await this.getGpEmpresa(subCollection));
      const response = await this.getById({
        collection,
        id,
        subCollection,
        orderSubcollection,
        setRegister: false,
      });
      const subCollectionItems = response[subCollection];
      delete response[subCollection];

      if (!response || !subCollectionItems || !subCollectionItems.length > 0)
        return (this.tableItems = []);

      this.tableItems = subCollectionItems.map((item) => {
        let filteredItem = {};
        this.headersDetails.forEach((header) => {
          filteredItem[header.value] =
            header.fill && item[header.fill]
              ? item[header.fill]
              : item[header.value];

          if (!filteredItem[header.value])
            filteredItem[header.value] = header.default; //this.$refs.formDetails.save(item, header, header.default)
        });
        return filteredItem;
      });
    },

    /**
     * Gera o pdf para ser exibido na tela e posteriormente baixado enviado por email
     */
    async blobTo64({ blob }) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async generatePdf({ base64str, objectStorage, url }) {
      this.setLoading({ stats: true });

      if (url) this.jsPdf.url = url;
      else if (objectStorage) {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "blob";

        xhr.onload = async (event) => {
          this.jsPdf.blob = xhr.response;
          // this.jsPdf.doc = await this.blobTo64({blob: xhr.response})
          this.jsPdf.dialog = true;
          this.setLoading({ stats: false });
        };
        xhr.open("GET", objectStorage.documentUrl);
        xhr.send();
      } else if (base64str) {
        // decode base64 string, remove space for IE compatibility
        let binary = atob(base64str.replace(/\s/g, ""));
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        const doc = new jsPDF();
        let blob = new Blob([view], { type: "application/pdf" });
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.click()

        const blobUrl = URL.createObjectURL(blob)
        window.open(blobUrl)

        // this.jsPdf.blob = blob
        // this.jsPdf.dialog = true
        this.jsPdf.blob = blob;
        this.jsPdf.dialog = true;
        this.setLoading({ stats: false });
      } else {
        try {
          const doc = new jsPDF();
          const fontSize = 7;
          let lineHeight = fontSize;
          const fontFamily = "helvetica";
          const tableLineWidth = 0.2;
          const tableLineColor = [125, 125, 125];
          const hexToNumber = (str) => eval("0x" + str);
          const fillColor = (this.colorPdfTable && [
            hexToNumber(this.colorPdfTable.slice(1, 3)),
            hexToNumber(this.colorPdfTable.slice(3, 5)),
            hexToNumber(this.colorPdfTable.slice(5, 7)),
          ]) || [41, 128, 186]; // azul padrão de header

          // altere o valor para determinar qual linha deve começar o documento
          let line = 2;

          const size = {
            x: 210,
            y: 297,
          };

          const item = this.$refs.formMaster.getModel();

          /**
           * Logo
           * addImage ref: https://artskydj.github.io/jsPDF/docs/module-addImage.html
           */
          const image = await this.loadImage();
          doc.addImage(image, "JPEG", 5, lineHeight, 16, 9);

          /**
           * Título
           */
          doc.setFont(fontFamily);
          doc.setFontSize(2.5 * fontSize);
          /** @TODO parametrizar o título */
          doc.text(`Pedido ${item.id}`, size.x / 2, line * lineHeight, {
            align: "center",
          });
          doc.setFontSize(fontSize);
          line++;

          /**
           * Empresa
           */
          const colsEmpresa = 3;
          const company = this.company;
          const empresaItems = [
            { label: "Empresa", value: company.M0_NOMECOM.trim() },
            { label: "CNPJ", value: company.M0_CGC.trim() },
            { label: "Inscrição Estadual", value: company.M0_INSC.trim() },
            { label: "Site", value: "www.yama.com.br" },
            { label: "E-Mail", value: "" },
            { label: "Telefone", value: company.M0_TEL.trim() },
            { label: "Endereço", value: company.M0_ENDCOB.trim() },
            { label: "Bairro", value: company.M0_BAIRCOB.trim() },
            { label: "CEP", value: company.M0_CEPCOB.trim() },
            { label: "Cidade", value: company.M0_CIDCOB.trim() },
            { label: "UF", value: company.M0_ESTCOB.trim() },
          ];
          const empresaBody = [];
          for (let row = 0; row < empresaItems.length / colsEmpresa; row++) {
            const rowInputs = empresaItems.slice(
              row * colsEmpresa,
              row * colsEmpresa + colsEmpresa
            );
            empresaBody.push(
              rowInputs.reduce((acc, item) => {
                const label = {
                  content: item.label,
                  styles: {
                    fillColor,
                    textColor: [255, 255, 255],
                    fontStyle: "bold",
                    lineWidth: 0.1,
                    lineColor: [171, 206, 228],
                  },
                };
                const value = {
                  content: item.value,
                };

                return [...acc, label, value];
              }, [])
            );
          }
          doc.autoTable({
            startY: line * lineHeight,
            body: empresaBody,
            margin: { top: 25, right: 5, bottom: 10, left: 5 },
            styles: { halign: "center", fontSize, cellPadding: 1 },
            headStyles: { halign: "center", fontStyle: "bold" },
            tableLineWidth,
            tableLineColor,
          });

          /**
           * Cabeçalho
           */
          // Define o número de colunas por tabela
          const cols = 3;
          const inputs = this.computedInputsMaster.filter(
            (i) => i.value && i.exportPdf !== false && !i.returnObject
          );
          const body = [];
          for (let row = 0; row < inputs.length / cols; row++) {
            const rowInputs = inputs.slice(row * cols, row * cols + cols);
            body.push(
              rowInputs.reduce((acc, input) => {
                let text = item[input.value] || "";
                if (input.type == "select") {
                  const selectItem = input.items.find(
                    (i) => i.value == item[input.value]
                  );
                  if (selectItem) text = selectItem.text || item[input.value];
                }

                /**
                 * Configuração do Label
                 * ref: https://github.com/simonbengtsson/jsPDF-AutoTable#options
                 */
                const label = {
                  content: input.label,
                  styles: {
                    fillColor,
                    textColor: [255, 255, 255],
                    fontStyle: "bold",
                    lineWidth: 0.1,
                    lineColor: [171, 206, 228],
                  },
                };

                /**
                 * Configuração do texto do valor do item
                 */
                const value = {
                  content: text.trim(),
                };

                return [...acc, label, value];
              }, [])
            );
          }
          doc.autoTable({
            startY: doc.lastAutoTable.finalY + lineHeight,
            body,
            margin: { top: 25, right: 5, bottom: 10, left: 5 },
            styles: {
              fontSize,
              cellPadding: 1,
            },
            tableLineWidth,
            tableLineColor,
          });

          /**
           * Impostos
           */
          if (
            this.impostos &&
            this.impostos.headers &&
            this.impostos.headers.length > 0
          ) {
            const head = [
              [
                {
                  content: "Impostos",
                  colSpan: this.impostos.headers.length,
                  styles: { halign: "center" },
                },
              ],
              this.impostos.headers.map((el) => ({ content: el.text })),
            ];
            const impostosBody = [
              this.impostos.headers.map((el) => {
                const value = this.impostos.itens[0][el.value];
                return `R$ ${value.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`;
              }),
            ];
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: impostosBody,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { halign: "center", fontSize, fillColor, cellPadding: 1 },
              headStyles: { halign: "center", fontStyle: "bold" },
              tableLineWidth,
              tableLineColor,
            });

            if (this.impostosPdfText)
              doc.text(
                this.impostosPdfText,
                5,
                doc.lastAutoTable.finalY + lineHeight / 2
              );
          }

          /**
           * Totais
           */
          this.docPdfTotais(doc, {
            lineHeight,
            fontSize,
            fillColor,
            tableLineWidth,
            tableLineColor,
          });

          /**
           * Tabela de itens
           */
          const headersItens = this.computedHeaders.filter((header) => {
            if ("exportPdf" in header)
              return header.exportPdf === true || header.exportPdf == "itens";
            else return true;
          });
          const head = [headersItens.map((h) => h.text)];
          const headKeys = headersItens.map((h) => h.value);

          const table = this.$refs.formDetails.getTable();
          const bodyTableItems = [];
          for (const row of table.rows) {
            const item = row.item;
            if (!this.filterItemsFunction(item)) continue;

            bodyTableItems.push(
              headKeys.map((header) => row.getCellFromHeader(header).text)
            );
          }

          // ref: https://github.com/simonbengtsson/jsPDF-AutoTable
          doc.autoTable({
            startY: doc.lastAutoTable.finalY + lineHeight,
            head,
            body: bodyTableItems,
            margin: { top: 25, right: 5, bottom: 10, left: 5 },
            styles: { fontSize, cellPadding: 1 },
            headStyles: { fontStyle: "bold", fillColor },
            tableLineWidth,
            tableLineColor,
          });

          /**
           * Totais (fim da tabela)
           */
          this.docPdfTotais(doc, {
            lineHeight,
            fontSize,
            fillColor,
            tableLineWidth,
            tableLineColor,
          });

          this.jsPdf.doc = doc;
          this.jsPdf.dialog = true;
        } catch (e) {
          console.error(e);
        } finally {
          this.setLoading({ stats: false });
        }
      }
    },

    /**
     * Carrega a imagem da logo da yama para por no PDF
     */
    async loadImage() {
      const url = require("@/assets/yama.jpg");
      const response = await axios.get(url, { responseType: "blob" });
      const blob = response.data;
      return new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = reject;
        fr.readAsDataURL(blob);
      });
    },

    docPdfTotais(
      doc,
      { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor }
    ) {
      const headers = this.computedHeaders.filter((header) => {
        if ("exportPdf" in header)
          return header.exportPdf === true || header.exportPdf == "totais";
        else return true;
      });
      const totalize = this.$refs.formDetails
        .getTotalize()
        .filter((el) => !!headers.find((h) => h.value == el.value));
      if (totalize) {
        const head = [
          [
            {
              content: "Totais",
              colSpan: totalize.length,
              styles: { halign: "center" },
            },
          ],
          totalize.map((el) => el.label),
        ];
        const body = [totalize.map((el) => el.text)];
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + lineHeight,
          head,
          body,
          margin: { top: 25, right: 5, bottom: 10, left: 5 },
          styles: { halign: "center", fontSize, fillColor, cellPadding: 1 },
          headStyles: { halign: "center", fontStyle: "bold" },
          tableLineWidth,
          tableLineColor,
        });

        if (this.totaisPdfText)
          doc.text(
            this.totaisPdfText,
            5,
            doc.lastAutoTable.finalY + lineHeight / 2
          );
      }
    },

    /** @TODO parametrizar o nome do documento */
    savePdf() {
      if (this.jsPdf.doc) this.jsPdf.doc.save("pedido.pdf");
      else if (this.jsPdf.blob) {
        fs.saveAs(this.jsPdf.blob, "Arquivo -" + new Date().valueOf() + ".pdf");
      }

      console.warn("jsPdf.doc not yet defined.");
    },

    async sendPdf({ to, text, bcc, cc, subject }) {
      let response;
      try {
        this.jsPdf.sendEmail.sending = true;
        if (this.jsPdf.doc) {
          response = await this.$api.post(`/mail`, {
            to,
            text,
            id: this.id,
            bcc,
            cc,
            subject,
            attachment: this.jsPdfOutput,
          });

          this.jsPdf.sendEmail.dialog = false;
          this.$toast.success("Email enviado!", { position: "top-right" });
        } else if (this.jsPdf.blob) {
          let reader = new FileReader();
          reader.readAsDataURL(this.jsPdf.blob);
          reader.onloadend = async () => {
            let base64data = reader.result;
            response = await this.$api.post(`/mail`, {
              to,
              text,
              id: this.id,
              bcc,
              cc,
              subject,
              attachment: base64data,
            });

            this.jsPdf.sendEmail.dialog = false;
            this.$toast.success("Email enviado!", { position: "top-right" });
          };
        }
      } catch (err) {
        response = err;
      } finally {
        this.jsPdf.sendEmail.sending = false;
      }

      return response;
    },

    jsPdfHandleDialog(value) {
      if (value) {
        const item = this.$refs.formMaster.getModel();

        const integration = this.$integrations.find(
          (i) => i.collection === this.collection
        );
        const {
          toDefault,
          subjectDefault,
          textDefault,
          enableCcDefault: enableCc,
          ccDefault,
          enableBccDefault: enableBcc,
          bccDefault,
        } = (integration && integration.emailConfigs) || {
          toDefault: '""',
          textDefault: '""',
          subjectDefault: '""',
          enableCcDefault: false,
          ccDefault: '""',
          enableBccDefault: false,
          bccDefault: '""',
        };

        const to = eval(toDefault);
        const text = eval(textDefault);
        const subject = eval(subjectDefault);
        const cc = eval(ccDefault);
        const bcc = eval(bccDefault);

        /** @TODO parametrizar */
        // const to = item['SA1'] && item['SA1']['A1_EMAILPDF'] && item['SA1']['A1_EMAILPDF'].trim() || ''
        this.jsPdf.sendEmail.model = {
          to,
          text,
          subject,
          cc,
          bcc,
        };

        this.jsPdf.sendEmail.inputs = this.jsPdf.sendEmail.inputs.map((i) => {
          if (i.value === "cc") return { ...i, hide: !enableCc };
          else if (i.value === "bcc") return { ...i, hide: !enableBcc };
          return i;
        });
      } else {
        this.jsPDF.sendEmail.model = null;
      }
    },
  },

  async created() {
    this.validatedDetails = !this.typeDetails || this.typeDetails === "none";

    if (this.collection && (this.collection.includes('SC5') || this.collection.includes('SA1'))) {
      /**
       * Begin TO-DO
       * Retirar esse código e ver como usar em um filtro ou validação
       * na integrations
       */
      let filtersSE4 = [{ key: "active", operator: "==", value: true }];

      // se for bonificação ou cópia, só traga a 999
      if (
        (!this.id && this.claimRoute === "add") ||
        (this.claimRoute === "edit" &&
          this.collection === "SC5" &&
          this.item.C5_XTIPO !== "B")
      ) {
        this.resetRegister("SE4");
        filtersSE4.push({ key: "E4_CODIGO", operator: "!=", value: "999" });
      }
      // else
      //   filtersSE4.push({key: 'E4_CODIGO', operator: '!=', value: '999'})

      /**
       * End TO-DO
       */
    }

    if (this.id) {
      let handleItem = false;
      let item =
        this.registers[this.register] &&
        this.registers[this.register].find(
          (item) => item.id === this.$route.params.id
        );
      if (!item)
        item = await this.getById({
          collection: this.collection,
          register: this.register,
          id: this.$route.params.id,
          getFilial: false,
        });
      else this.normalizeItem(item);

      /**
       * TO-DO
       * Tire isso daqui
       */
      if (this.claimRoute === "add") {
        const {
          C5_CLIENTE,
          C5_LOJACLI,
          SA1,
          A1_NOME,
          A1_END,
          A1_EST,
          A1_MUN,
          A4_NREDUZ,
          C5_FILIAL,
          DA0,
          C5_TABELA,
          SA4,
          C5_TRANSP,
          DA0_DESCRI,
          C5_NUM,
          C5_TPFRETE,
        } = item;
        this.item = {
          C5_CLIENTE,
          C5_LOJACLI,
          SA1,
          A1_NOME,
          A1_END,
          A1_EST,
          A1_MUN,
          A4_NREDUZ,
          C5_FILIAL,
          DA0,
          C5_TABELA,
          C5_TPFRETE,
          E4Blocked: true,
          DA0_DESCRI,
          SA4,
          C5_TRANSP,
          C5_XTIPO: "B",
          C5_YPEDORI: C5_NUM,
        };

        /**
         * TO-DO
         * Tirar essa gambiarra daqui depois. Ver essa lógica para colocar na computed
         */
        // setTimeout(()=>{
        //   const SE4 = this.registers.SE4.find(condPG => condPG.E4_CODIGO.trim() === '999')
        //   this.item = {...this.item, SE4}
        // },1500)
      } else this.item = handleItem ? this.handleItem(item) : item;

      // se for criação, não puxe os impostos, pois é uma cópia
      if (
        this.claimRoute !== "add" &&
        (item.impostos || item.IMPOSTOS || item.Impostos)
      )
        this.calcImps(item.impostos || item.IMPOSTOS || item.Impostos);

      if (this.claimRoute !== "add") {
        await this.fetchDetails();
      }

      if (item[this.tableIdFrom] && !this.readonlyComputed)
        await this.getTableItems({
          collection: this.tableCollection,
          subCollection: this.tableSubcollection,
          orderSubcollection: this.orderSubcollection,
          id: item[this.tableIdFrom],
        });

      this.tableIdFromValue = item[this.tableIdFrom]
    } else {
      this.ready = true;

      // this.$nextTick(() => {

      //   this.$refs.formMaster.$on('change:' + this.tableIdFrom, async (id) => {
      //     await this.getTableItems({collection: this.tableCollection, subCollection: this.tableSubcollection, orderSubcollection: this.orderSubcollection, id})
      //   })
      // })
      // this.tableIdFromValue = item[this.tableIdFrom]
    }
    // this.startAutoSave()

    /**
     * @TODO
     * Código provisório, remover assim que possível.
     * v-on:change no input SA1 do form cabeçalho
     */
    const SA1Input = this.inputsMaster.find((input) => input.value == "SA1");
    if (SA1Input) {
      SA1Input.on = {
        change: [
          (SA1) => {
            if (typeof SA1.A1_XPTDESC == "string") {
              const value = parseFloat(SA1.A1_XPTDESC);
              if (isNaN(value))
                console.warn("A1_XPTDESC of client " + SA1.id + " is NaN.");
              else if (value == 0) this.A1_XPTDESC = null;
              else this.A1_XPTDESC = value;
            } else if (SA1.A1_XPTDESC == 0) {
              this.A1_XPTDESC = null;
            } else {
              this.A1_XPTDESC = SA1.A1_XPTDESC;
            }
          },
        ],
      };
    }
  },

  beforeUnmount() {
    clearTimeout(this.autoSave.timer);
  },
};
</script>

<style>
  .card-taxes ion-card-header {
    padding: .25em;
  }
  .card-errors ion-card-header {
    padding: 0;
    text-align: center;
  }
  .card-errors ion-card-header ion-button {
    margin: 0;
  }
</style>