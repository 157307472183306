<template>
  <section>
    <ion-select v-if="showCompanies" v-model="companySelect" :compare-with="compareWith" :style="{textAlign: 'left', paddingLeft: '10px'}">
      <ion-select-option
        v-for="company of companiesFiltered" :key="company" :value="company">
          {{company.M0_CODIGO}} - {{company.M0_NOME}}
          {{company.M0_FILIAL}}
      </ion-select-option>
    </ion-select>
  </section>
</template>

<script>
import { IonSelect, IonSelectOption } from '@ionic/vue'
import { createNamespacedHelpers, mapState, mapActions } from 'vuex'
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  components: {
    IonSelect,
    IonSelectOption
  },
  name: 'SelectEmpFil',
  data: () => ({
    companySelect: {}
  }),
  watch: {
    companySelect() {
      this.changeCompany({company: this.companySelect, user: this.user})
    }
  },
  computed: {
    ...mapState(['companies', 'companySelected']),
    ...authState(['user']),
    showCompanies() {
      return (this.user && this.user.companies && this.user.companies.length > 0) && (this.companies && this.companies.length > 0)
    },
    companiesFiltered() { 
      return this.companies.filter(x => this.user.companies.includes(x.id)) 
    }
  },
  methods: {
    ...mapActions(['changeCompany']),
    compareWith(o1, o2) {
      return o1.M0_CODIGO + o1.M0_CODFIL === o2.M0_CODIGO + o2.M0_CODFIL
    }
  },
  created() {
    this.companySelect = this.companySelected
  }
}
</script>

<style>
  ion-select {
    font-size: 16px;
    font-weight: 125;
  }
  ion-select-option {
    font-size: 8px;
  }
</style>