<template>
  <ion-grid :style="{height: '100%'}">
    <ion-row class="ion-justify-content-center ion-align-items-center" :style="{height: '100%', textAlign: 'center'}">
      <ion-col size="12">
        <img :src="logoAsset" :style="{width: '50%', marginBottom: '40px'}" alt="{{title}}" class="contain" />
        <br/>
        <ion-spinner name="dots"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner 
} from '@ionic/vue';
export default {
  name: 'Splash',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner 
  },
  computed:{
    logoAsset(){
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? require("@/assets/logo.png") : require("@/assets/logo_dark.png")
    }
  }
}
</script>

<style>
ion-spinner {
  transform: scale(2);
}
</style>