<template>
  <ion-card>
    <ion-card-header class="ion-no-padding">
      <ion-grid class="ion-no-padding">
        <ion-row :style="{backgroundColor: 'primary', opacity: opacity ? .25 : 1}">
          <ion-col size="7" class="ion-padding">
            <ion-row>
              <ion-label>
                <strong>
                  {{ playlist.snippet.title }}
                </strong>
              </ion-label>
            </ion-row>
            <ion-row v-if="playlist.videosList"
              >{{ playlist.videosList.length }} vídeos</ion-row
            >
          </ion-col>
          <ion-col size="5">
            <ion-img
              :style="{ height: '60px', objectFit: 'cover' }"
              v-if="playlist.snippet.thumbnails"
              :src="playlist.snippet.thumbnails.default.url"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-header>
    <ion-card-content class="ion-no-padding">
      <ion-list v-if="expanded" class="ion-no-padding">
        <template v-for="(video, index) in playlist.videosList" :key="index">
          <ion-grid @click=" play(video.snippet.resourceId ? video.snippet.resourceId.videoId : video.id)" class="ion-no-padding ion-no-margin" >
            <ion-row>
                <ion-img v-if="video.snippet.thumbnails" :style="{ objectFit: 'cover' }" :src="video.snippet.thumbnails.maxres.url" alt="texto" />
            </ion-row>
          </ion-grid>
          <ion-item-divider class="ion-padding">
              <ion-label>{{ video.snippet.title }}</ion-label>
          </ion-item-divider>
        </template>
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  //   IonItem,
  IonImg,
  IonLabel,
  IonItemDivider,
} from "@ionic/vue";
export default {
  name: "Playlist",
  props: ["playlist", "expanded", "opacity"],
  emits: ["selectVideo"],
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    // IonItem,
    IonImg,
    IonLabel,
    IonItemDivider,
  },
  // data() {
  //   return {
  //     expand: false,
  //   };
  // },
  computed: {
    computedExpand() {
      return this.expanded
    }
  },
  methods: {
    play(videoId) {
      this.$emit("selectVideo", videoId);
    },
  }
};
</script>

<style>
</style>