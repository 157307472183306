<template>
  <ion-page>
    <ion-content>
      <ion-list v-if="items.length > 0">
        <template v-for="(item, index) in items" :key="index">
          <ion-card :router-link="`/tabs/tickets/detalhes/${item.id}`">
            <ion-card-content>
              <ion-grid>
                <ion-row v-for="header in headers" :key="header.value">
                  <ion-col size="4">
                    <ion-label>{{ header.text }}</ion-label>
                  </ion-col>
                  <ion-col size="8">
                    <ion-label>{{ item[header.value] }}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </template>
      </ion-list>

      <ion-grid v-else :style="{height: '100%'}">
        <ion-row class="ion-justify-content-center ion-align-items-center" :style="{height: '100%', textAlign: 'center'}">
          <ion-col size="12">
            <ion-icon :icon="fileTrayOutline" :style="{width: '10em', height: '10em', opacity: '0.3'}"/>
            <br/>
            <ion-label :style="{opacity: '0.3', textSize: '10em'}">Sem tickets cadastrados</ion-label>
          </ion-col>
        </ion-row>
  </ion-grid>
      
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="callRegister()">
          <ion-icon :icon="add" />
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import store from "@/store";
import { mapMutations } from 'vuex'
import {
  IonContent,
  IonPage,
  // IonHeader,
  // IonToolbar,
  // IonTitle,
  // IonBackButton,
  IonList,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonFab,
  IonFabButton,
  IonIcon,
} from "@ionic/vue";
import { add, fileTrayOutline } from "ionicons/icons";
import { createNamespacedHelpers } from "vuex";
const { mapState: authState } = createNamespacedHelpers("auth");
const { mapActions: registersActions } = createNamespacedHelpers("registers");

export default {
  name: "TicketsList",
  components: {
    IonContent,
    IonPage,
    // IonHeader,
    // IonToolbar,
    // IonTitle,
    // IonBackButton,
    IonList,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonFab,
    IonFabButton,
    IonIcon,
  },
  props: ["title", "collection", "register", "claims"],
  data() {
    return {
      headers: [
        { text: "Título", value: "titulo" },
        { text: "Data de criação", value: "data_criacao", type: "timestamp" },
        { text: "Status", value: "status" },
      ],
      items: [],
      filters: [],
      details: [],
    };
  },
  setup() {
    return {
      add, fileTrayOutline,
    };
  },
  computed: {
    ...authState(["user"]),
    computedMyRoute() {
      return this.$route.path.includes('ticket')
    }
  },
  methods: {
    ...mapMutations(['setSyncable']),
    ...registersActions(["getApi"]),
    callRegister() {
      store.$router.push("/tabs/tickets/cadastrar");
    },
    processStatus() {
      // Aberto - Status 0, 1, 3, 6, 7, 8, 9
      // Pendente comigo - 2
      // Encerrado - 4, 5

      for (const [index, item] of this.items.entries()) {
        item.id = item.idchamado;

        switch (item.ultimasituacao) {
          case (0, 1, 3, 6, 7, 8, 9):
            item.status = "Aberto";
            break;
          case 2:
            item.status = "Pendente comigo";
            break;
          case (4, 5):
            item.status = "Encerrado";
            break;
        }
      }
    },
    getTickets() {
      let options = { params: { cliente: this.user.email } };
      store.$api
        .get("/tickets", options)
        .then((response) => {
          this.items = [...response.data];
          this.processStatus();
        })
        .catch((error) => {});
    },
  },
  watch: {
    $route() {
      if (this.computedMyRoute) {
        this.setSyncable(false)
      }
    }
  },
  created() {
    if (this.computedMyRoute) this.setSyncable(false)
    this.getTickets();
  },
};
</script>

<style>
</style>