import axios from "axios";
import store from "../store/index";
// import Vue from "vue";
import { baseApiUrl } from "./globals";

const api = axios.create({
  baseURL: baseApiUrl,
  timeout: 10000000,
});

const success = (res) => res;
const error = async (error) => {
  const { noToast } = error.config;
  if (error.config && error.response && error.response.status === 401) {
    return store.dispatch("auth/refreshToken").then((response) => {
      error.config.headers["Authorization"] = "Bearer " + response.token;
      return axios.request(error.config);
    });
  }
  if (error.code == "ECONNABORTED" && !noToast)
    // Vue.$toast.error("Requisição cancelada, tempo excedido", {
    //   position: "top-right",
    // });
    console.error("Requisição cancelada, tempo excedido");
  else if (!noToast)
    // Vue.$toast.error(error.response.data.error || error.response.data, {
    //   position: "top-right",
    // });

  return Promise.reject(error);
};

api.interceptors.response.use(success, error);

export default api;
