<template>
  <ion-card>
    <ion-card-content>
      <form @submit.prevent="validate() && submit()" @reset.prevent="reset" ref="form" >
        <ion-list>
          <template v-for="(input, index) in inputs" :key="index" :style="{position: 'relative'}">
            <ion-item :class="input.type == 'divider' ? `divider-box${index > 0 ? '' : ' first'}` : 'box'" :style="{borderColor: computeInputsForValidation && auxInputs[input.value] && auxInputs[input.value].validated && !auxInputs[input.value].validated.valid ? 'red' : ''}" 
              v-show="input.hasOwnProperty('show') ? input.show : typeof input.hide == 'function' ? !input.hide({item: formData}) : typeof input.hide == 'string' ? !formData[input.hide] : !input.hide"
              lines="none">
              <!-- DIVIDER -->
              <template v-if="input.type == 'divider'">
                <span v-html="input.label"></span>
              </template>

              <!-- TEXT FIELD -->
              <template v-else-if="input.type == 'text-field'">
                <ion-label position="floating">{{ input.label }}</ion-label>
                <ion-input 
                  :class="input.value === 'status' ? 'input-status' : 'inherit'"
                  :hint="input.hint" 
                  :type="input.textType" 
                  :disabled="readonly || input.readonly || (typeof input.disabled == 'string' ? formData[input.disabled] : input.disabled)"
                  :placeholder="input.placeholder" 
                  v-model="formData[input.value]" 
                  @change="(event) => proccessInput(formData, input, event.target ? event.target.value || '' : '')" />
              </template>

              <!-- TEXT AREA -->
              <template v-else-if="input.type == 'textarea'">
                <ion-label position="floating">
                  {{ input.label }}
                </ion-label>
                <ion-textarea 
                  v-model="formData[input.value]" 
                  :placeholder="input.placeholder" 
                  :disabled="readonly || input.readonly || (typeof input.disabled == 'string' ? formData[input.disabled] : input.disabled)" 
                  :rows="input.rows || 10" />
              </template>

              <!-- SELECT -->
              <template v-else-if="input.type == 'select'">
                <ion-label position="floating">
                  {{input.label }}
                </ion-label>
                <ion-select
                  v-model="formData[input.value]"
                  :value="formData[input.value]"
                  :disabled="readonly || input.readonly || (typeof input.disabled == 'string' ? formData[input.disabled] : input.disabled)"
                  :placeholder="input.placeholder"
                  multiple="false"
                  :hint="input.hint"
                  @ionChange="(value) => validateInput(input, value)">
                  
                  <template v-for="(item, index) in (typeof input.items == 'string') ? registers[input.items] : input.items" :key="index">
                    <ion-select-option :value="getValueSelect({item, input})" >
                    <!-- <ion-select-option :value="item" >  -->
                      <!-- {{ item.text }} -->
                      {{ (typeof input.itemText == 'function') ? input.itemText({item: formData, element: item}) : (!input.itemText ? item.text: input.itemText) }}
                    </ion-select-option>  
                  </template>
                </ion-select>
              </template>

              <!-- AUTOCOMPLETE -->
              <template v-else-if="input.type == 'autocomplete'">
                <SimpleAutoComplete
                  v-model="formData[input.value]" 
                  :disabled="readonly || input.readonly || (typeof input.disabled == 'string' ? formData[input.disabled] : input.disabled)"
                  :label="input.label"
                  :delay="'750'"
                  :itemText="input.itemText"
                  :value="input.value"
                  :placeholder="input.placeholder" 
                  :options="getItems({input, offline})"
                  @change="(value) => proccessInput(formData, input, value)" />
              </template>

              <!-- AUTOCOMPLETE ALGOLIA -->
              <template v-else-if="input.type === 'autocompletealgolia'">
                <SimpleAutoComplete
                  v-model="formData[input.value]" 
                  v-if="offline"
                  :disabledObject="{ params: {input, formData: {...formData}, readonly}, 'execDisable': ({input, formData}) => readonly || input['readonly'] || (typeof input.disabled == `string` ? formData[input.disabled] : input.disabled) }"
                  :label="input.label"
                  :delay="'750'"
                  :itemText="input.itemText"
                  :value="input.value"
                  :attributesToHighlight="input.fieldsToShow" 
                  :placeholder="input.placeholder" 
                  :options="getItems({input, offline})"
                  @change="(value) => proccessInput(formData, input, value)" />
                <AutoCompleteAlgolia 
                  v-else
                  v-show="!input.hide" 
                  v-model="formData[input.value]" 
                  :modelForm="{...formData[input.value]}" 
                  :disabledObject="{ params: {input, formData: {...formData}, readonly}, 'execDisable': ({input, formData}) => readonly || input['readonly'] || (typeof input.disabled == `string` ? formData[input.disabled] : input.disabled) }"
                  :index="input.value" 
                  :value="input.value" 
                  :placeholder="input.placeholder" 
                  :label="input.label" 
                  :options="computedAlgoliaHits[input.value]" 
                  :optionsKey="input.itemText" 
                  :delay="750" 
                  :handler="handlerAlgolia" 
                  :attributesToHighlight="input.fieldsToShow" 
                  :restrictSearchableAttributes="input.fieldsToSearch" 
                  @changed="(value) => proccessInput(formData, input, value)"> 
                </AutoCompleteAlgolia>
              </template>

              <!-- SWITCH -->
              <template v-else-if="input.type == 'switch' && input.parent && formData[input.parent]">
                <ion-label>{{ input.label }}</ion-label>
                <ion-toggle 
                  :disabled="readonly || input.readonly || (typeof input.disabled == 'string' ? formData[input.disabled] : input.disabled)"
                  v-model="formData[input.parent][input.value]" />
              </template>
              <template v-else-if="input.type == 'switch'">
                <ion-label>{{ input.label }}</ion-label>
                <ion-toggle 
                  :disabled="readonly || input.readonly || (typeof input.disabled == 'string' ? formData[input.disabled] : input.disabled)"
                  v-model="formData[input.value]" />
              </template>
            </ion-item>
            <template v-if="auxInputs[input.value] && auxInputs[input.value].validated && auxInputs[input.value].validated.message">
              <ion-label :style="{color: 'red'}">
                {{auxInputs[input.value].validated.message}}
              </ion-label>
            </template>
          </template>
        </ion-list>
      </form>
    </ion-card-content>
  </ion-card>
</template>

<script>
// import VJsf from '@koumoul/vjsf'
// import VAutocompleteAlgolia from '../VAutocompleteAlgolia.vue'
// import { reactive } from 'vue'
import {createNamespacedHelpers, mapMutations, mapState} from "vuex";
import useAutoComplete from '../../useAutoComplete'
import SimpleAutoComplete from "../SimpleAutoComplete.vue";
import AutoCompleteAlgolia from "../AutoCompleteAlgolia.vue";
import store from "../../store";
import {
  IonInput,
  IonList,
  IonItem,
  IonCard,
  IonCardContent,
  // IonText,
  IonLabel,
  IonTextarea,
  IonSelectOption,
  IonSelect,
  IonToggle,
} from "@ionic/vue";
const {mapState: registersState, mapActions: registersActions, mapMutations: registersMutations} = createNamespacedHelpers("registers");
const {mapState: authState, mapActions: authActions} = createNamespacedHelpers("auth");
export default {
  name: "Form",
  // emits: ['onValidated'],
  components: {
    IonInput,
    IonList,
    IonItem,
    IonCard,
    IonCardContent,
    IonLabel,
    IonTextarea,
    IonSelectOption,
    IonSelect,
    IonToggle,
    SimpleAutoComplete,
    AutoCompleteAlgolia,
  },
  props: {
    inputs: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "teal",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    collection: {
      required: false,
      type: String,
    },
    collectionDetails: {
      required: false,
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      /**
       * Guarda o conteúdo (`String`) de cada input on the fly através do v-bind
       */
      formData: {},
      options: [
        { value: '1', text: 'aa' + ' - ' + '1' },
        { value: '2', text: 'ab' + ' - ' + '2' },
        { value: '3', text: 'bc' + ' - ' + '3' },
        { value: '4', text: 'cd' + ' - ' + '4' },
        { value: '5', text: 'de' + ' - ' + '5' }
      ],
      item: {
        value: '',
        text: ''
      },

      /**
       * Guarda os valores de dados auxiliares de cada input.
       */
      auxData: {},

      /**
       *
       */
      querySelection: null,
      formChips: 4,
      searchAlgolia: "",
      algoliaHits: [],
      auxInputs: [],
    };
  },
  computed: {
    ...registersState(["registers", "counters"]),
    ...authState(["user"]),
    ...mapState(["searching", "offline", "companySelected"]),

    itemsForAutocomplete(input) {
      return typeof input.items == "string" ? registers[input.items] : Array.isArray(input.items) ? input.items : auxData[input.value].items;
    },

    computeInputsForValidation(){
      this.inputs.map(input => {
        if(input.rules) {
          this.auxInputs[input.value] = {...input, validated:{valid: false, message: ''}}
        }
      });

      return true;
    },

    computedAlgoliaHits() {
      return this.algoliaHits;
    },
    computedOffline() {
      return this.offline
    }
  },

  watch: {
    model: {
      handler: function(newValue) {
        this.setFormData(newValue);
      },
    },
    user: {
      handler: function(newValue) {
        this.getUser(newValue);
      },
    },
    inputs: {
      handler: function() {
        this.setFormData(this.model);
      },
    },
    auxData: {
      handler: function() {
        this.setFormData(this.model);
      },
    },
  },

  methods: {
    ...registersActions(["setLoadedForm", "save", "getDetails", "get", "getApi", "getById", "getOffline"]),
    ...registersMutations(["setRegister"]),
    ...authActions(["getSA3Subs"]),
    ...mapMutations(["setLoading"]),

    getValueSelect({item, input}){

      // if (input.value === 'SE4') {
      // }
      if (input.itemValue) {
        return item[input.itemValue]
      }

      return item.value ? item.value : item
    },

    getItems({input, algolia = false, offline = false}) {
      if (offline) {
        let items = []
        if (this.auxData[input.value] && this.auxData[input.value].items) {
          items = this.auxData[input.value].items
        } else if (input.itemsOffline) {
          const { collection, gpEmpresa } = input.itemsOffline
          // console.info('collection', collection);
          const { M0_CODIGO } = this.companySelected
          const ge = gpEmpresa ? `${M0_CODIGO}0` : ''
          const itemsStoraged = JSON.parse(localStorage.getItem(`offline:${collection}${ge}`))
          items = itemsStoraged ? Object.keys(itemsStoraged).map((key, index) => itemsStoraged[key]) : []
          // console.info('items', items);
        }
        return items
      }
      else if (algolia) {
        return this.computedAlgoliaHits[input.value];
      } else {
        const items = typeof input.items == "string" ? this.registers[input.items] : Array.isArray(input.items) ? input.items : this.auxData[input.value].items;
        return items;
      }
    },

    async computeHide(item, input) {
      const self = {
        getById: this.getById,
        get: this.get,
        // getSA3Subs: this.getSA3Subs,
        item,
        input,
        user: this.user,
        registers: this.registers,
      };

      let show = true;

      if (typeof input.hide == "function") {
        show = !(await input.hide(self));
      } else if (typeof input.hide == "string") {
        show = !this.formData[input.hide];
      } else if (typeof input.hide == "boolean") {
        show = !input.hide;
      }

      // this.$set(input, 'show', show)
      input["show"] = show;

      return show;
    },

    submit(event) {
      this.$emit("submit", this.getModel());
    },

    validate() {
      Object.keys(this.auxInputs).forEach((key) => {
        if(!this.auxInputs[key].validated.valid){
          return false
        }
      });

      return true
    },

    validateInput(input, value){

      if(input.rules){

        input.rules.forEach(rule => {
          let response = rule(value)
          if(this.auxInputs[input.value]){
            this.auxInputs[input.value].validated.valid = (typeof response === 'boolean') ? response : false
            this.auxInputs[input.value].validated.message = (typeof response === 'string') ? response : ''
          }
        })
      }
    },

    proccessInput(item, input, value) {
      this.validateInput(input, value)
      this.setValue(item, input, value);
      this.proccessDependencies(item, input);
      this.computeHide(item, input);
    },

    getModel() {
    const formData = store.$_.cloneDeep(this.formData);

      // Filtra os inputs que estão com a flag send = false
      this.inputs
        .filter((input) => (typeof input.send == "function" ? !input.send(formData) : "send" in input && !input.send))
        .map((input) => input.value)
        .forEach((value) => delete formData[value]);

      const jsfInputs = this.inputs
        .filter((input) => input.type == "jsf" && input.root == true)
        .reduce((acc, input) => {
          const inputData = formData[input.value];
          delete formData[input.value];
          return {
            ...acc,
            ...inputData,
          };
        }, {});

      /**
       * Transforma os atributos com chaves compostas (ex.: 'a.b.c': 1) em objetos aninhados (ex.: a: { b: { c: 1 } } ).
       */
      const entries = Object.entries(formData);
      const [keys, vals] = entries.reduce(
        ([accKeys, accVals], [key, val]) => [
          [...accKeys, key],
          [...accVals, val],
        ],
        [[], []]
      );
      const formDataDeep = store.$_.zipObjectDeep(keys, vals);

      return {...formDataDeep, ...jsfInputs};
    },

    async setFormData(model) {
      /**
       * Possível generalização de condição de haver um dado auxiliar + caracteristicas do dado a ser criado + valor default.
       */
      this.inputs
        .filter((input) => input.type === "autocomplete")
        .forEach((input) => {
          if (input.computed) {
            this.computeValue(model, input);
          }

          if (input.value in this.auxData) {
            this.auxData[input.value] = {...this.auxData[input.value], loadingAutocomplete: false, searchAutocomplete: null};
            // this.auxData[input.value]["searchAutocomplete"] = null;
          } else {
            // this.$set(this.auxData, input.value, { loadingAutocomplete: false, searchAutocomplete: null })
            this.auxData[input.value] = {loadingAutocomplete: false, searchAutocomplete: null};
          }
        });

      this.inputs
        .filter((input) => "select#switch#text-field".includes(input.type) && input.computed)
        .map((input) => {
          
          // const valueInput = eval(input.default)
          // this.computeValue(model, input)
        });

      this.inputs
        .filter((input) => input.type === "password")
        .forEach((input) => {
          if (input.value in this.auxData) {
            this.auxData[input.value]["showPassword"] = false;
          } else {
            // this.$set(this.auxData, input.value, { showPassword: false })
            this.auxData[input.value] = {showPassword: false};
          }
        });

      // this.inputs.filter(input => typeof input.hide === 'string').map(input => input.hide = eval(input.hide))validatedvalidated

      /**
       * Atribui um valor inicial para cada input de acordo com a propriedade correspondente em this.model.
       * A forma de atribuir o valor varia de acordo com o tipo do input.
       */
      await Promise.all(
        this.inputs.map(async (input) => {
          switch (input.type) {
            case "text-field":
            case "password":
            case "textarea":
            case "switch":
            case "select": 
            // {
            //   const value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";

            //   this.formData = {...this.formData, [input.value]: value}

            //   break;
            // }
            case "combobox":
            case "file": {
              let value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";

              if (input.date && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = store.$moment(value.toDate()).format("DD/MM/YYYY");
              }
              if (input.dateHour && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = store.$moment(value.toDate()).format("DD/MM/YYYY - HH:mm:ss");
              }
              if (input.dateYear && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = store.$moment(value.toDate()).format("YYYY");
              }
              if (input.datePlusWeek && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = store.$moment(value.toDate()).format("DD/MM/YYYY, dddd");
              }
              // this.$set(this.formData, input.value, value)
              this.formData[input.value] = value;
              break;
            }

            case "autocomplete": {
              await this.loadItems({input, model})
              break
            }

            case "autocompletealgolia": {
              const value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";
              // this.$set(this.formData, input.value, value)
              // this.formData[input.value] = value;

              this.formData = {...this.formData, [input.value]: value}
            
              if (value) 
                this.algoliaHits = {...this.algoliaHits, [input.value]: [value]}
              
              // if (value)
                // this.$set(this.algoliaHits, input.value, [value])
                // this.formData[input.value] = [value];

              // prop item-text
              if (input.fieldsToShow) {
                input.itemText = ({element}) => {
                  let str = element[input.fieldsToShow[0]] && element[input.fieldsToShow[0]].trim();
                  input.fieldsToShow.slice(1).forEach((field) => {
                    if (element[field] && typeof element[field] === "string") {
                      str = str + " - " + element[field].trim();
                    }
                  });
                  return str;
                }
              }

              break;
            }

            case "jsoneditor": {
              // this.$set(this.formData, input.value, model[input.value]? model[input.value] : {})
              this.formData[input.value] = model[input.value] ? model[input.value] : {};
              break;
            }

            case "jsfChild": {
              let _model = [];
              if (model && model[input.value])
                _model = model[input.value].map((input) => {
                  return {
                    data: input.data || input,
                    delete: false,
                  };
                });

              // this.$set(this.formData, input.value, _model)
              this.formData[input.value] = _model;

              /**
               * Ordena os inputs
               */
              if (input.schema && input.schema.properties) input.schema.properties = this.sortJsfProperties(input.schema.properties);

              let auxData = [];
              for (let m of _model) {
                auxData.push({
                  schema: input.schema,
                  options: {disableAll: false},
                });
              }

              // this.$set(this.auxData, input.value, auxData)
              this.auxData[input.value] = auxData;

              break;
            }

            case "jsf": {
              /**
               * Se é uma string, pega o valor do jsoneditor correspondente,
               * Se é objeto, usa ele mesmo.
               */
              const schema = typeof input.schema == "object" ? input.schema : typeof input.schema == "string" ? this.formData[input.schema] : {properties: {}};

              const value = typeof input.schema == "object" ? input.value : input.schema;

              /**
               * Ordena os inputs
               */
              this.updateVJSF(schema, {value});

              if (!this.auxData[input.value])
                // this.$set(this.auxData, input.value, {})
                this.auxData[input.value] = {};

              /**
               * @TODO Modelar os atributos na raiz do formData em vez de input.value.
               * Atualmente a desestruturação só é feita na hora de enviar o form.
               */
              if (input.root) {
                const _model = Object.entries(model).reduce((acc, [key, value]) => (key in schema.properties ? {...acc, [key]: value} : acc), {});
                // this.$set(this.formData, input.value, _model)
                this.formData[input.value] = _model;
              } else {
                // this.$set(this.formData, input.value, this.auxData[input.value].model)
                this.formData[input.value] = this.auxData[input.value].model;
              }

              // this.$set(this.auxData[input.value], 'model', model[this.collectionDetails] || {})
              this.auxData[input.value]["model"] = model[this.collectionDetails] || {};
              break;
            }

            case "color-picker": {
              const value = model[input.value] || "#000000";
              // this.$set(this.formData, input.value, value)
              this.formData[input.value] = value;
              // this.$set(this.auxData, input.value, { menu: false, model: value, errorMessages: [] })
              this.auxData[input.value] = {menu: false, model: value, errorMessages: []};
              break;
            }
          }

          // Executa os v-on change na edição
          if (input.on && input.on.change) {
            input.on.change.forEach((change) => {
              change(this.formData[input.value]);
            });
          }
        })
      );

      this.inputs.forEach((input) => {
        if (input.computeFirst) this.computeValue(this.formData, input);
        this.computeHide(model, input);
      });

    },

    async loadItems({input, model, inputDispatcher}){
      let offlineReference = null
      if (input.type === 'autocomplete') {
        const value = model[input.value]
          ? model[input.value]
          : ('default' in input)
            ? input.default
            : ""

        this.auxData[input.value] = value;
  
        if (typeof input.items == 'object' && !Array.isArray(input.items)) {
          if (['get', 'getApi', 'getById', 'getSA3Subs'].indexOf(input.items.method) >= 0) {
            const user = {
              uid: this.user.uid,
              displayName: this.user.displayName,
              SA3: this.user.SA3,
              isSuperAdmin: this.user.claims && this.user.claims.admin
            }

            
            try {
              if (inputDispatcher && model[inputDispatcher.value] && model[inputDispatcher.value]['offline']) {
                offlineReference = { ...inputDispatcher.itemsOffline, id: model[inputDispatcher.value]['id'] }
              }
              const setParams = eval(input.items.params)
              let params = setParams ? setParams({ item: model, user }) : null

              if (params){
                if (offlineReference) {
                  params[0] = {...params[0], offlineReference}
                }
                const method = input.items.method === 'get' && this.offline && offlineReference ? 'getOffline' : input.items.method// 'get'
                const items = await this[method](...params)
                this.auxData[input.value] = {
                  ...this.auxData[input.value],
                  items
                }

              } else {
                this.auxData[input.value] = {
                  ...this.auxData[input.value],
                  items: []
                }
              }
            } catch (err) {
              console.error(`Error in input ${input.value}`, err)
            }
          } else {
            console.warn(`input "${input.value}" has invalid object "items" prop value on attribue "method" (items.method = ${input.items.method})`)
          }
  
        }
        
        if (value && input.returnObject && typeof input.items == "string")
          this.setRegister({ register: input.items, data: value })
      }
    },

    clearField({field}) {
      this.formData[field] = null;
    },

    changeColorPickerInput(value, input) {
      this.auxData[input.value].errorMessages.splice(0);
      if (!value) this.formData[input.value] = "#000000";
      else if (!/^#(?:[0-9a-f]{3}){1,2}$/i.test(value)) this.auxData[input.value].errorMessages.push("Formato inválido.");
      else this.formData[input.value] = value;
    },

    querySelections(query, input) {
      if (!query || query == this.auxData[input.value].lastQuerySelection) return;
      else this.auxData[input.value].lastQuerySelection = query;

      if (this.auxData[input.value].querySelection) {
        clearTimeout(this.auxData[input.value].querySelection);
        this.auxData[input.value].querySelection = null;
      }

      const {collection, register, filters, orderBy} = input.search || {};
      this.auxData[input.value].querySelection = setTimeout(async () => {
        this.auxData[input.value].querySelection = null;
        if (!query || !collection || !register || !orderBy) return;

        this.auxData[input.value].loadingAutocomplete = true;
        const response = await this.get({collection, register, orderBy, filters, query});
        this.auxData[input.value].loadingAutocomplete = false;
      }, 500);
    },

    reset() {
      this.inputs.forEach((input) => {
        if (input.type == "jsoneditor") {
          this.formData[input.value] = {};
        }
      });
      this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    updateVJSF(data, input) {
      if (data) {
        if (!this.auxData[input.value])
          this.auxData[input.value] = {};

        if (data.properties) data.properties = this.sortJsfProperties(data.properties);
        else {
          const properties = {};
          for (const el of data) {
            properties[el.value] = store.$_.cloneDeep(el);
            delete properties[el.value].value;
          }
          data = {
            properties,
            type: "object",
          };
        }

        this.auxData[input.value]["schema"] = data;
      }
    },

    sortJsfProperties(properties) {
      if (typeof properties == "object") return Object.fromEntries(Object.entries(store.$_.cloneDeep(properties)).sort(([, a], [, b]) => a.order - b.order));
      else return properties;
    },

    /**
     * Computa os valores dos atributos de `item` que estão presentes em `headers` respeitando a ordem passada
     */
    async computeItem(item, inputs, inputDispatcher) {
      for (const input of inputs) {
        if (input.reloadItems) {
          await this.loadItems({input, model: item, inputDispatcher})
        }
        await this.computeValue(item, input);
        await this.computeHide(item, input);
      }

      this.$emit('changeForm', {formData: this.formData})
    },

    /**
     * Computa o atributo `header` no objeto `item`
     */
    async computeValue(item, input) {
      const self = {
        getById: this.getById,
        getApi: this.getApi,
        get: this.get,
        getSA3Subs: this.getSA3Subs,
        item,
        input,
        user: this.user,
        setLoading: this.setLoading,
      };

      const computedValue = input.computed ? await input.computed(self) : item[input.value];

      this.proccessInput(item, input, computedValue);

      return computedValue;
    },

    setValue(item, input, value) {
      const handledValue = input.set ? input.set(value, item) : value;

      item = {...item, [input.value]: handledValue}
      
      this.formData[input.value] = handledValue

      this.$emit("change:" + input.value, handledValue)

      return handledValue;
    },


    async proccessDependencies(item, input) {
      // Os inputs/atributos a serem usados como busca não devem incluir o próprio elemento que está sendo alterado.
      const inputs = this.inputs.filter((h) => h != input);

      // Array com os dependentes diretos do input, elimina a possibilidade do elemento ser dependente *direto* dele mesmo devido ao filter.
      const directDependentAttributes = this.getDependentAttributes(input, inputs);

      // Headers a serem computados respeitando a ordem de precedência da menor posição do array pra maior.
      const dependencyChain = this.getDependencyChain(directDependentAttributes, inputs);

      // for of para respeitar a ordem do array `dependencyChain`.
      for (const dependentAttributes of dependencyChain) {
        await this.computeItem(item, dependentAttributes, input);
        inputs.map(async (i) => await this.computeHide(item, i));
      }
    },

    /**
     * Retorna um array de inputs que dependem *diretamente* do `input` passado no primeiro parâmetro
     */
    getDependentAttributes(input, inputs) {
      return inputs.filter((h) => h.dependents && h.dependents.includes(input.value));
    },

    /**
     * Retorna uma cadeia de inputs que são dependentes dos inputs passados no primeiro argumento.
     * A ordem dos elementos no array indicam o grau de dependência dos inputs.
     */
    getDependencyChain(dependents, inputs, dependencyChain = []) {
      if (dependents.length == 0) return dependencyChain;

      const filteredInputs = inputs.filter((h) => !dependents.includes(h));
      const newDependents = dependents.map((h) => this.getDependentAttributes(h, filteredInputs)).reduce((acc, arr) => [...acc, ...arr.filter((el) => !acc.includes(el))], []);

      return this.getDependencyChain(newDependents, filteredInputs, [...dependencyChain, dependents]);
    },

    getUser(user) {
      // ver isso aqui  depois
      if (!this.readonly) {
        const _user = user
          ? {
              uid: user && user.uid,
              displayName: user.displayName,
              SA3: user.SA3,
            }
          : null;

        const input = this.inputs.find((i) => i.value == "_user") || {value: "_user"};
        this.proccessInput(this.formData, input, _user);
      }
    },

    async handlerAlgolia(req) {
      try {
        const {index, hits} = await req;
        // this.$set(this.algoliaHits, index, hits)
        this.algoliaHits = {...this.algoliaHits, [index]: hits};
      } catch (err) {
        console.error(err);
      }
    },

    selectAll({input, items, itemValue}) {
      const {value} = input;
      this.$nextTick(() => {
        if (items && this.formData[value] && this.formData[value].length >= items.length) {
          this.proccessInput(this.formData, input, []);
        } else if (items) {
          this.proccessInput(this.formData, input, itemValue ? items.map((x) => x[itemValue]).slice() : items.slice());
        }
      });
    },
  },

  setup(props) {
    return {
      ...useAutoComplete(props.options, props.optionsKey),
    };
  },
  created() {
    // this.auxInputs = store.$_.cloneDeep(this.inputs)

    this.setFormData(this.model)
    if (![undefined, "users"].includes(this.collection)) this.getUser(this.user);
  },
};
</script>
<style scoped>
  .box {
    border-color: #dadada;
    border-width: thin;
    border-style: solid;
    border-radius: 7.5px;
    padding: 0;
    margin-bottom: .2em;
    margin-top: .75em;
  }
  .divider-box {
    border: 0;
    border-bottom: .2em;
    border-color: #dadada;
    border-style: solid;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 0;
  }
  .divider-box:not(.first) {
    padding-top: .5em;
  }
  .divider-box * {
    font-size: 1.3em;
  }
</style>
