<template>
  <div class="div-autocomplete" :style="{width: '100%'}">
    <ion-label position="floating">{{ label }}</ion-label>
    <ion-input 
      clear-input 
      type="text" :placeholder="placeholder + ' ' + disabled" 
      @ionInput="onInput" 
      v-model="userInput" 
      @click="$event.target.select()"
      :disabled="disabled" />
    <ion-card class="suggestions-card " v-if="filteredSuggestions && filteredSuggestions.length">
      <ion-item v-for="(s, i) in filteredSuggestions" :key="i" @click="selected(s)" lines="none" :style="{fontSize: '.9em'}">
        <template v-if="attributesToHighlight && attributesToHighlight.length > 0">
          <span v-html="getHighlightResult(s)"></span>
        </template>
        <template v-else>
          <div>{{ typeof optionsKey == "function" ? optionsKey({element: s}) : s[optionsKey] }}</div>
        </template>
      </ion-item>
    </ion-card>
  </div>
</template>

<script>
import useAutoComplete from "../useAutoComplete";
import {IonCard, IonLabel, IonInput, IonItem} from "@ionic/vue";
export default {
  name: "AutoComplete",
  components: {
    IonCard,
    IonLabel,
    IonInput,
    IonItem,
  },
  emits:[
    'changed'
  ],
  watch: {
    options() {
      if (this.options && this.options.length > 0) {
        this.suggestions = this.options;
      } 
    },
    userInput(newValue) {
      if (!newValue || newValue.trim() === '') {
        this.isItemAvailable = false;
        this.$emit('changed', null)
      }
    },
    selectedItem() {
      this.$emit(`changed`, this.selectedItem.item)
    },
    modelForm(){
      // this.selected(this.modelForm)
    }
  },
  props: {
    attributesToHighlight: Array,
    label: String, 
    modelForm: null,
    msg: {
      type: String,
      required: false
    }, 
    options: Array, 
    optionsKey: null, 
    placeholder: String,
    disabledObject: {
      type: Object
    }
    // value: null
  },
  created(){
    const { params = null, execDisable = null } = this.disabledObject
    this.disabled = execDisable ? execDisable(params) || false : false
    if (this.modelForm ) this.selected(this.modelForm)
  },
  setup(props) {
    return {
      ...useAutoComplete(props.options, props.optionsKey),
    };
  },
  data() {
    return {
      disabled: Boolean
    };
  },
  methods: {
    onInput(event) {
      this.onChange(event)
    },
    getHighlightResult(item){
      return this.attributesToHighlight.map(field => 
        (item._highlightResult 
          && item._highlightResult[field] 
          && item._highlightResult[field].matchedWords
          && item._highlightResult[field].matchedWords.length > 0
        ) ? item._highlightResult[field].value : item[field]
      ).join(' - ')
    },
    doSave: function(_clear) {
      if (_clear) {
        this.$emit("save-option", {});
        this.userInput = "";
      } else {
        this.$emit("save-option", this.selectedItem);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  body {
    font-family: sans-serif;
  }

  /* input {
      border: 1px solid #999;
      padding: 0.5rem;
      width: 300px;
    } */

  .suggestions-card {
    /* padding: 4px; */
    opacity: 0.5;
    margin: 0 0.2em 1em 0em;
    /* width: calc(100% -8px); */
    box-shadow: 2px 2px #929292 !important;
  }
</style>
