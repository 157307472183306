import {createStore} from "vuex";
import auth from "./auth";
import registers from "./registers";
import crypto from "crypto";
import axios from "axios";

const gApi = axios.create();

export default createStore({
  modules: {auth, registers},
  state: {
    notificationToken: '',
    searching: {
      stats: false,
      index: "", 
      handler: null,
      filters: [], 
      attributesToHighlight: []
    },
    loading: {
      stats: false,
      message: "carregando",
    },
    alerts: [],
    lastAlert: "Avisos",
    snackbar: {
      stat: false,
      text: "",
      color: "info",
      timeout: 3000,
    },
    drawer: false,
    drawerRight: false,
    drawerForm: false,
    fixNDR: false,
    demo: false,
    companies: [],
    companySelected: {},
    synchronizing: false,
    lastSync: null,
    offline: false,
    syncable: false,
    collectionStructure: null,
    algolia: null,
    prothues: null,
  },
  mutations: {
    setSearching(state, {stats, index, handler, filters, attributesToHighlight}){
      state.searching = {stats, index, handler, filters, attributesToHighlight};
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLastSync(state, payload) {
      state.lastSync = payload;
    },
    setSynchronizing(state, payload) {
      state.synchronizing = payload;
    },
    setOffline(state, payload) {
      state.offline = payload;
    },
    setSyncable(state, payload) {
      state.syncable = payload;
    },
    pushAlert(state, payload) {
      state.alerts.push(payload);
      state.lastAlert = payload.type;
    },
    showSnackbar(state, payload) {
      state.snackbar = payload;
      state.snackbar.stat = true;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    setDrawerRight(state, payload) {
      state.drawerRight = payload;
    },
    setFixNDR(state, payload) {
      state.fixNDR = payload;
    },
    setDrawerForm(state, payload) {
      state.drawerForm = payload;
    },
    setDemo(state, payload) {
      state.demo = payload;
    },
    setCollectionStructure(state, payload) {
      state.collectionStructure = payload;
    },
    setAlgolia(state, payload) {
      state.algolia = payload;
    },
    setProtheus(state, payload) {
      state.protheus = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setCompanySelect(state, payload) {
      state.companySelected = payload;
      this.$api.defaults.params = {
        gpEmpresa: payload.M0_CODIGO + "0",
        filial: payload.M0_CODFIL,
      };
    },
  },
  actions: {
    setLastSync({commit}, lastSync) {
      commit('setLastSync', lastSync)
    },
    // GENERAL
    async initSystem({state, commit, dispatch}, {$router}) {
      dispatch("auth/authChanged", {$router}, {root: true});
    },
    // async setOffline({commit}, {isOffline}) {
    //   commit("setOffline", isOffline)
    // },
    async getConfigs({commit, dispatch}, {user}) {
      const companies = await dispatch("registers/get", {collection: "sigamat", register: "companies"}, {root: true});
      commit("setCompanies", companies);
      commit('setCompanySelect', await dispatch('setLastCompany', {companies, user})) 
      commit(
        "setCollectionStructure",
        await dispatch(
          "registers/getById",
          {
            collection: "_configs",
            register: "_configs",
            id: "collectionStructure",
            setRegister: false,
          },
          {root: true}
        )
      );
      commit(
        "setAlgolia",
        await dispatch(
          "registers/getById",
          {
            collection: "_configs",
            register: "_configs",
            id: "algolia",
            setRegister: false,
          },
          {root: true}
        )
      );
      commit(
        "setProtheus",
        await dispatch(
          "registers/getById",
          {
            collection: "_configs",
            register: "_configs",
            id: "protheus",
            setRegister: false,
          },
          {root: true}
        )
      );
    },
    async setLastCompany({commit}, {companies, user}){
      // ler do localstorage se está definido a última filial que o usuário que logou
      // caso não tenha encontre define a primeira filial disponivel para o usuário
      
      const lastCompanies = JSON.parse(localStorage.getItem('lastCompanyByUser')) || {}
      let lastCompany = lastCompanies[user.uid]
      if (!lastCompany) {
        const company = companies.find(x => user.companies.includes(x.id))
        lastCompany = company
        lastCompanies[user.uid] = lastCompany
        localStorage.setItem('lastCompanyByUser', JSON.stringify(lastCompanies))
      }
      return lastCompany
    },
    async changeCompany({state, commit, dispatch, rootState}, {company, user}) {
      if (!company) return
      if (!user) user = rootState.auth.user
      const oldCompany = this.$_.cloneDeep(state.companySelected)
      
      // Atualiza ultima filial logada
      const lastCompanies = JSON.parse(localStorage.getItem('lastCompanyByUser'))
      lastCompanies[user.uid] = company
      localStorage.setItem("lastCompanyByUser", JSON.stringify(lastCompanies))  
 
      commit('setCompanySelect', company)
  
      // Update User
      await dispatch('auth/updateUser', {}, {root:true}) 
  
      this.$integrations.forEach(integration => {
        let {register, collection, filters = '', orderBy = '', details, externalRequest} = integration
        if (!['SA1', 'SC5', 'DA0'].includes(register)) return // Desconsidera integrações não usadas no mobile
        if (
          (
            // state.collectionStructure[register].gpEmpresa === 'e'
            // &&
            oldCompany.M0_CODIGO !== company.M0_CODIGO
            || !oldCompany
          )
          ||
          (state.collectionStructure[register] && state.collectionStructure[register].filial === 'e')
        ) {
          // Reset Observers e Registers
          if (rootState.registers.observers[register]) {
            rootState.registers.observers[register].snapshots.forEach(el => {
              el()
            });
          }
          commit('registers/resetRegister', register, { root: true }) 

          try {
            if (['{', '['].some(symbol => orderBy.includes(symbol)))
              orderBy = JSON.parse(orderBy)
          } catch (e) {
            console.warn(`Error when trying to define "orderBy" in changeCompany ${integration.name}.`, e)
          }
          try {
            filters = (integration.filters) ? JSON.parse(integration.filters) : null
          } catch (e) {
            console.warn(`Error when trying to define "filters" in changeCompany ${integration.name}.`, e)
          }
          if (externalRequest && externalRequest.query) {
            dispatch('registers/getExternal',{
              ...externalRequest,
              register 
            })
          } else {
            // Start Observers
            dispatch('registers/observer', {
              collection, 
              register, 
              filters,
              orderBy,
              details
            }, {root: true})
          }
        }
      });
    },
    dispatchAction({dispatch}, {action, params}) {
      dispatch(action, params, {root: true});
    },
    notify({}, {title, body, image, badge, icon, toast = true}) {
      this.$notification.show(
        title,
        {
          lang: "PT",
          body,
          image,
          badge,
          icon,
        },
        {}
      );
      if (toast) {
        this.$toast.info(`${title} - ${body}`);
      }
    },
    gravatarUrl({}, {email}) {
      let hash = crypto
        .createHash("md5")
        .update('andrewillams@gmail.com' || "")
        .digest("hex");
      return `https://www.gravatar.com/avatar/${hash}?s=200&d=mm&r=g`;
    },
    downloadFileByUrl({}, {url, type, label}) {
      gApi
        .get(url, {responseType: "blob"})
        .then((response) => {
          const blob = new Blob([response.data], {type: response.data.type});
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((e) => console.error(e));
    },
    toggleSearching({commit}, {stats, index, handler, filters, attributesToHighlight}){
      commit('setSearching', {stats, index, handler, filters, attributesToHighlight});
    },
  },
});
