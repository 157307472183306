const FormData = require('form-data');
const stringify = require('json-stringify-safe');
import algoliasearch from 'algoliasearch/lite';
import store from '../store'
import { toastController } from '@ionic/core';

const register = {
  namespaced: true,
  registerActive: null,
  state: {
    registers: {},
    observers: {
      counts: { snapshots: [] }
    },
    loading: {},
    counters: {},
    lastAlgoliaReqParams: {},
    recentRemove: false,
  },
  mutations: {
    setRegister(state, { register, data, unshift = false }) {
      if (!state.registers[register]) { 
        // store.$set(state.registers, [register], [])
        state.registers[register] = []
      }
      const ix = state.registers[register].findIndex(x => x.id == data.id)
      if (ix > -1) {
        // store.$set(state.registers[register], [ix], data)
        state.registers[register][ix] = data
      } else if (unshift && !state.recentRemove) {
        state.registers[register].unshift(data)
      } else {
        state.registers[register].push(data)
      }
      state.recentRemove = false
    },
    setRegisterActive(state, {register}) {
      state.registerActive = register
    },
    removeRegister(state, { register, data }) {
      const ix = state.registers[register].findIndex(x => x.id == data.id)
      if (ix > -1) {
        state.registers[register].splice(ix, 1)
      }
    },
    resetRegister(state, register) { state.registers[register] = []},
    setRecentRemove(state, payload) { state.recentRemove = payload },
    // resetRegisters(state) { store.$set(state, 'registers', {}) },
    resetRegisters(state) {state.registers = {}},

    setCounter(state, { collection, count }) {
      // store.$set(state.counters, collection, count)
    },
    resetCounters(state) { state.counters = {} },
  },
  actions: {
    // GPEmpresa
    getGpEmpresa({ rootState }, collection) {
      let gpEmpresa = ''
      if (collection != '_configs' && rootState.collectionStructure && Object.prototype.hasOwnProperty.call(rootState.collectionStructure, collection)) {
        if (rootState.collectionStructure[collection].gpEmpresa == 'e') {
          gpEmpresa = rootState.companySelected.M0_CODIGO + '0'
        }
      }
      return gpEmpresa
    },

    // Filial
    getFilial({ rootState }, collection) {
      let filial = ''
      if (collection != '_configs' && rootState.collectionStructure && Object.prototype.hasOwnProperty.call(rootState.collectionStructure, collection)) {
        if (rootState.collectionStructure[collection].filial == 'e') {
          filial = rootState.companySelected.M0_CODFIL
        } else {
          filial = ' '.repeat(rootState.collectionStructure.filialTam)
        }
      }
      return filial
    },

    mountedFilters({ rootState }, { ref, filters, register, forceFilter = false }) {
      try {
        const user = rootState.auth.user // Disponivel para Filtro

        // DESABILITA FILTROS PARA SUPER ADMIN OU PAPEL ESTEJA LIBERADO
        if (!forceFilter && ((user && user.claims && user.claims.admin) || (user && user.rules && user.rules.some(x => x.claims && x.claims[register] && x.claims[register].noFilters)) )) return ref || []
        // if (user?.claims?.admin || user?.rules.some(x => x.claims && x.claims[register] && x.claims[register].noFilters)) return ref || []
        let ret = []

        filters.forEach(el => {
          let { key, operator, value, expectedValue } = el
          // console.info('FILTER =>', key, operator, value, expectedValue)

          // Validation
          if (
            !key
            || !operator
            || (typeof value != 'boolean' && !value)
            || (Array.isArray(value) && !value.length > 0)
          ) throw { error: 'Malformed Filter', filter: el }

          if (key.includes('${')) key = eval(key)
          if (typeof value == 'string' && value.includes('${')) value = eval(value)

          // Array
          if (value == undefined || value == 'undefined') value = ''
          if (typeof value == 'string' && value.includes('[') && value.includes(']') && expectedValue && expectedValue === 'array') value = eval(value)
          else if (typeof value == 'string' && value.includes(',') && expectedValue && expectedValue === 'array') value = value.split(",")
          else if (typeof value == 'string' && value && expectedValue && expectedValue === 'array') value = [value]

          // console.info('FORMATED FILTER =>', key, operator, value, user)
          if (!!key && !value) throw { error: 'Empty Filter', filter: el }
          else if (!!key && !!value && ref) ref = ref.where(key, operator, value)
          else if (!!key && !!value) ret.push({ key, operator, value })
        })

        return (ref) ? ref : ret
      } catch (error) {
        console.error(error)
        return null
      }
    },

    // OBSERVER
    async prepareRef ({dispatch}, {  gpEmpresa, filial, collection, register, filters, orderBy, limit = 30, forceFilter }) {
      let ref = this.$db().collection(collection)

      // FILTERS
      if (filters) ref = await dispatch('mountedFilters', { ref, filters, register, forceFilter })
      if (!ref) return state.loading[register] = false

      ref = ref.where('lifeControl.deletedAt', '==', null)
      if (filial) ref = ref.where('filial', '==', filial)

      // ORDER BY
      let order = (Array.isArray(orderBy)) ? orderBy : [orderBy]
      for (const el of order) {
        if (el && typeof el == 'string') {
          ref = ref.orderBy(el)
        } else if (el && typeof el != 'string') {
          ref = ref.orderBy(el.key, el.mod)
        } else {
          ref = ref.orderBy(this.$db.FieldPath.documentId(), 'desc')
        }
      }

      // LIMIT
      ref = ref.limit(limit)

      return ref
    },
    async observer({ state, dispatch, rootState }, { collection, register, filters, details, orderBy, limit, forceFilter }) {
      if (!state.registers[register]) { 
       state.registers[register] = [] 
      }
      if (!state.loading[register]) {
        state.loading[register] = true
      }

      // PREPARE ENVIROMENT
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      collection = collection + gpEmpresa

      dispatch('count', { collection: 'searchAlgolian' })
      // dispatch('count', { collection })

      const ref = await dispatch('prepareRef', { gpEmpresa, filial, collection, register, filters, orderBy, limit, forceFilter })

      // Grava Referencia e register para paginação
      if (!state.observers[register]) { state.observers[register] = {} }

      state.observers[register].ref = ref
      state.observers[register].gpEmpresa = gpEmpresa
      state.observers[register].filial = filial
      state.observers[register].collection = collection
      state.observers[register].details = details
      state.observers[register].orderBy = orderBy
      state.observers[register].filters = filters
      state.observers[register].limit = limit
      state.observers[register].forceFilter = forceFilter

      // SNAPSHOT
      // console.info('observer', collection, register, filters, details, orderBy, limit, ref)
      const before = this.$_.before(2, snapshot => dispatch('processregister', { snapshot, collection, register, details, orderBy }));
      const after = this.$_.after(2, snapshot => dispatch('processregister', { snapshot, collection, register, details, orderBy, unshift: true }));
      const registerSnapshot = ref.onSnapshot(snapshot => { before(snapshot); after(snapshot) })

      // Grava listener para encerramento posterior
      if (state.observers[register].snapshots && state.observers[register].snapshots.length > 0) { state.observers[register].snapshots.push(registerSnapshot) }
      else { state.observers[register].snapshots = [registerSnapshot] }
      state.observers[register].snapshots = state.observers[register].snapshots

    },
    async nextObserver({ state, dispatch }, { register }) {
      const gpEmpresa = state.observers[register].gpEmpresa
      const filial = state.observers[register].filial
      const collection = state.observers[register].collection
      const details = state.observers[register].details
      const orderBy = state.observers[register].orderBy
      const filters = state.observers[register].filters
      const limit = state.observers[register].limit
      const forceFilter = state.observers[register].forceFilter
      const lastDocCursor = state.observers[register].lastDocCursor
      const ref = await dispatch('prepareRef', { gpEmpresa, filial, collection, register, filters, orderBy, limit, forceFilter })

      if (!lastDocCursor) { return }

      let startAfter = ''
      if (lastDocCursor.length == 1) {
        startAfter = lastDocCursor[0]
      } else {
        for (const el of lastDocCursor) {
          startAfter += `'${el}', `
        }
        startAfter = startAfter.slice(0, -2)
      }

      ref.startAfter(startAfter)
        .onSnapshot(snapshot => {
          dispatch('processregister', { snapshot, collection, register, details, orderBy })
        })
    },
    async processregister({ state, commit, dispatch }, { snapshot, collection, register, details, orderBy, unshift }) {
      const changes = snapshot.docChanges()
      const registers = []
      for (const [idx, el] of changes.entries()) {
        const data = { id: el.doc.id, ...el.doc.data() }
        registers.push(data)
        if (el.type == 'added' || el.type == 'modified') {
          commit('setRegister', { register, data, unshift })
        }
        else if (el.type == 'removed') { commit('removeRegister', { register, data }) }
      }

      // GET Detalhes em outras collections baseada nos registros encontrados
      if (details) {
        registers.forEach(register => {
          details.forEach(detail => {
            dispatch('getDetails', { item: store.$_.cloneDeep(register), detail: store.$_.cloneDeep(detail) })
          });
        });
      }

      // Grava último id para próxima iteração
      const last = snapshot.docs[snapshot.docs.length - 1];
      let lastDocCursor = null
      if (snapshot.docs.length > 0) {
        let order = (Array.isArray(orderBy)) ? orderBy : [orderBy]
        lastDocCursor = []
        for (const el of order) {
          if (el && typeof el == 'string') {
            lastDocCursor.push(last.data()[el])
          } else if (el && typeof el != 'string' && el.key.includes('.')) {
            const path = el.key.split('.')
            let lastData = last.data()
            for (const p of path) {
              lastData = lastData[p]
            }
            lastDocCursor.push(lastData)
          } else if (el && typeof el != 'string') {
            lastDocCursor.push(last.data()[el.key])
          } else {
            lastDocCursor.push(last.id)
          }
        }
      }
      state.observers[register].lastDocCursor = lastDocCursor
      state.loading[register] = false
    },
    // GET Details
    async getDetails({ dispatch }, { item, detail, endSlice = 1 }) {
      const { collection, register, filters, orderBy } = detail
      let get = true
      // Replace Filters
      filters.forEach(elx => {
        if (typeof elx.value == 'string' && elx.value.includes('res.')) {
          const path = elx.value.split('.')
          path.shift()
          let value = store.$_.cloneDeep(item)
          path.forEach(ely => {
            const newValue = value[ely]
            if (value && Array.isArray(newValue) && newValue.length > 0) value = newValue.filter(x => x).slice(0, endSlice)
            else if (value && typeof newValue == 'string' && newValue) value = [newValue]
            else if (value && typeof newValue == 'boolean') value = newValue
            else if (value && typeof newValue == 'object' && !Array.isArray(newValue) && newValue) value = newValue
            else {
              value = null
              get = false
            }
          });
          elx.value = value
        }
      });
      if (get) dispatch('get', { collection, register, filters, orderBy })
    },
    async syncOffline({commit, dispatch, rootState}) {
      commit('setSynchronizing', true, {root: true})

      const currentCompany = rootState.companySelected
      for (const company of rootState.companies) {
        await dispatch('changeCompany', {company}, {root: true})
        // Sincroniza tabelas pequenas completas
        dispatch('observer', {
          collection: "SE4",
          register: "SE4",
          // filters: filtersSE4,
          filters: [{ key: "active", operator: "==", value: true }],
          orderBy: "E4_CODIGO",
          limit: 999,
          forceFilter: true,
        })
        dispatch('observer', {
          collection: "DA0",
          register: "DA0",
          filters: [{ key: "active", operator: "==", value: true }],
          // orderBy: 'indice',
          limit: 999,
        })
  
        // Sincroniza tabelas grandes com base no cadastro de integração e registros setados offline
        for (const integration of store.$integrations) {
          const fieldsOffline = integration && integration.inputsMaster ? integration.inputsMaster.filter(i => i.itemsOffline) || []: []
          if (fieldsOffline && fieldsOffline.length > 0) {
            for (const field of fieldsOffline) {
              const registersOff = await dispatch('cacheOffline', {collection: `${field.itemsOffline.collection}`})
              // console.info('registersOff', registersOff);
            }
          }
        }
        // localStorage.setItem('offline:lastSync', JSON.stringify(lastSync))
      }
      await dispatch('changeCompany', {company: currentCompany}, {root: true})
      commit('setSynchronizing', false, {root: true})
      dispatch('setLastSync', new Date(), {root: true})
    },

    // getLastSyncOffline({},{register = null}) {
    //   const lastSync = JSON.parse(localStorage.getItem('offline:lastSync'))
    //   if (lastSync) {
    //     return register ? lastSync[register] : lastSync
    //   }
    //   return null
    // },

    async getOffline({dispatch}, {collection, offlineReference}) {
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      const { id: idReference, collection: collectionReference, gpEmpresa: geReference } = offlineReference
      const collectionOffline = `${collectionReference}${geReference ? gpEmpresa : ''}`
      const reference = JSON.parse(localStorage.getItem(`offline:${collectionOffline}`))
      const documentReference = reference ? reference[idReference] : null
      if (documentReference) {
        return documentReference['dependents'] ? 
          documentReference['dependents'][`${collection}${geReference ? gpEmpresa : ''}`] || [] : 
          []
      }
      return []
    },

    // GET
    async get({ commit, dispatch }, { collection, register = collection, filters, orderBy, query, limit = 10, ref, setRegister = true, forceFilter, offlineReference = false }) {
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const filial = await dispatch('getFilial', collection)
      collection = collection + gpEmpresa
      // console.info((ref) ? ref.path : null)
      if (!ref) ref = this.$db().collection(collection)
      
      // FILTERS
      if (filters) ref = await dispatch('mountedFilters', { ref, filters, register, forceFilter })
      if (!ref) return

      ref = ref.where('lifeControl.deletedAt', '==', null)
      if (filial) ref = ref.where('filial', '==', filial)

      // ORDER BY
      if (limit != 1) {
        let order = (Array.isArray(orderBy)) ? orderBy : [orderBy]
        for (const el of order) {
          if (el && typeof el == 'string') {
            ref = ref.orderBy(el)
          } else if (el && typeof el != 'string') {
            ref = ref.orderBy(el.key, el.mod)
          } else {
            ref = ref.orderBy(this.$db.FieldPath.documentId())
          }
        }
      }

      // START AT
      if (query) { ref = ref.startAt(filial + query).endAt(`${filial + query}\uf8ff`) }

      // LIMIT
      if (limit) ref = ref.limit(limit)

      const registerSnapshot = await ref.get()
      // console.info('get', collection, register, filters, orderBy, query, limit, ref, setRegister, registerSnapshot)

      const registers = []
      registerSnapshot.forEach(doc => {
        const data = { id: doc.id, ...doc.data() }
        registers.push(data)
        if (register && setRegister) commit('setRegister', { register, data })
      });

      // persiste offline
      if (offlineReference) {
        const { id: idReference, collection: collectionReference, gpEmpresa: geReference } = offlineReference
        const collectionOffline = `${collectionReference}${geReference ? gpEmpresa : ''}`
        const reference = JSON.parse(localStorage.getItem(`offline:${collectionOffline}`))
        const documentReference = reference ? reference[idReference] : null
        if (documentReference) {
          documentReference['dependents'] = {...documentReference['dependents'], [collection]: registers }
          reference[idReference] = documentReference
          localStorage.setItem(`offline:${collectionOffline}`, JSON.stringify(reference))
        }
      }
      return registers
    },

    // GET BY ID
    async getById({ commit, dispatch }, { collection, register = collection, id, subCollection, orderSubcollection, setRegister = true, getFilial = true }) {
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      if (getFilial) {
        const filial = await dispatch('getFilial', collection)
        id = filial + id
      }
      collection = collection + gpEmpresa
      let ref = this.$db().collection(collection).doc(id)

      const doc = await ref.get()
      // console.info('getById', collection, register, id, subCollection, setRegister, doc)
      if (!doc.exists) return
      const data = { id: doc.id, ...doc.data() }

      if (subCollection) {
        const refSub = this.$db().collection(collection).doc(id).collection(subCollection)

        data[subCollection] = await dispatch('get', { ref: refSub, orderBy: orderSubcollection, limit: null, setRegister: false })
      }

      if (register && setRegister) commit('setRegister', { register, data })
      return data
    },

    async sendStack({commit, dispatch}) {
      const stackOffline = JSON.parse(localStorage.getItem('offline:stack'))
      let stackNew = {}

      for (const i in stackOffline) {
        stackNew = {...stackNew, [i]: []}
        for (const stack of stackOffline[i]) {
          const {integration, collection, collectionDetails, item, itemsDetails, gpEmp, fil, gpEmpDetails, filDetails, register} = stack

          // console.info('i', i);
          // console.info('stackOffline[i]', stackOffline[i]);
          // console.info('stack', stack)
          const isUpdate = item.lifeControl && item.lifeControl.createdAt

          const itemId = item.id
          try {
            if(!isUpdate) delete item.id

            const result = await dispatch('save', {collection, collectionDetails, integration, item, itemsDetails, gpEmp, fil, gpEmpDetails, filDetails})
            // console.info('result', result);
            if (!isUpdate && result && result.status == 200) {
              commit('removeRegister', { register, data: {id: itemId} })
            }
          } catch (error) {
            console.error(`register: ${register}, collection: ${collection}, id: ${itemId} .::. Error:  ${error}`)
            stackNew[i].push(stack)
          }
        }
      }
      // console.info('stackNew', stackNew);
      localStorage.setItem('offline:stack', JSON.stringify(stackNew))
    },

    async saveOffline({commit, dispatch}, { integration, collection, collectionDetails, item, itemsDetails, register }) {
      const gpEmp = await dispatch('getGpEmpresa', collection)
      const fil = await dispatch('getFilial', collection)
      let stackOffline = JSON.parse(localStorage.getItem('offline:stack'))
      let stackIntegration = stackOffline && stackOffline[collection] ? stackOffline[collection] : []
      
      let gpEmpDetails
      let filDetails

      if (collectionDetails) {
        gpEmpDetails = await dispatch('getGpEmpresa', collectionDetails)
        filDetails = await dispatch('getFilial', collectionDetails)
      }

      item.id = item.id || (new Date()).getTime()

      stackIntegration.push({integration, collection, collectionDetails, item, itemsDetails, gpEmp, fil, gpEmpDetails, filDetails, register})
      stackOffline = {...stackOffline, [collection]: stackIntegration}
      localStorage.setItem('offline:stack', JSON.stringify(stackOffline))
      const registerOff = {...item, status: 'offline', [collectionDetails]: itemsDetails} 
      commit('setRegister', { register, data: registerOff, unshift: true })
    },

    // CRUD
    async save({ commit, dispatch }, { collection, item, notToast = false, integration = false, collectionDetails, itemsDetails, submitToErp, gpEmp = null, fil = null, gpEmpDetails = null, filDetails = null, updateNotificationToken = false }) {
        
      const originalCollection = collection
      if (integration) {
        const gpEmpresa = gpEmp ? gpEmp : await dispatch('getGpEmpresa', collection)
        const filial = fil ? fil : await dispatch('getFilial', collection)
        collection = collection + gpEmpresa
        item.gpEmpresa = gpEmpresa.slice(0, -1)
        item.filial = filial
        item.status = 'Rascunho'
        item.errorErp = ''
      }
      commit('setLoading', { status: true }, { root: true })
      const method = (item.id) ? 'patch' : 'post'
        
      let urlMaster = (integration) ? `/integration` : ``
      urlMaster += (item.id) ? `/${collection}/${item.id}` : `/${collection}`

      return this.$api[method](encodeURI(urlMaster), item, {params:{updateNotificationToken}})
        .then(async res => {
          const item = res.data.data
       
          if (collectionDetails) {
            const gpEmpresa = gpEmpDetails ? gpEmpDetails : await dispatch('getGpEmpresa', collectionDetails)
            const filial = filDetails ? filDetails : await dispatch('getFilial', collectionDetails)
            collectionDetails = collectionDetails + gpEmpresa
            const urlDetails = `/integration/batch/${collection}/${collectionDetails}?set=true`
            itemsDetails = itemsDetails.map((x, ix) => {
              return {
                ...x.data,
                id: res.data.data.id + (ix + 1).toString().padStart(4, '0'),
                idParent: res.data.data.id,
                gpEmpresa,
                filial
              }
            })
            await this.$api.post(urlDetails, itemsDetails)
          }

          // TODO Alterar para transação
          if (submitToErp) {
            item.submitToErp = true
            item.status = 'Pronto para o envio'
            item.bloqueado = true
            await this.$api.patch(`/integration/${collection}/${item.id}`, item)
          }
          
          if (!notToast) {
            let message = 'Registro Salvo!'

            if (integration) {
              const _integration = this.$integrations.find(x => x.collection === originalCollection)
              if ( (method == 'patch' && _integration && _integration.editSuccessMessage)
                || (method == 'post'  && _integration && _integration.registerSuccessMessage) ) {
                try {
                  /**
                   * @TODO melhorar a segurança do eval.
                   * está permitindo acesso a todo o escopo do código e a execução de qualquer código,
                   * sendo assim uma falha de segurança.
                   */
                  message = method == 'patch' ? eval(_integration.editSuccessMessage) : eval(_integration.registerSuccessMessage)
                } catch (err) {
                  console.error('save: Error on eval successMessage', err)
                }
              }
            }

            // this.$toast.success(message, { position: 'top-right' })
            dispatch('showToast', {message , color: 'success'})
          }

          return res
        })
        .catch(error => { throw (error.response && error.response.data) ? error.response.data.error : error })
        .finally(() => commit('setLoading', { status: false }, { root: true }))
    },
    async remove({ commit, dispatch }, { collection, item, integration = false }) {
      commit('setLoading', { status: true }, { root: true })
      const gpEmpresa = await dispatch('getGpEmpresa', collection)
      const originalCollection = collection
      collection = collection + gpEmpresa
      let url = (integration) ? `/integration` : ``
      url += `/${collection}/${item.id}`
      if (item.id) {
        return this.$api.delete(url)
          .then(res => {
            commit('setRecentRemove', true)
            let message = 'Registro Deletado!'
            if (integration) {
              const _integration = this.$integrations.find(x => x.collection === originalCollection)
              if (_integration.deleteSuccessMessage) {
                try {
                  /**
                   * @TODO melhorar a segurança do eval.
                   * está permitindo acesso a todo o escopo do código e a execução de qualquer código,
                   * sendo assim uma falha de segurança.
                   */
                  message = eval(_integration.deleteSuccessMessage)
                } catch (err) {
                  console.error('save: Error on eval successMessage', err)
                }
              }
            }
            // this.$toast.success(message, { position: 'top-right' })
            dispatch('showToast', {message, color: 'success'})
            return res
          })
          .catch(error => {
            console.error(error)
            return error
          })
          .finally(() => commit('setLoading', { status: false }, { root: true }))
      }
    },
    async calculaImpostos({ dispatch, rootState }, { item, itemsDetails, collectionDetails }) {
      if (collectionDetails) {
        const gpEmpresa = await dispatch('getGpEmpresa', collectionDetails)
        const filial = await dispatch('getFilial', collectionDetails)
        collectionDetails = collectionDetails + gpEmpresa
        item.GPEMPRESA = gpEmpresa.slice(0, -1)
        item.FILIAL = filial
        item[collectionDetails] = []
        for (const x of itemsDetails) { item[collectionDetails].push({ ...x.data, GPEMPRESA: gpEmpresa, FILIAL: filial }) }
      }
      //TODO Arrumar limpeza do item
      delete item.DA0
      delete item.SA1
      delete item.SA3
      delete item.SA4
      delete item.SE4
      delete item._user
      return this.$api.post('/protheus/calculaImpostos', item, { duration: rootState.protheus.timeout, noToast: true })
        .then(res => res.data.data)
        .catch(error => error)
    },
    async getDashboardRequest({ dispatch, rootState }, { resource, filters, preFilter, filterExtras }) {
      try {
        const { data } = await this.$api.post('/protheus/request', {resource, filters, preFilter, filterExtras}, { timeout: rootState.protheus.timeout, noToast: true })        
        return data
      } catch (error) {
        console.error(error)
        return error
      }
    },
    removeBundle({ commit }, { collection, itens }) {
      const ids = itens.map(x => x.id)
      commit('setLoading', { status: true }, { root: true })
      if (itens.length > 0) {
        return this.$api.patch(`/${collection}/remove`, ids)
          .then(res => {
            // this.$toast.success('Registros Deletados!', { position: 'top-right' })
            dispatch('showToast', {message, color: 'success'})
            return res
          })
          .catch(error => {
            console.error(error)
            return error
          })
          .finally(() => commit('setLoading', { status: false }, { root: true }))
      }
    },
    async count({ state, commit }, { collection }) {
      const snapshot = this.$db()
        .collection('counts')
        .doc(collection)
        .collection('shards')
        .onSnapshot(snapshot => {
          let count = 0;
          for (const doc of snapshot.docs) {
            count += doc.get('count');
          }
          commit('setCounter', { collection, count })
        })

      // Grava listener para encerramento posterior
      if (state.observers['counts'].snapshots && state.observers['counts'].snapshots.length > 0) { state.observers['counts'].snapshots.push(snapshot) }
      else { state.observers['counts'].snapshots = [snapshot] }
      // store.$set(state.observers['counts'], 'snapshots', state.observers['counts'].snapshots)
      state.observers['counts']['snapshots'] = state.observers['counts'].snapshots
    },
    import({ commit }, { collection, item }) {
      commit('setLoading', { status: true }, { root: true })

      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      const payload = new FormData();
      for (const key in item) {
        switch (key) {
          case 'file':
            payload.append(key, item[key])
            break;
          default:
            payload.append(key, stringify(item[key]))
            break;
        }
      }

      const method = 'post'
      const url = `/${collection}/import`
      return this.$api[method](url, payload, options)
        .then(res => {
          const { data } = res.data
          // this.$toast.success(data.message, { position: 'top-right' })
          dispatch('showToast', {message, color: 'success'})
          return res
        })
        .catch(error => { throw error.response.data.error || error.response.data })
        .finally(() => commit('setLoading', { status: false }, { root: true }))
    },
    async searchAlgolian({ state, dispatch, rootState }, { index, query, facetFilters = [], restrictSearchableAttributes = [], attributesToRetrieve = ['*'], attributesToHighlight = [], hitsPerPage = 1000 }) {
      
      if (state.counters.searchAlgolian > (rootState.algolia.searchLimit)) {
        // this.$toast.error('A consulta não pode ser concluída pois o limite de consultas do algolia já foi alcançado.')
        dispatch('showToast', {message: 'A consulta não pode ser concluída pois o limite de consultas do algolia já foi alcançado.', color: 'danger'})
        return { hits: [] }
      } 

      const compareFilters = (last, actual) => last.length == actual.length && actual.every((arr, idx) => arr.length == last.length && arr.every((str, idx2) => str == last[idx][idx2]))
      if (!rootState.algolia || (state.lastAlgoliaReqParams.query == query && compareFilters(state.lastAlgoliaReqParams.facetFilters, facetFilters))) {
        console.warn('searchAlgolian: prevent searching the same query as last one.')
        return { hits: [] }
      }
      state.lastAlgoliaReqParams = { query, facetFilters }

      // PREPARE ENVIROMENT
      const gpEmpresa = await dispatch('getGpEmpresa', index)
      const filial = await dispatch('getFilial', index)

      // FILTERS
      const integration = this.$integrations.find(x => x.collection === index)
      if (integration) {
        let { filters } = integration
        if (filters) filters = await dispatch('mountedFilters', { filters: JSON.parse(filters), register: index })
        if (!filters) return

        for (const { key, operator, value } of filters) {
          facetFilters.push([`${key}:${value}`])
        }

        if (filial) facetFilters.push([`filial:${filial}`])

      } else {
        console.warn(`searchAlgolian: Couldn't find integration '${index}'.`)
      }

      // collection = collection+gpEmpresa

      const modifiedIndex = index + gpEmpresa
      const client = algoliasearch(rootState.algolia.appId, rootState.algolia.searchApiKey);
      const ix = client.initIndex(modifiedIndex)
      // dispatch('cacheOffline', {collection: modifiedIndex})
      return ix.search(query, {
        'facetFilters': facetFilters,
        'restrictSearchableAttributes': restrictSearchableAttributes,
        'attributesToRetrieve': attributesToRetrieve,
        'attributesToHighlight': attributesToHighlight,
        'hitsPerPage': hitsPerPage
      })
        .then(result => {
          // console.info(index, modifiedIndex, query, result, facetFilters, restrictSearchableAttributes, attributesToRetrieve, attributesToHighlight, hitsPerPage)
          this.$api.put('/counters/searchAlgolian', {}, {})
            .then(res => res)
            .catch(error => { throw error.response.data.error || error.response.data })
          return { index, modifiedIndex, ...result }
        })
        .catch(e => e)
    },
    async cacheOffline({dispatch}, {collection, offlineReference}) {
      try {
        const gpEmpresa = await dispatch('getGpEmpresa', collection)
        collection = collection + gpEmpresa
        let refOffline = this.$db().collection(collection)
        refOffline = refOffline.where('offline', '==', true)
        refOffline = refOffline.where('lifeControl.deletedAt', '==', null)
        const registerSnapshotOffline = await refOffline.get()
        let registersOffline = {}
        registerSnapshotOffline.forEach(doc => {
          const data = { id: doc.id, ...doc.data() }
          registersOffline = {...registersOffline, [doc.id]: data}
        });

        localStorage.setItem(`offline:${collection}`, JSON.stringify(registersOffline))
        return registersOffline
      } catch(e) {
        console.error(e)
        return null
      }
    },
    async post ({}, { url, data = null}) {
      try {
        return await this.$api.post(url, data)
      } catch (error) {
        console.error(error)
        return error
      }
    },
    async getApi({}, { url, options, generic}) {
      try {
        if (generic) {
          url = `/protheus/genericRequest`
          if (options && options.params) 
            options.params = {...options.params, ...generic} 
          else 
            options = {params: generic}
        }

        const result = await this.$api.get(url, options)
        return result.data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async showToast({}, {message, color, duration = 2000, position = 'bottom', animated = true}) {
      const toast = await toastController
        .create({
          message,
          duration,
          color,
          position,
          animated
        })
      await toast.present();
    },

    // GET EXTERNAL REQUEST
    async getExternal({ commit, dispatch, rootState }, { register, query, ordem, idField, id, search, fieldsToSearch, fieldsToMark = [], filters, setRegister = true, page = 1, perPage = 45, nextPage = false, filtersIn = [], allRegs = false, async = false }) {
      const gpEmpresa = await dispatch('getGpEmpresa', 'e')
      const filial = await dispatch('getFilial', 'e')
      const user = rootState.auth.user // Disponivel para Filtro
      const searchred = (search && fieldsToSearch && fieldsToSearch.length > 0)
      const filtered = (filters && filters.length > 0)
      const filteredIn = (filtersIn && filtersIn.length > 0 && filtersIn.filter(f => f.selecteds.length > 0))

      if (!allRegs) {
        commit('resetRegister', `${register}FilteredAll`)
        commit('resetRegister', `${register}All`)
      }

      if (!nextPage && !id) {
        commit('resetRegister', `${register}Filtered`)
        commit('setCounter', {counter: `${register}Filtered`, count: 0})
      }
      if (page == 1 && !async) commit('setLoading', { status: true }, { root: true })

      let querySearch = ''
      if (searchred) {
        querySearch += ' AND ('
        for (const att of fieldsToSearch) {
          querySearch += `UPPER(${att}) LIKE '%${search.toUpperCase()}%' OR `
        }
        querySearch = querySearch.slice(0, -3)+')'
      }

      let queryFilter = ''
      if (filtered) {
        for (const values of filters) {
          for (let value of values) {
            value = value.split(':')
            queryFilter += `AND ${value[0]} = '${value[1]}'`
          }
        }
      }

      let queryFilteredIn = ''
      if (filteredIn) {
        const queryFiltersIn = filtersIn.map((f, index) => {
          const {field, selecteds, orAnd = 'AND'} = f
          const values = selecteds && selecteds.length > 0 ? selecteds.map(s => `'${s.split('<em>').join('').split('</em>').join('')}'`).join(",") : "''"
          if (typeof field === 'string')
            return  `${field} IN (${values}) `
          else 
            return `(${field.map(f => `${f} IN (${values})`).join(` ${orAnd} `)})`
        }).join(' AND ')
        queryFilteredIn += ` AND ${queryFiltersIn} `
      }

      if (query.includes('${')){ 
        const queryEval = eval(query)
        if (typeof queryEval === 'string') query = queryEval + querySearch + queryFilter + queryFilteredIn
        else if (typeof queryEval === 'function') query = queryEval({querySearch, queryFilter, queryFilteredIn, user})
      }

      if (id) query = `SELECT * FROM (${query}) AS SQL WHERE ${idField} = '${id}' `

      if (async) {
        try {
          const res = await this.$api.post('/protheus?path=restqry', {
            "PAGINA": page,
            "PORPAGINA": allRegs ? 99999999999 : perPage,
            "QUERY": query,
            "ORDEM": ordem
          }, {noToast: true})
          if (!res || !res.data || !res.data) return []
          let {PROXIMO, TOTAL, RETORNOS} = res.data
          if (register && setRegister) {
            commit('setCounter', {counter: (searchred || filtered || filteredIn) ? `${register}Filtered` : register, count: TOTAL})
  
            for (const el of RETORNOS) {
              el.id = el[idField]
              if (searchred) {
                for (const att of fieldsToSearch) {
                  if (el[att]) {
                    el[att] = el[att].replace(search.toUpperCase(), `<em>${search.toUpperCase()}</em>`)
                  } else {
                    const fieldMarked = fieldsToMark.find(fm => fm.searchableContent === att)
                    if (fieldMarked)
                    el[att] = el[fieldMarked.value].replace(search.toUpperCase(), `<em>${search.toUpperCase()}</em>`)
                  }
                }
              }
  
              commit('setRegister', { register: (searchred || filtered || filteredIn) ? `${register}Filtered${allRegs?'All':''}` : `${register}${allRegs?'All':''}`, data: el })
            }
          }
          RETORNOS = this.$_.cloneDeep(RETORNOS)
          return (id) ? RETORNOS[0] : RETORNOS
        } catch (error) {
          if (error && error.response && error.response.status) {
            console.error('error', error.response.status, error.response.data.error)  
          } else {
            console.error('error', error)  
          }
          return error
        }
      } else {

        // console.info(register, ordem, idField, id, search, fieldsToSearch, filters, setRegister, page, perPage, nextPage, query)
        return this.$api.post('/protheus?path=restqry', {
          "PAGINA": page,
          "PORPAGINA": allRegs ? 99999999999 : perPage,
          "QUERY": query,
          "ORDEM": ordem
        }, {noToast: true})
        .then(res => {
          if (!res || !res.data || !res.data) return []
          let {PROXIMO, TOTAL, RETORNOS} = res.data
          if (register && setRegister) {
            commit('setCounter', {counter: (searchred || filtered || filteredIn) ? `${register}Filtered` : register, count: TOTAL})
  
            for (const el of RETORNOS) {
              el.id = el[idField]
              if (searchred) {
                for (const att of fieldsToSearch) {
                  if (el[att]) {
                    el[att] = el[att].replace(search.toUpperCase(), `<em>${search.toUpperCase()}</em>`)
                  } else {
                    const fieldMarked = fieldsToMark.find(fm => fm.searchableContent === att)
                    if (fieldMarked) el[fieldMarked.value] = el[fieldMarked.value].replace(search.toUpperCase(), `<em>${search.toUpperCase()}</em>`)
                  }
                }
              }
  
              commit('setRegister', { register: (searchred || filtered || filteredIn) ? `${register}Filtered${allRegs?'All':''}` : `${register}${allRegs?'All':''}`, data: el })
            }
          }
          
          RETORNOS = this.$_.cloneDeep(RETORNOS)
          return (id) ? RETORNOS[0] : RETORNOS
        })
        .catch(error => {
          if (error && error.response && error.response.status) {
            console.error('error', error.response.status, error.response.data.error)  
          } else {
            console.error('error', error)  
          }
          return error
        })
        .finally(() =>     commit('setLoading', { status: true }, { root: true }))
      }
    },
  }
}

export default register