<template>
  <ion-row>
    <ion-col class="col-filter">
      <!-- Search --> 
      <ion-searchbar 
        v-model="searchInput.value"
        label="Digite para pesquisar"
        @change="inputingSearch">
      </ion-searchbar>

    </ion-col>
  </ion-row>

  <ion-row>
    <!-- Filters -->
    <ion-col 
      class="col-filter"
      v-for="filter in filters"
      :key="filter.value"
      >
      <!-- <ion-label v-if="filter.filterType == 'switch'">
      </ion-label> -->
      <ion-button
        v-if="filter.filterType == 'switch'"
        size="small" 
        expand="full"
        :fill="!filtersModelsData[filter.value] ? 'outline' : 'solid'"
        :color="filtersModelsData[filter.value] ? 'success' : 'secondary'"
        @click="filtersModelsData[filter.value] = !filtersModelsData[filter.value]"
        v-show="!filter.hide">
        <ion-icon v-if="filtersModelsData[filter.value]" :icon="checkmarkCircle"></ion-icon>  
        {{filter.filterLabel || filter.text}}
      </ion-button>
      <ion-button
        v-else 
        expand="full"
        :fill="!filtersModelsData[filter.value] || filtersModelsData[filter.value].length === 0 ? 'outline' : 'solid'"
        :color="filtersModelsData[filter.value] && filtersModelsData[filter.value].length > 0 ? 'success' : ''"
        @click="showAlert({ filter })" 
        size="small">
        <ion-badge color="warning" v-if="filtersModelsData[filter.value] && filtersModelsData[filter.value].length > 0">
          {{ filtersModelsData[filter.value].length }}
        </ion-badge>
        {{ filter.filterLabel || filter.text }}
      </ion-button>
    </ion-col>
  </ion-row>

  <!-- Limpar Filtros -->
  <ion-row v-if="filters && filters.filter(f => !f.hide).length > 0">
    <ion-col size="12" class="col-filter">
      <ion-button  
        fill="outline"
        color="warning"
        @click="clearFilters" size="small" expand="full">
        <ion-icon :icon="closeOutline"></ion-icon>
        Limpar Todos os Filtros
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
  import { 
    closeOutline,
    checkmarkCircle } from "ionicons/icons";
  import {
    alertController,
    isPlatform,
    // IonLabel,
    IonRow,
    IonBadge,
    IonButton,
    IonCol,
    IonSearchbar,
    IonIcon,
    // IonToggle
  } from '@ionic/vue'
  export default {
    emits: ['searching', 'filtersChange'],
    props: ['filters', 'filtersModels', 'search'],
    components: {
      // IonLabel,
      IonButton,
      IonRow,
      IonCol,
      IonBadge,
      // IonChip,
      // IonSelect,
      // IonSelectOption,
      IonSearchbar,
      IonIcon,
      // IonToggle
    },
    setup() {
      return {
        closeOutline,
        checkmarkCircle
      };
    },
    data() {
      return {
        searchInput: {
          value: ''
        },
        filtersModelsData: {}
      }
    },
    created() {
      this.searchInput = this.search
      this.filtersModelsData = this.filtersModels
    },
    watch: {
      filtersModelsData(newValue) {
        this.$emit('filtersChange', newValue)
      }
    },
    methods: {
      inputingSearch(event) {
        this.$emit('searching', event.target.value)
      },
      async showAlert({filter}) {
        let buttons = [
          {
            text: 'Todos',
            icon: 'checkbox-outline',
            cssClass: 'all-none-button',
            handler: () => {

              // check all checkboxes
              alert.inputs = alert.inputs.map((checkbox) => {
                checkbox.checked = true;
                return checkbox;
              });

              return false;
            }
          }, 
          {
            text: 'Nenhum',
            cssClass: 'all-none-button',
            handler: () => {
              // uncheck all checkboxes
              alert.inputs = alert.inputs.map((checkbox) => {
                checkbox.checked = false;
                return checkbox;
              });

              return false;
            }
          }, 
          {
            text: 'OK',
            handler: (data) => {
              // handle the data returned from the alert here
              this.filtersModelsData[filter.value] = data
            }
          }, 
          {
            text: 'Cancelar',
            role: 'cancel',
          }
        ];

        // adjust button order in four button layout for ios
        if (isPlatform('ios')) {
          const okButton = { ...buttons[2] };
          const cancelButton = { ...buttons[3] };
          buttons = [buttons[0], buttons[1], cancelButton, okButton];
        }

        const alert = await alertController.create({
          header: filter.filterLabel || filter.text,
          inputs: filter.filterItems.map(item => ({
            label: item, 
            value: item, 
            type: 'checkbox', checked: this.filtersModelsData[filter.value] && this.filtersModelsData[filter.value].find(f => f === item)
          })),
          cssClass: 'four-button-alert',
          buttons: [...buttons]
        });

        await alert.present();
      },

      clearFilters({invalidItem = false}) {
        this.searchInput.value = ''
        Object.entries(this.filtersModelsData).forEach(([key,val]) => {
          if (typeof val == 'boolean')
            this.filtersModelsData[key] = false
          else if (Array.isArray(val))
            this.filtersModelsData[key].splice(0)
          else
            console.warn('unknown filter type', key, val)
        })
        this.filtersModelsData['invalidItem'] = invalidItem
      },
    }
  }
</script>

<style>
  .four-button-alert.md .alert-button-group-vertical {
    display: block;
  }
  .four-button-alert.md .alert-button-group-vertical * {
    margin: 0;
    font-size: .9em;
  }
  .four-button-alert.md .alert-button-group-vertical button:nth-of-type(1), 
  .four-button-alert.md .alert-button-group-vertical button:nth-of-type(2) {
    float: left;
    color: var(--ion-color-medium);
  }

  .four-button-alert.md .alert-button-group-vertical button:nth-of-type(3), 
  .four-button-alert.md .alert-button-group-vertical button:nth-of-type(4) {
    float: right;
  }

  .four-button-alert.ios .alert-button-group-vertical {
    flex-direction: row !important;
  }
  .four-button-alert.ios .alert-button-group-vertical button:nth-of-type(1) {
    color: var(--ion-color-medium);
  }
  .four-button-alert.ios .alert-button-group-vertical button:nth-of-type(2) {
    color: var(--ion-color-medium);
    border-right: none;
  }
  .col-filter *:not(ion-icon,ion-badge) {
    padding: 0;
    font-size: .6em;
  }
  ion-searchbar * {
    padding: 0;
    font-size: .6em;
  }
</style>