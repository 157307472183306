<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-img :src="logoAsset" :style="{width: '50%'}" />
          <ion-card :style="{'max-width': '75%', margin: '20px 0'}">
            <ion-card-header>
              <ion-card-title>
                Login
              </ion-card-title>
              <ion-card-subtitle>
                Insira suas credenciais
              </ion-card-subtitle>
            </ion-card-header>

            <ion-card-content>
              <Form @submit="signin">
                <ion-item>
                  <ion-label position="floating">Email</ion-label>
                  <Field name="email" v-slot="{field}" :rules="isRequired">
                    <ion-input v-bind="field" @IonChange="user.email = $event.target.value" :rules="rules.email" label="E-mail" required />
                  </Field>
                </ion-item>
                <ErrorMessage name="email_error"></ErrorMessage>
                <ion-item>
                  <ion-label position="floating">Senha</ion-label>
                  <Field name="password" v-slot="{field}" :rules="isRequired">
                    <ion-input v-bind="field" @IonChange="user.password = $event.target.value" :rules="rules.password" label="Senha" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" required />
                  </Field>
                </ion-item>
                <ErrorMessage name="password_error"></ErrorMessage>
              </Form>

              <ion-button type="submit" expand="block" :style="{marginTop: '30px'}" color="light" @click="signin">
                Entrar
              </ion-button>
              
              <!-- <v-alert
                :value="res.value"
                :type="res.type"
                :icon="res.icon"
                text
                dense
                dismissible
                transition="scale-transition"
                :style="{display: 'block', 'text-align': 'center', marginTop: '15px'}"
              >{{res.content}}</v-alert> -->
              <ion-button fill="celar" expand="block" :style="{marginTop: '30px'}">
                Recuperar Senha
              </ion-button>
            </ion-card-content>
          </ion-card>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
//import Header from "@/components/Auth/Header.vue";
// import Background from "../../components/Auth/Background.vue";
import {
  IonPage,
  IonContent,
  IonButton,
  IonCard,
  IonLabel,
  IonCardHeader, 
  IonCardContent, 
  IonInput, 
  IonCardTitle, 
  IonCardSubtitle, 
  // IonContent, 
  IonItem,
  // IonCol,
  IonRow,
  IonImg,
  IonGrid,
} from "@ionic/vue";
import {createNamespacedHelpers, mapMutations} from "vuex";
import {Form, Field, ErrorMessage} from "vee-validate/dist/vee-validate";

const {mapActions: authActions} = createNamespacedHelpers("auth");
export default {
  name: "SignIn",
  components: {
    // Background,
    IonPage,
    IonContent,
    IonButton,
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    // IonContent,
    IonLabel,
    IonCardHeader,
    IonCardContent,
    IonInput,
    IonItem,
    // IonCol,
    IonRow,
    IonImg,
    IonGrid,
    Form,
    Field,
    ErrorMessage
  },
  data: () => ({
    credentials: {},
    logging: false,
    valid: true,
    res: {
      value: false,
      type: "info",
      icon: null,
      content: "",
    },
    user: {},
    rules: {
      email: [(v) => !!v || "Informe o e-mail", (v) => /.+@.+\..+/.test(v) || "Informe um e-mail válido"],
      password: [(v) => !!v || "Informe a senha"],
    },
    showPassword: false,
    logoAsset: '',
  }),
  methods: {
    ...mapMutations(["setLoading"]),
    ...authActions(["signIn"]),

    signin() {
      this.setLoading({stats: true, message: "Validando acesso"})
      this.signIn(this.user)
        .then((res) => {
          if (!res.error) {
            this.res = {
              value: true,
              type: "success",
              icon: "mdi-check-circle-outline",
              content: "Login efetuado!",
            };
          } else {
            this.res = {
              value: true,
              type: "error",
              icon: "mdi-alert-outline",
              content: res.error,
            };
          }
          this.setLoading({stats: false});
        })
        .catch((err) => {
          console.error(err);
          this.res = {
            value: true,
            type: "error",
            icon: "mdi-alert-outline",
            content: "Erro interno, favor contactar o administrador.",
          };
          this.setLoading({stats: false});
        });
    },
  },
  created() {
    this.setLoading({stats: false});

    let theme = window.matchMedia('(prefers-color-scheme: dark)')
    
    this.logoAsset = theme.matches ? require("@/assets/logo.png") : require("@/assets/logo_dark.png")

    theme.addListener((mediaQuery) => {
      this.logoAsset = mediaQuery.matches ? require("@/assets/logo.png") : require("@/assets/logo_dark.png")
    })
  },
  setup() {
    const onSubmit = (data) => {
    };

    const isRequired = (value) => {
      if (!value) return "Is a required field";

      return true;
    };

    return {
      onSubmit,
      isRequired,
    };
  },
};
</script>
<style scoped>
  ion-content{
    padding: 20px
  }
</style>
