<template>
    <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
</template>

<script>
import { IonRefresher, IonRefresherContent } from '@ionic/vue'
import { createNamespacedHelpers } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')

export default{
  name: 'Refresher',
  props: {
    myObserver: {
        collection: "string", 
        register: "string", 
        filters: "string",
        orderBy: "string",
        details: "string"
    }
  },
  components: {
      IonRefresher, 
      IonRefresherContent
  },
  computed: {
  },
  methods: {
    ...registersActions(["observer"]),
    doRefresh(event) {
      this.observer(this.myObserver)
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    }
  }
}
</script>
