let hostname = window.location.hostname.replace('.yama.com.br', '')
const baseUrl = window.location.origin
let baseApiUrl = ''

switch (hostname) {
  case 'localhost':
    baseApiUrl = 'http://localhost:5000'
    break;

  case 'm.dev-vendas':
  case 'mobile-yama.ufsales.com.br':
    baseApiUrl = 'https://api-dev-vendas.yama.com.br'
    break;

  default:
    baseApiUrl = `https://api-vendas.yama.com.br`
    break;
}

export {baseUrl, baseApiUrl}