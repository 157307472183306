import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";
import { Network } from "@capacitor/network"

import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import firebaseConfig from "./configs/firebase-config.json";

import setProperties from "./plugins/vueProperties";
import Vue3NativeNotification from 'vue3-native-notification'

import "@firebase/auth";
import "@firebase/firestore";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import store from "./store";

Network.addListener('networkStatusChange', status => {
  document.dispatchEvent(
    new CustomEvent('swOffline', { detail: status })
  )
});

window.addEventListener("load", () => {
  if (navigator.onLine) {
    document.dispatchEvent(
      new CustomEvent('swOffline', { detail: { connected: true} })
    )
  } else {
    document.dispatchEvent(
      new CustomEvent('swOffline', { detail: { connected: false} })
    )
  }
});

import runtime from "serviceworker-webpack-plugin/lib/runtime";
if ("serviceWorker" in navigator && !process.env.VUE_APP_LOCAL) {
  runtime
    .register()
    .then(() => {
      console.info('[Service Worker] Registered')
    })
    .catch(err => {
      console.error('[Service Worker] Registration failed: ', err)
    })
}

const app = createApp(App);

app.use(store);
app.use(IonicVue);
app.use(Vue3NativeNotification, {requestOnNotify: true,})

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

//HABILITANDO CACHE DE REQUISIÇÕES DO FIREBASE PARA USO OFFLINE
firebase.firestore().enablePersistence()
  .then(() => {
  })
  .catch((err) => {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });

store.$appConfig = firebaseConfig;
store.$auth = firebase.auth;
store.$db = firebase.firestore;
store.$messaging = (firebase.messaging.isSupported()) ? firebase.messaging() : null;

// Gravando evento de instalação para criar botão instalação no ModalUser
window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault()
  store.$deferredPrompt = e
  return false
});

/* Vue Properties */
setProperties();

/**
 * Chamada de integrações
 */

/**
 * Definição das rotas de cada integração cadastrada na collection integrations
 */
const integrations = new Promise((resolve, reject) => {
  firebase
    .firestore()
    .collection("integrations")
    .where("lifeControl.deletedAt", "==", null)
    .where("active", "==", true)
    .orderBy("menuOrder")
    .get()
    .then(function(querySnapshot) {
      let integrations = [];
      for (const doc of querySnapshot.docs) {
        const integration = { id: doc.id, ...doc.data() };
        if (integration.active) integrations.push(integration);
      }
      resolve(integrations);
    })
    .catch((e) => reject(e));
});

const userRules = new Promise((resolve, reject) => {
  firebase
    .firestore()
    .collection("userRules")
    .where("lifeControl.deletedAt", "==", null)
    .where("active", "==", true)
    .get()
    .then(function(querySnapshot) {
      let userRules = [];
      for (const doc of querySnapshot.docs) {
        const userRule = { id: doc.id, ...doc.data() };
        if (userRule.active) userRules.push(userRule);
      }
      resolve(userRules);
    })
    .catch((e) => reject(e));
});

const reports = new Promise((resolve, reject) => {
  firebase.firestore().collection('reports')
  .where('lifeControl.deletedAt', '==', null)
  .where('active', '==', true)
  .get()
  .then(function (querySnapshot) {
    let reports = []
    for (const doc of querySnapshot.docs) {
      const report = { id: doc.id, ...doc.data() }
      if (report.active)
      reports.push(report)
    }

    resolve(reports)
  })
  .catch(e => reject(e))
});

const courses = new Promise((resolve, reject) => {
  firebase.firestore().collection('courses')
  .where('lifeControl.deletedAt', '==', null)
  // .where('active', '==', true)
  .get()
  .then(function (querySnapshot) {
    let courses = []
    for (const doc of querySnapshot.docs) {
      const report = { id: doc.id, ...doc.data() }
      // if (courses.active)
      courses.push(report)
    }

    resolve(courses)
  })
  .catch(e => reject(e))
});


Promise.all([integrations, userRules, reports, courses])
  .then((values) => {

    store.$integrations = values[0];
    store.$userRules = values[1];
    let customRouter = router(values[0], values[1], values[2], values[3])
    store.$router = customRouter
    app.use(customRouter)
    customRouter.isReady().then(() => {
      app.mount("#app");
    });
  })
  .catch((err) =>
    console.error("Error getting integrations and userRules: ", err)
  );
