<template>
  <ion-searchbar 
    placeholder="Pesquisar"
    @ionInput.prevent="onInputChange($event)"/>
</template>
<script>
import { IonSearchbar } from "@ionic/vue"
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: 'VTextFieldExternal',
  emits: ['click:clear', 'keydown', 'input'],
  components:{
    IonSearchbar
  },
  props: {
    externalRequest: {
      type: Object,
      required: true
    },

    /**
     * Função de tratamento da requisição da busca.
     * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
     */
    handler: {
      type: Function,
      required: false
    },

    /**
     * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
     */
    delay: {
      type: Number,
      required: false,
      default: () => 0
    },

    filters: Array,
    filtersIn: Array,
    fieldsToSearch: Array,
    fieldsToMark: Array,
    register: String,
  },
  data() {
    return {
      timer: null,
    }
  },
  methods: {
    ...registersActions(['getExternal']),
    onInputChange($event){
      if (this.delay)
        this.timeoutSearch($event.target.value, this.filters, this.filtersIn)
      else
        this.search($event.target.value, this.filters, this.filtersIn)
    },
    timeoutSearch(query, filters, filtersIn) {
      if (query || filters || filtersIn) {
        if (this.timer)
          clearTimeout(this.timer)
  
        this.timer = setTimeout(() => {
          this.timer = null
          this.handler(this.externalSearch(query, filters, filtersIn))
        }, this.delay)
      }
    },
    search(query, filters, filtersIn) {
      if (query || filters.length > 0 || filtersIn.length > 0)
        this.handler(this.externalSearch(query, filters, filtersIn))
    },
    externalSearch(query, filters, filtersIn) {
      const filtersInSelecteds = filtersIn ? filtersIn.filter(f => {return f.selecteds && f.selecteds.length > 0}) : []
      if (query || (filters && filters.length > 0) || (filtersInSelecteds && filtersInSelecteds.length > 0)) {
        return this.getExternal({
          ...this.externalRequest,
          register: this.register,
          search: query,
          fieldsToSearch: this.fieldsToSearch,
          fieldsToMark: this.fieldsToMark,
          filters,
          filtersIn: filtersInSelecteds
        })
      }
      else return []
    }
  }
}
</script>