<template>
  <!-- <v-overlay :value="loading.stats" z-index=250 class="text-center">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    <h4 class="mt-4">{{loading.message}}</h4>
  </v-overlay> -->
  <ion-loading
    :is-open="loading.stats"
    :message="loading.message || ''"
  >
  </ion-loading>
</template>

<script>
import { IonLoading } from '@ionic/vue'
import { mapState } from 'vuex'
export default {
  name: 'Loading',
  components: {
    IonLoading
  },
  computed: {
    ...mapState(['loading'])
  }
}
</script>
 
<style>

</style>