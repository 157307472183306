<template>
  <ion-page>
    <ion-content>
      <DataTable 
        :items="optDataTable.items"
        :headers="optDataTable.headers"
        :opt="optDataTable"
        :collection="collection"
        :register="register"
        :details="details"
        :claims="computedClaims"
        :blockedField="blockedField"
        :integration="integration"
        :dataFilters="dataFilters"
        :inputsMaster="inputsMaster"
        :myObserver="myObserver"/>
    </ion-content>
  </ion-page>
</template>
<script>
import { 
  IonContent,
  IonPage,
} from '@ionic/vue'
import store from '../../store'
import { createNamespacedHelpers, mapState, mapMutations } from 'vuex'
import DataTable from "../../components/DataTable.vue"
const { mapState:registersState, mapActions:registersActions, mapMutations:registersMutations } = createNamespacedHelpers('registers')

export default {
  name: "List",
  // inheritAttrs: false,
  components: {
    // Refresher,
    DataTable,
    IonContent,
    IonPage,
  },
  props: {
    collection: {
      type: String,
      required: true,
    },
    register: {
      type: String,
      required: true,
    },
    filters: {
      required: false,
    },
    orderBy: {
      required: true,
    },
    details: {
      type: Array,
      required: false,
      default: () => []
    },
    blockedField: String,
    headers: {
      type: Array,
      required: false,
    },
    claims: {
      type: Object,
      required: true,
      default: () => ({
        add: false, 
        view: false, 
        edit: false, 
        delete: false, 
      })
    },
    integration: {
      type: Boolean,
      default: false
    },
    dataFilters: {
      default: () => []
    },
    inputsMaster: {
      type: Array,
      default: () => []
    },
    customActions: Array,
    headersDetails: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...registersActions(['observer']),
    ...mapMutations(['setSyncable']),
    ...registersMutations(['setRegisterActive']),
    initRegisters() {
      this.observer(this.myObserver)
    },
    proccessItem({item, head, copy = false}) {
      let ret = store.$_.cloneDeep(item)
    
      if (!ret) return
      if (
        ret._highlightResult 
        && ret._highlightResult[head.value] 
        && ret._highlightResult[head.value].matchedWords
        && ret._highlightResult[head.value].matchedWords.length > 0
      ) ret = ret._highlightResult
      
      /* NESTED */ 
      const pathItem = head.value.split('.')
      const lenItem = pathItem.length
      if (lenItem == 1) {
        ret = ret[pathItem]
      } else {
        for (let ix = 0; ix < pathItem.length; ix++) {
          const el = pathItem[ix];
          if (ret && typeof ret[el] != 'undefined') { ret = ret[el] }
          else { 
            ret = (head.checkbox) ? false : ''
            break; 
          }
        }
      }

      /* ASSIST */ 
      const detail = head.detail
      if (detail && ret) {
        const pathAssist = detail.split('.')
        const lenAssist = pathAssist.length
        if (Array.isArray(ret)) {
          ret.forEach((el, ix) => {
            let retEl = (this.registers[pathAssist[0]]) ? this.registers[pathAssist[0]].find(x => x.id === el) : null
            if (retEl) {
              for(let i = 1; i < lenAssist; i++) {
                const el2 = pathAssist[i];
                retEl = (retEl) ? retEl[el2] : ''
              }
              ret[ix] = retEl
            }
          })
        } else {
          let retEl = (this.registers[pathAssist[0]]) ?  this.registers[pathAssist[0]].find(x => x.id === ret) : null
          for(let i = 1; i < lenAssist; i++) {
            const el = pathAssist[i];
            retEl = (retEl) ? retEl[el] : ''
          }
          ret = retEl
        }
      }

      /* FORMATS */ 
      if (!ret) return
      if (ret && ret.value) ret = ret.value
      if (head.slice && !copy) { ret = ret.slice(head.slice) }
      if (head.upper) { ret = ret.toUpperCase() }
      if (head.lower) { ret = ret.toLowerCase() }
      if (head.trim) { ret = ret.trim() }
    
      /* FORMAT DATES */
      if (head.date || head.dateHour || head.dateYear || head.datePlusWeek) { 
        if (Object.prototype.hasOwnProperty.call(ret, "toDate")) ret = ret.toDate()
        else if (ret.seconds && ret.nanoseconds) ret = new store.$db.Timestamp(ret.seconds, ret.nanoseconds).toDate() // Retorno via Algolia não contem metodo toDate
      }
      if (head.date) { ret = store.$moment(ret).format("DD/MM/YYYY") }
      if (head.dateHour) { ret = store.$moment(ret).format("DD/MM/YYYY - HH:mm:ss") }
      if (head.dateYear) { ret = store.$moment(ret).format("YYYY") }
      if (head.datePlusWeek) { ret = store.$moment(ret).format("DD/MM/YYYY, dddd") }
   
      // if (options.slice) { obj = (obj) ? obj.slice(options.slice) : '' }
      // if (options.multiplier) { obj = (obj) ? obj * options.multiplier : '' }
      return ret
    },
  },
  computed: {
    ...registersState(['registers']),
    ...mapState(['synchronizing']),
    optDataTable() {
      this.computedClaims
      return {
        // vModel: this.vModel,
        headers: [...this.computedHeaders, /*this.status,*/ ...this.actions],
        items: this.registers[this.register],
        showSelect: true,
        itemKey: 'id',
        serverItemsLength: true,
        dispatch: []
      }
    },
    myObserver() {
      return {
        collection: this.collection, 
        register: this.register, 
        filters: this.filters,
        orderBy: this.orderBy,
        details: this.details
      }
    },
    computedHeaders() {
      return this.inputsMaster.filter(input => input.list).map(x => {
        if (x.markers) { 
          for (const key in x.markers) { 
            if (Object.hasOwnProperty.call(x.markers, key)) {
              x.markers[key.trim().toLowerCase()] = x.markers[key]
            }
          }
          x.markers = {...x.markers, offline: 'offline', 'Sem Status': 'noStatus'}
        }
        return { ...x, text: x.label || x.value }
      })
    },
    status() {
      return { text: 'Sincronia', value: 'data-table-status', align: 'center', tdClass: 'text-center'}
    },
    actions() {
      return [{ text: 'Ações', value: 'data-table-actions', align: 'center', tdClass: 'text-center text-no-wrap' }]
    },
    computedClaims() {
      const json = localStorage.getItem(process.env.VUE_APP_USERKEY)
      const userData = JSON.parse(json)

      let claims = this.claims

      // claims.view = (claims.view && userData?.claims?.admin) || userData?.rules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.view)
      // claims.add = (claims.add && userData?.claims?.admin) || userData?.rules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.add)
      // claims.edit = (claims.edit && userData?.claims?.admin) || userData?.rules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.edit)
      // claims.delete = (claims.delete && userData?.claims?.admin) || userData?.rules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.delete)
      // claims.noFilters = userData?.rules.find(x => x.claims && x.claims[this.register] && x.claims[this.register]?.view)

      return claims
    },
    computedCustomActions() {
      if (!this.customActions)
        return []

      return this.customActions.map(_action => {
        const action = { ..._action }

        if (!action.color)
          action.color = 'primary'

        if (!action.action || action.action.apply)
          action.action = () => console.warn(`missing action function for custom action "${action.text}."`)

        return action
      })
    },
    computedSyncable() {
      return this.inputsMaster.some(im => im.itemsOffline)
    },
    computedMyRoute() {
      return this.$route.path.includes(this.collection)
    }
  },
  watch: {
    $route() {
      if (this.computedMyRoute) {
        this.setRegisterActive({register: this.register, fields: this.inputsMaster})
        this.setSyncable(this.computedMyRoute && this.computedSyncable)
      }
    }
  },
  mounted(){
    // console.info('Firebase cloud messaging object', this.$messaging)
  },
  created() {
    if (this.computedMyRoute) {
      this.setSyncable(this.computedSyncable)
      this.setRegisterActive({register: this.register})
    }
    this.initRegisters()
  }
}
</script>

<style scoped>
  .wrap-text {
    padding-top: 2px;
    padding-bottom: 2px;
    display: block;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ion-col{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .light-blue{
    background-color: #03a9f4;
  }

  .error{
    background-color: #e64a19;
  }

  .deep-orange, .darken-2{
    background-color: #e64a19;
  }

  .yellow{
    background-color: yellow;
  }

  .black{
    background-color:black;
  }

  .deep-purple .lighten-2{
  background-color: purple;
  }

  .green{
    background-color: #4caf50;
  }

  .brown{
    background-color: brown;
  }

  .orange{
    background-color: orange;
  }

  .grey{
    background-color: #9e9e9e;
  }

  .amber{
    background-color: gold;
  }

  .offline{
    background-color: rgb(30, 36, 36);
  }

  .noStatus {
    background: linear-gradient(
      to bottom,
      #5d9634,
      #5d9634 50%,
      #538c2b 50%,
      #538c2b
    );
    /* The rectangle in which to repeat. 
      It can be fully wide in this case */
    /* background-size: 100% 20px; */
  }
  </style>

