<template>
  <ion-app v-if="(collectionStructure && user) || $route.meta.public">
    <loading/>
    <keep-alive>
    <ion-router-outlet />
    </keep-alive>
    <ion-button v-if="showInstallBtn" @click="installPwa()">Instalar App</ion-button>
  </ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet, IonButton, toastController, loadingController}  from "@ionic/vue"
import store from "./store"
import {defineComponent} from "vue";
import Loading from "./components/Loading.vue";
import {createNamespacedHelpers, mapState, mapActions, mapMutations} from "vuex";
const {mapState: authState} = createNamespacedHelpers("auth");
const { mapActions: registersActions } = createNamespacedHelpers("registers")
import offline from './mixins/offline'

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
});
//a
export default defineComponent({
  name: "App",
  components: {
    IonApp, 
    IonRouterOutlet,
    IonButton,
    Loading
  },
  computed: {
    ...mapState(["collectionStructure", "loading"]),
    ...authState(["user"]),
    computedToastUpdateOpen() {
      return this.toastUpdateOpen
    },
    showing() {
      return !this.$route.meta.public && !this.$route.path.includes('cadastrar') && !this.$route.path.includes('detalhes');
    },
  },
  mixins:[offline],
  data() {
    return {
      loadingPresent: null,
      showInstallBtn: false,
      toastUpdateOpen: false
    }
  },
  methods: {
    ...registersActions(["showToast", "sendStack"]),
    ...mapActions(["initSystem"]),
    ...mapMutations(["setLoading", "setOffline"]),
    installPwa(){
      // hide our user interface that shows our A2HS button
      addBtn.style.display = 'none';
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // console.info('User accepted the A2HS prompt');
        } else {
          // console.info('User dismissed the A2HS prompt');
        }
        deferredPrompt = null;
      });
    },
    async openToast() {
      this.toastUpdateOpen = true
      const toast = await toastController
      .create({
        message: 'Nova versão do app está disponível.',
        color: 'success',
        buttons: [
          {
            text: 'Atualizar',
            handler: () => {
              // Here the actual reload of the page occurs
              this.toastUpdateOpen = false;
              toast.dismiss();
              window.location.reload(true)
            }
          }
        ]
      })

      // toast.onDidDismiss(() => {
      //   console.info('Dismissed toast');
      // });
      return toast.present();
    },
    async presentLoading() {
      const { stats, message, duration } = this.loading
      if (!stats) {
        if (this.loadingPresent) {
          this.loadingPresent.dismiss()
          // this.loadingPresent = null
        }
        return
      } else {
        if (message) this.loadingPresent.message = message
        if (duration) this.loadingPresent.duration = duration
        await this.loadingPresent.present()
      }
    }
  },
  watch: {
    isOffline(newValue) {
      if (!newValue) {
        this.sendStack()
      }
      this.setOffline(newValue)
    },
    loading(){
      this.presentLoading()
    }
  },
  async created() {
    this.setOffline(!navigator.onLine)
    this.loadingPresent = await loadingController.create()

    this.initSystem({$router: this.$router});
    
    if (store.$messaging) {
      store.$messaging.onMessage((payload) => {
        // console.info('Message received. ', payload);
        this.notify(payload)
      });
    }

    setTimeout(() => {
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          for (let ix = 0; ix < registrations.length; ix++) {
            if (!!registrations[ix].waiting) {
              console.info('[Service Worker] Unregistering Service Worker...', registrations[ix])
              registrations[ix].unregister()
              this.openToast()
            }
          }
        })
    }, 1000*3);
  },
  setup() {
      const notify = function ({data}) {

        // console.info('data', data);
        this.$notification.show(
          data.title,
          {
            body: data.body,
            icon: './assets/notification_icon.png',
            badge: './assets/notification_badge.png'
          },
          {},
        )
      }

      return{
        notify
      }
    },
});
</script>
