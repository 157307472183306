import _ from 'lodash'
import store from '../store'

const auth = {
  namespaced: true,
  state: {
    user: null,
    observers: {
      user: null,
    },
    vapidKey: null
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
      if (payload) {
        localStorage.setItem(this.$config.userKey, JSON.stringify(payload))
        try {
          this.$api.defaults.headers.common['Authorization'] =  `bearer ${payload.token}`
        } catch( error ) {
          console.error(error);
        }
      } else {
        localStorage.removeItem(this.$config.userKey)
      }
    },
    setVapidKey(state, payload){
      state.vapidKey = payload
    }
  },
  actions: {
    // AUTH Changed
    authChanged ({state, commit, dispatch, rootState}) {
      let once = true
      return this.$auth().onAuthStateChanged(async user => {
        if (user) {
          store.$router.push('/splash')
          await dispatch('getIdToken').then(async idToken => {
            if (state.observers.user) { state.observers.user() }
            state.observers.user = await this.$db().collection('users')
            .doc(user.uid)
            .onSnapshot(async doc => {
                const data = doc.data()
                let payload = {
                  ...user.providerData[0],
                  token: idToken.token,
                  claims: idToken.claims,
                  ...data,
                  uid: doc.id,
                  rules: (data.rules) ? this.$userRules.filter(ur => data.rules.find(uId => uId === ur.id)) : []
                }

                // Get Configs
                await dispatch('getConfigs', {user: payload},  {root: true}) 
                
                // Update User
                await dispatch('updateUser', {user: payload}) 
                
                if(once){
                  // Sincroniza os dados para uso offline
                  await dispatch('registers/syncOffline', {}, {root: true})
         
                  // Get Token to Push Notify
                  dispatch('registerNotificationToken')
                  once = false
                }

                if(['/login', '/splash'].includes(store.$router.currentRoute.value.path)) { store.$router.push('/') }
              })
            })
              
        } else {
          commit('setUser', null)
          commit('registers/resetRegisters', {}, { root: true })
          commit('registers/resetCounters', {}, { root: true })
          dispatch('closeListeners')

          if(store.$router.currentRoute.value.path != '/login') { 
            store.$router.push('/login') 
          }
        }
      })
    },
    async updateUser ({state, commit, dispatch}, {user}) {
      if (!user) user = state.user
      user.SA3 = await dispatch('getSA3', {uid:user.uid})
      // if(user.SA3) user.SA3Subs = await dispatch('getSA3Subs', {A3_COD:user.SA3.A3_COD})
      commit('setUser', user)
    },
    async getSA3 ({dispatch}, {uid}) {
      const collection = 'SA3'
      
      const SA3 = await dispatch('registers/get', { collection, filters: [{key: 'A3_USRPORT', operator: '==', value: uid}], limit: 1, forceFilter: true}, {root: true})
      return (SA3 && SA3.length > 0) ? SA3[0] : null
    },
    async getSA3Subs ({dispatch}, {SA3, isSuperAdmin}) {
      const collection = 'SA3'
      
      if (isSuperAdmin) {
        const allSA3 = await dispatch('registers/get', { collection, limit: 9999, filters: [{key: 'active', operator: '==', value: true}]}, {root: true})
        return allSA3
      }
      else if (!(SA3 && SA3.A3_COD)) return []

      // busque os registros de vendedores em que o usuário logado seja o gerente
      const SA3Geren = await dispatch('registers/get', { collection, limit: 9999, filters: [{key: 'A3_GEREN', operator: '==', value: SA3.A3_COD}]}, {root: true})

      // busque os registros de vendedores em que o usuário logado seja o superior
      const SA3Super = await dispatch('registers/get', { collection, limit: 9999, filters: [{key: 'A3_SUPER', operator: '==', value: SA3.A3_COD}]}, {root: true})

      return [SA3, ...SA3Geren, ...SA3Super]
    },
    async recoverPassword({dispatch}, {user_email}){
      
    },

    async registerNotificationToken({state, dispatch}){
      if (!this.$messaging) return
      let cloudMessaging = await this.$db().collection('_configs').doc("cloudMessaging").get()

      let {vapidKey} = cloudMessaging.data()

      this.$messaging.getToken({ vapidKey })
      .then((currentToken)=>{
        let now = new Date()
        let tokensParaComparar = []

        // console.info('currentToken', currentToken)
        // console.info("state.user.notificationTokens", state.user.notificationTokens)
        
        // listo todos os tokens que o usuário tem registrado e que estão dentro da validade
        let registeredTokens = []
        if (state.user && state.user.notificationTokens) {
          registeredTokens = state.user.notificationTokens.map((notificationToken)=>{
            // calculamos o tempo de validade do token
            let tokenValido = notificationToken ? Math.round((Date.parse(now) - Date.parse(notificationToken.updatedAt))/86400000) <= 60 ? true : false : false
  
            // se o token estiver dentro da validade, ele permanece na lista de tokens de notificação do usuario
            if(tokenValido){
              // com essa lista nós podemos testar se currenttoken já está cadastrado ou não
              tokensParaComparar.push(notificationToken.token)
  
              // atualizamos a validade do token
              notificationToken.updatedAt = now
  
              return notificationToken
            }
          });
        }
    
        if(!tokensParaComparar.includes(currentToken) || (registeredTokens.length === 0)){
          registeredTokens.push({
            token: currentToken,
            createdAt: now,
            updatedAt: now
          })
        }

        dispatch('registers/save', {collection: 'users', item: {id: state.user.uid, email: state.user.email, notificationTokens: registeredTokens}, notToast: true, updateNotificationToken: true}, {root: true})
      })
    },

    // CLOSE LISTENERS
    closeListeners ({state, rootState}) {
      // Auth
      if (state.observers.user) { state.observers.user() }
     
      // Registers
      const registersObservers = rootState.registers.observers
      for (const key in registersObservers) {
        if (Object.prototype.hasOwnProperty.call(registersObservers, key)) {
          const registersSnapshots = registersObservers[key].snapshots
          registersSnapshots.forEach(el => {
            el()
          });
        }
      }
    },

    // SIGN
    signIn ({commit, dispatch}, {email, password}) {
      if (!email || !password) return {error: 'E-mail ou senha não informados!'}
      return this.$auth().signInWithEmailAndPassword(email, password)
        .then(res => {
          commit('setUser', res.user)
          return res.user
        })
        .catch(async err => {
          const message = await dispatch('translateError', err)
          return {error: message}
        });
    },
    translateError({}, err) {
      let message = ''
      switch (err.code) {
        case 'auth/invalid-email':
          message = 'E-mail inválido!'
          break;
        case 'auth/user-disabled':
          message = 'Usuário desabilitado!'
          break;
        case 'auth/user-not-found':
          message = 'Usuário não encontrado!'
          break;
        case 'auth/invalid-action-code':
          message = 'Link de acesso inválido. Link expirado ou já foi usado!'
          break;
        case 'auth/argument-error':
          message = 'E-mail ou senha não informados!'
          break;
        default:
          message = err.message
          break;
      }
      return message
    },
    signOut () {
      this.$auth().signOut()
    },
    getIdToken () {
      return this.$auth().currentUser.getIdTokenResult(/* forceRefresh */ true)
        .then(idTokenResult => idTokenResult)
        .catch(err => err);
    },
    refreshToken ({dispatch, commit, state}) {
      return dispatch('getIdToken').then(idToken => {
        const payload = {
          ...state.user,
          token: idToken.token,
          claims: idToken.claims
        }
        // payload.uid = state.user.uid
        commit('setUser', payload)
        return payload
      })
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    }
  }
}

export default auth