import { toRefs, reactive } from "vue";

/**
 *
 */
const useAutoComplete = (options, key) => {
  let state = reactive({
    userInput: "",
    filteredSuggestions: [],
    suggestions: options,
    selectedItem: {}
  });

  let selected = _item => {
    const keyParsed = (typeof key == 'function') ? key({element: _item}) : key
    state.userInput = keyParsed;
    state.filteredSuggestions = [];
    state.selectedItem = { userInput: state.userInput, item: _item };
  };

  let onChange = _event => {
    let i = _event.target.value;
    state.userInput = i
    state.selectedItem = { userInput: state.userInput, item: null };
    if (i.length === 0) {
      state.filteredSuggestions = [];
      return;
    }
  
    const r = state.suggestions ? state.suggestions.filter( suggestion =>  {
      const keyParsed = (typeof key == 'function') ? key({element: suggestion}) : key
      return  keyParsed.toLowerCase().indexOf(i.toLowerCase()) > -1
    }) : []
    state.filteredSuggestions = r
  };
  return {
    ...toRefs(state),
    selected,
    onChange
  };
};

export default useAutoComplete;
