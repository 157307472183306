<script>
import List from "@/views/Registers/List"
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: 'NotificationsList',
  extends: List,
  computed: {
    ...mapState({ config: 'notifications' }),
    ...authState(['user']),
    userId() {
      return this.user ? this.user.uid : null
    },
    computedHeaders() {
      return [
        { text: 'Tipo', value: 'type', ...this.typeConfig },
        { text: 'Título', value: 'title' },
        { text: 'Resumo', value: 'message' },
        { text: 'Lido', value: 'status.read', date: true },
        { text: 'Recebido', value: 'status.delivered', date: true },
      ]
    },
    typeConfig() {
      if (!this.config || !this.config.types)
        return {}

      const markers = Object.entries(this.config.types).reduce((acc, [key, value]) => ({ ...acc, [key]: value.color}), {})
      
      return { markers }
    },
    computedCustomActions() {
      return [
        {
          text: 'Marcar como lida',
          color: 'primary', 
          icon: 'mdi-eye-check-outline',
          multipleAction: this.markNotificationsAsRead,
          singleAction: this.markAsRead,
          sending: false,
        }
      ]
    },
  },
  methods: {
    initRegisters() {
      this.observer({
        collection: this.collection, 
        register: this.register, 
        filters: [{ key: 'userId', operator: '==', value: this.userId }],
        orderBy: { key: 'lifeControl.createdAt', mod: 'desc' },
        details: this.details,
        forceFilter: true,
      })
    },
    async markNotificationsAsRead(items, action) {
      const unreadNotifications = items.filter(item => !item.status.read)
      if (unreadNotifications.length > 0) {
        action.sending = true
        const response = await this.$api.post(`/notifications/batch/markAsRead`, unreadNotifications.map(({ id }) => ({ id })))
        action.sending = false
        return response
      }
    },
    async markAsRead(item) {
      if (item.status.read || item.loading)
        return

      item.loading = true
      const response = await this.$api.post(`/notifications/markAsRead/${item.id}`)
      item.loading = false
      return response
    },
  }
}
</script>

<style>

</style>