<template>
  <ion-content>
    <Refresher :myObserver="myObserver" />
    <ion-list :style="{ width: '100%', backgroundColor: 'var(--ion-background-color)', paddingStart: '2px', paddingEnd: '8px', marginTop: '5px'}">
      <ion-card
        class="card-items"
        v-for="item of computedItems"
        :key="item.id"
        :style="{marginBottom: '8px'}"
      >
        <ion-card-content class="ion-no-padding" :style="{position: 'relative'}">
          <ion-row @click="showItem(item.id)">
            <ion-col
              size="0.1"
              v-if="statusHeader && item.status"
              :class="statusHeader.markers[item.status.toLowerCase().trim()]"
            />
            <ion-col
              size="0.1"
              v-else
              :class="statusHeader.markers['Sem Status'] ? statusHeader.markers['Sem Status'] : statusHeader.markers['rascunho']"
            />
            <ion-grid>
              <ion-row>
                <ion-col
                  v-if="titleHeader"
                  size="6"
                  class="ion-justify-content-start ion-no-padding"
                >
                  <div>
                    <small>
                      {{ titleHeader.label }}:
                      <strong
                        v-html="proccessItem({ item, head: titleHeader })"
                      ></strong>
                    </small>
                  </div>
                </ion-col>
                <ion-col
                  size="6"
                  class="ion-justify-content-end ion-no-padding"
                  v-if="titleRightHeaders && titleRightHeaders.length > 0"
                >
                  <div :style="{ fontSize: '0.8em' }">
                    <div
                      v-for="(tRightHeader, index) of titleRightHeaders"
                      :key="index"
                    >
                      <span
                        v-html="proccessItem({ item, head: tRightHeader })"
                      ></span>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row v-if="subTitleHeader">
                <ion-col
                  size="12"
                  class="ion-justify-content-start ion-no-padding"
                >
                  <small>
                    {{ subTitleHeader.label }}:
                    <strong
                      v-html="proccessItem({ item, head: subTitleHeader })"
                    ></strong>
                  </small>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col
                  size="12"
                  class="ion-justify-content-start ion-no-padding"
                >
                  <template v-for="(head, index) of allHeaders" :key="index">
                    <small>
                      {{ head.label }}:
                      <strong v-html="proccessItem({ item, head })"></strong>
                    </small>
                  </template>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-col
              v-if="claims.offline"
              size="2"
              :style="{ padding: '0'}"
              @click="$event.stopPropagation();toggleOffline(item)"
            >
              <ion-icon v-if="item.offline" :icon="cloudOffline"></ion-icon>
              <ion-icon v-else :icon="cloudOfflineOutline"></ion-icon>
            </ion-col>
          </ion-row>
          <div :style="{position: 'absolute', right: '0px', bottom: '0px', padding: '8px'}">
            <ion-icon 
              v-if="item.status && item.status.toLowerCase().trim() === 'rascunho' && !offline && collection === 'SC5'" slot="icon-only" 
              :icon="trash" color="danger" 
              @click="presentConfirmRemove(item)"/>
            <ion-icon 
              v-if="item.C5_NUM && !offline && collection === 'SC5'" slot="icon-only" 
              :icon="gift" color="primary" 
              @click="bonus(item.id)"/>
          </div>
        </ion-card-content>
      </ion-card>
      <ion-infinite-scroll
        @ionInfinite="updatePage($event)" 
        threshold="50px" 
        id="infinite-scroll"
        :disabled="disableInfiniteScroll">
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Carregando">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-list>
    <ion-fab horizontal="end" vertical="bottom" slot="fixed" animated>
      <ion-fab-button>
        <ion-icon :icon="ellipsisVertical" />
      </ion-fab-button>
      <ion-fab-list side="top">
        <ion-fab-button @click="showSearch()">
          <ion-icon :icon="searchIcon" />
        </ion-fab-button>
        <ion-fab-button @click="callRegister()">
          <ion-icon :icon="add" />
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>
  </ion-content>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonList,
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
  IonFabButton,
  IonFab,
  IonFabList,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  alertController
} from "@ionic/vue";
import Refresher from "./Refresher.vue"
import { add, trash, ellipsisVertical, search as searchIcon, cloudOffline, cloudOfflineOutline, gift } from "ionicons/icons";
import { createNamespacedHelpers, mapActions, mapState } from "vuex";
import store from "../store";
import { ref } from "vue";
const { mapState: registersState, mapActions: registersActions } = createNamespacedHelpers("registers");
const { mapState: authState } = createNamespacedHelpers("auth");
export default {
  name: "DataTable",
  components: {
    IonCard,
    IonCardContent,
    IonList,
    IonCol,
    IonRow,
    IonGrid,
    IonIcon,
    IonFabButton,
    IonFab,
    IonFabList,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Refresher,
  },
  props: {
    myObserver: {},
    items: Array,
    headers: Array,
    opt: Object,
    collection: String,
    register: String,
    details: Array,
    claims: Object,
    blockedField: String,
    integration: Boolean,
    dataFilters: {
      type: Array,
      default: () => [],
    },
    inputsMaster: {
      type: Array,
      default: () => [],
    },
    customActions: Array,
  },
  data() {
    return {
      disableInfiniteScroll: false,
      openFilter: false,
      dialog: false,
      dialogImporter: false,
      dialogRemoveItem: false,
      selected: [],
      saveValid: true,
      importValid: true,
      selecteds: [],
      bundleActions: false,
      dialogBundleRemove: false,
      itemLoaded: {},
      itemComputed: {},
      itemToRemove: {},
      showPassword: false,
      method: "new",
      searchAutocomplete: null,
      loadingAutocomplete: false,
      querySelection: null,
      dataTableChips: 1,
      formChips: 4,
      vjsf: {},
      searchInput: "",
      algoliaHits: [],
      algoliaFilters: [],
      filterItems: {},
      statusHeader: {},
      titleHeader: {},
      titleRightHeaders: [],
      subTitleHeader: {},
      allHeaders: [],
    };
  },
  computed: {
    ...registersState(["registers", "counters", "loading"]),
    ...mapState(["algolia", "searching", "offline"]),
    ...authState(["user"]),
    computedItems() {
      return (this.searchInput && this.searchInput.length > 0) ||
        this.algoliaFilters.length > 0
        ? this.algoliaHits
        : this.opt.items || [];
    },
    registersCount() {
      return this.algoliaHits.length || this.counters[this.collection];
    },
    // computedDataFilters() {
    //   return this.user?.claims?.admin
    //     ? this.dataFilters.map(f => f.data || f)
    //     : this.dataFilters.filter(filter => filter.userRules?.some(ur => this.user?.rules?.indexOf(ur) >= 0)).map(f => f.data || f)
    // },
    headersMobile() {
      return this.headers.filter((h) => h.mobile);
    },
  },
  watch: {
    searching: {
      handler: function (newValue) {
        if (!newValue.stats) {
          this.algoliaFilters = [];
        }
      },
    },
    selecteds: {
      handler: function (newValue) {
        this.bundleActions = newValue.length > 1;
      },
    },
    itemLoaded: {
      handler: function (newValue) {
        const inputs = this.opt.form.inputs;
        const jsoneditorValues = inputs
          .filter((x) => x.type == "jsoneditor")
          .map((x) => x.value);
        jsoneditorValues.forEach((el) => {
          if (!newValue[el]) return;
          this.itemComputed[el] = store.$_.cloneDeep(newValue[el]);
          const properties = Object.fromEntries(
            Object.entries(this.itemComputed[el].properties).sort(
              ([, a], [, b]) => a.order - b.order
            )
          );
          this.itemComputed[el].properties = properties;
        });
      },
      deep: true,
    },
  },
  created() {
    // fields on headers
    this.titleHeader = this.headersMobile.find(
      (hm) => hm.mobile.type === "title"
    );
    this.titleRightHeaders = this.headersMobile
      .filter((hm) => hm.mobile.type === "titleRight")
      .sort((h1, h2) => {
        if (h1.mobile.verticalPosition > h2.mobile.verticalPosition) return 1;
        if (h1.mobile.verticalPosition < h2.mobile.verticalPosition) return -1;
        return 0;
      });
    this.subTitleHeader = this.headersMobile.find(
      (hm) => hm.mobile.type === "subTitle"
    );
    this.allHeaders = this.headersMobile
      .filter((hm) => hm.mobile.type === "all")
      .sort((h1, h2) => {
        if (h1.mobile.horizontalPosition > h2.mobile.horizontalPosition)
          return 1;
        if (h1.mobile.horizontalPosition < h2.mobile.horizontalPosition)
          return -1;
        return 0;
      });
    this.statusHeader = this.headers.find((h) => h.value === "status");

    // dataFilters
    this.dataFilters.forEach(async (filter) => {
      if (filter.source == "collection") {
        /** @TODO */
        const algoliaClient = algoliasearch(
          this.algolia.appId,
          this.algolia.searchApiKey
        );
        const algoliaIndex = algoliaClient.initIndex(filter.collectionName);
        try {
          filter.loading = true;
          const response = await algoliaIndex.search();

          if (response && response.hits) {
            filter.values = response.hits.map(
              (hit) => hit[filter.collectionField]
            );
          } else {
            console.warn("Error in algolia search on filter " + filter.name);
            filter.values = [];
          }
        } catch (err) {
          console.error(err);
        } finally {
          filter.loading = false;
        }
      } else if (filter.source == "algolia") {
        // prop items
        this.filterItems[filter.algoliaIndex] = [];

        // prop item-text
        filter.itemText = (item) => {
          if (!filter.fieldsToShow) return "id";

          let str = item[filter.fieldsToShow[0]];
          filter.fieldsToShow.slice(1).forEach((field) => {
            str = str + " - " + item[field];
          });

          return str;
        };

        // prop no-data-text
        filter.noDataTextDefault = "Digite algo para pesquisar";
        filter.noDataText = filter.noDataTextDefault;
      }
    });
  },
  methods: {
    ...mapActions(["dispatchAction", "toggleSearching"]),
    ...registersActions([
      "save",
      "remove",
      "import",
      "nextObserver",
      "getDetails",
      "get",
      "removeBundle",
    ]),
    async presentConfirmRemove(item) {
      let itemToRemove = item
      const alert = await alertController
        .create({
          header: 'Atenção!',
          message: 'Confirmar remoção do pedido?',
          buttons: [{
            text: 'Cancelar',
            role: 'cancel',  
          },
          {
            text: 'Confirmar',
            handler: (item) => {
              this.removeItem(itemToRemove)
            }
          }],
        });
      await alert.present();
      const { role } = await alert.onDidDismiss();
    },
    callRegister() {
      const path = store.$router.currentRoute.value.path;
      store.$router.push(path + "/cadastrar");
    },
    showItem(itemId) {
      const path = store.$router.currentRoute.value.path;
      store.$router.push(path + "/detalhes/" + itemId);
    },
    bonus(itemId){
      const path = store.$router.currentRoute.value.path;
      store.$router.push(path + "/bonificar/" + itemId);
    },
    initialize() {
      const inputs = this.opt.form.inputs;
      inputs.forEach((el, ix) => {
        this.$set(inputs[ix], "showPassword", false);
      });
    },
    updatePage(e) {
      this.nextObserver({ register: this.register }).then(()=>{
        e.target.complete()
      })
    },
    selectAll({ items, value, itemValue }) {
      this.$nextTick(() => {
        if (
          items &&
          this.itemLoaded[value] &&
          this.itemLoaded[value].length >= items.length
        ) {
          this.$set(this.itemLoaded, value, []);
        } else if (items) {
          this.$set(
            this.itemLoaded,
            value,
            itemValue ? items.map((x) => x[itemValue]).slice() : items.slice()
          );
        }
      });
    },
    querySelections(query, { collection, register, filters, orderBy }) {
      clearTimeout(this.querySelection);
      this.querySelection = setTimeout(async () => {
        if (!query || !collection || !register || !orderBy) return;
        this.loadingAutocomplete = true;
        this.get({ collection, register, orderBy, filters, query }).then(
          () => (this.loadingAutocomplete = false)
        );
      }, 500);
    },
    proccessItem({ item, head, copy = false }) {
      let ret = store.$_.cloneDeep(item);
      if (!ret) return;
      if (
        ret._highlightResult &&
        ret._highlightResult[head.value] &&
        ret._highlightResult[head.value].matchedWords &&
        ret._highlightResult[head.value].matchedWords.length > 0
      )
        ret = ret._highlightResult;

      /* NESTED */

      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      if (lenItem == 1) {
        ret = ret[pathItem];
      } else {
        for (let ix = 0; ix < pathItem.length; ix++) {
          const el = pathItem[ix];
          if (ret && typeof ret[el] != "undefined") {
            ret = ret[el];
          } else {
            ret = head.checkbox ? false : "";
            break;
          }
        }
      }

      /* ASSIST */

      const detail = head.detail;
      if (detail && ret) {
        const pathAssist = detail.split(".");
        const lenAssist = pathAssist.length;
        if (Array.isArray(ret)) {
          ret.forEach((el, ix) => {
            let retEl = this.registers[pathAssist[0]]
              ? this.registers[pathAssist[0]].find((x) => x.id === el)
              : null;
            if (retEl) {
              for (let i = 1; i < lenAssist; i++) {
                const el2 = pathAssist[i];
                retEl = retEl ? retEl[el2] : "";
              }
              ret[ix] = retEl;
            }
          });
        } else {
          let retEl = this.registers[pathAssist[0]]
            ? this.registers[pathAssist[0]].find((x) => x.id === ret)
            : null;
          for (let i = 1; i < lenAssist; i++) {
            const el = pathAssist[i];
            retEl = retEl ? retEl[el] : "";
          }
          ret = retEl;
        }
      }

      /* FORMATS */

      if (!ret) return;
      if (ret && ret.value) ret = ret.value;
      if (head.slice && !copy) {
        ret = ret.slice(head.slice);
      }
      if (head.upper) {
        ret = ret.toUpperCase();
      }
      if (head.lower) {
        ret = ret.toLowerCase();
      }
      if (head.trim) {
        ret = ret.trim();
      }

      /* FORMAT DATES */
      if (head.date || head.dateHour || head.dateYear || head.datePlusWeek) {
        if (Object.prototype.hasOwnProperty.call(ret, "toDate"))
          ret = ret.toDate();
        else if (ret.seconds && ret.nanoseconds)
          ret = new store.$db.Timestamp(ret.seconds, ret.nanoseconds).toDate(); // Retorno via Algolia não contem metodo toDate
      }
      if (head.date) {
        ret = store.$moment(ret).format("DD/MM/YYYY");
      }
      if (head.dateHour) {
        ret = store.$moment(ret).format("DD/MM/YYYY - HH:mm:ss");
      }
      if (head.dateYear) {
        ret = store.$moment(ret).format("YYYY");
      }
      if (head.datePlusWeek) {
        ret = store.$moment(ret).format("DD/MM/YYYY, dddd");
      }

      // if (options.slice) { obj = (obj) ? obj.slice(options.slice) : '' }
      // if (options.multiplier) { obj = (obj) ? obj * options.multiplier : '' }

      return ret;
    },
    proccessParams({ item, params }) {
      const ret = {};
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const el = params[key];
          const pathItem = el.split(".");
          const lenItem = pathItem.length;
          if (lenItem == 1) {
            ret[key] = pathItem[0];
          } else {
            for (let ix = 1; ix < pathItem.length; ix++) {
              const el2 = pathItem[ix];
              if (typeof item[el2] != "undefined") {
                ret[key] = item[el2];
              } else {
                ret[key] = "";
                break;
              }
            }
          }
        }
      }
      return ret;
    },
    newItem() {
      this.itemLoaded = {};
      this.method = "new";
      const inputs = this.opt.form.inputs;
      inputs.forEach((el) => {
        if (el.default) {
          this.itemLoaded[el.value] = el.default;
        }
      });
    },
    importer() {
      this.itemLoaded = {};
      this.method = "import";
    },
    closeDialog() {
      this.dialog = false;
    },
    closeDialogImporter() {
      this.dialogImporter = false;
    },
    clipboardCopy: function (e) {
      this.$toast.success("Copiado");
    },
    clipboardError: function (e) {
      this.$toast.error("Falha ao copiar");
    },
    toggleCk({ item, head }) {
      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      let schema = item;
      for (let i = 0; i < lenItem - 1; i++) {
        const el = pathItem[i];
        if (!schema[el]) schema[el] = {};
        schema = schema[el];
      }
      schema[pathItem[lenItem - 1]] = !schema[pathItem[lenItem - 1]];

      this.saveItem(item);
    },
    removeChip({ value, item, itemValue }) {
      const ix = this.itemLoaded[value].findIndex((x) => x == item[itemValue]);
      if (ix > -1) this.itemLoaded[value].splice(ix, 1);
    },
    editItem(item) {
      // Details
      if (this.details) {
        this.details.forEach((detail) => {
          this.getDetails({
            item: store.$_.cloneDeep(item),
            detail: store.$_.cloneDeep(detail),
            endSlice: this.formChips + 1,
          });
        });
      }

      // Order Json
      const inputs = this.opt.form.inputs;
      const jsoneditorValues = inputs
        .filter((x) => x.type == "jsoneditor")
        .map((x) => x.value);
      jsoneditorValues.forEach((el) => {
        if (!item[el]) return;
        const properties = Object.fromEntries(
          Object.entries(item[el].properties).sort(
            ([, a], [, b]) => a.order - b.order
          )
        );
        item[el].properties = properties;
      });

      // Load Item
      this.itemLoaded = store.$_.cloneDeep(item);
      this.method = "edit";
      this.dialog = true;
    },
    saveItem(item) {
      if (this.$refs.save && !this.$refs.save.validate()) return;

      // Dispatch Before
      if (this.opt.dispatch) {
        this.opt.dispatch
          .filter((x) => x.when == "before" && x.method == this.method)
          .forEach((el) => {
            if (!el.switch || item[el.switch]) {
              this.dispatchAction({
                action: el.action,
                params: this.proccessParams({ params: el.params, item }),
              });
            }
          });
      }

      this.save({ collection: this.collection, item, integration: true })
        .then(() => {
          // Dispatch After
          if (this.opt.dispatch) {
            this.opt.dispatch
              .filter((x) => x.when == "after" && x.method == this.method)
              .forEach((el) => {
                if (!el.switch || item[el.switch]) {
                  this.dispatchAction({
                    action: el.action,
                    params: this.proccessParams({ params: el.params, item }),
                  });
                }
              });
          }

          this.closeDialog();
        })
        .catch((error) => console.error(error));
    },
    confirmRemove(item) {
      this.itemToRemove = store.$_.cloneDeep(item);
      this.dialogRemoveItem = true;
    },
    removeItem(item) {
      this.remove({
        collection: this.collection,
        integration: this.integration,
        item,
      });
      this.dialogRemoveItem = false;
    },
    removeItens() {
      this.removeBundle({
        collection: this.collection,
        integration: this.integration,
        itens: this.selecteds,
      })
        .then(() => {
          this.selecteds = [];
          this.dialogBundleRemove = false;
        })
        .catch((error) => {});
    },
    closeDialogRemoveItem() {
      this.itemToRemove = store.$_.cloneDeep({});
      this.dialogRemoveItem = false;
    },
    sendImport(item) {
      if (this.$refs.import && !this.$refs.import.validate()) return;

      this.import({ collection: this.collection, item })
        .then(() => this.closeDialogImporter())
        .catch((error) => {});
    },
    async search(req) {
      try {
        const { hits, params } = await req;
        this.algoliaHits = hits;
      } catch (err) {
        console.error(err);
        this.algoliaHits = [];
      }
    },
    async searchFilter(req, filter) {
      try {
        const { hits } = await req;
        this.filterItems[filter.algoliaIndex].splice(0);
        if (hits) this.filterItems[filter.algoliaIndex].push(...hits);
      } catch (err) {
        console.error(err);
        this.filterItems[filter.algoliaIndex].splice(0);
      }
    },
    searchAlgolia(algoliaFilters) {
      this.algoliaFilters = algoliaFilters;
      this.$refs.algoliaSearchInput.search(this.searchInput, algoliaFilters);
    },
    setAlgoliaFilters() {
      this.algoliaFilters = this.dataFilters
        .filter((filter) => filter.model)
        .map((filter) => [
          `${filter.filterKey}:${filter.model[filter.itemKey]}`,
        ]);

      // realiza a busca
      this.$refs.algoliaSearchInput.search(
        this.searchInput,
        this.algoliaFilters
      );
    },
    showSearch() {
      this.toggleSearching({
        stats: true,
        index: this.collection,
        handler: this.search,
        filters: this.algoliaFilters,
        attributesToHighlight: this.headers
          .filter((h) => h.mobile)
          .map((h) => h.value),
      });
    },
    toggleOffline(item) {
      item.offline = !item.offline
      this.saveItem(item);
    }
  },
  setup() {
    const isOpenRef = ref(false);
    const buttons = [{
      text: 'Cancelar',
      role: 'cancel',
    },
    {
      text: 'Confirmar',
      handler: () => {
        this.removeItem(this.itemToRemove)
      }
    }
    ];
    const setOpen = (value) => {
      isOpenRef.value = value;
    };
    return { add, trash, ellipsisVertical, searchIcon, isOpenRef, setOpen, cloudOffline, cloudOfflineOutline, gift, buttons };
  },
};
</script>

<style scoped>
  .card-items {
    margin: .2em;
  }
  .wrap-text {
    padding-top: 2px;
    padding-bottom: 2px;
    display: block;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ion-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .light-blue {
    background-color: #03a9f4;
  }

  .error {
    background-color: #e64a19;
  }

  .deep-orange,
  .darken-2 {
    background-color: #e64a19;
  }

  .yellow {
    background-color: yellow;
  }

  .black {
    background-color: black;
  }

  .deep-purple .lighten-2 {
    background-color: purple;
  }

  .green {
    background-color: #4caf50;
  }

  .brown {
    background-color: brown;
  }

  .orange {
    background-color: orange;
  }

  .grey {
    background-color: #9e9e9e;
  }

  .amber {
    background-color: gold;
  }

  .offline{
    background-color: rgb(30, 36, 36);
  }

  .noStatus {
    background: linear-gradient(
      to bottom,
      orange,
      orange 50%,
      orangered 50%,
      orangered
    );
    background-size: 100% 20px;
  }
</style>
